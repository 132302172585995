export const FETCH_MY_STORY_LIST = "myStory/FETCH_MY_STORY_LIST";
export const FETCH_MY_STORY_LIST_COMPLETED = "myStory/FETCH_MY_STORY_LIST_COMPLETED";
export const FETCH_MY_STORY_LIST_FAILED = "myStory/FETCH_MY_STORY_LIST_FAILED";

export const INSERT_MY_STORY = "myStory/INSERT_MY_STORY";
export const INSERT_MY_STORY_COMPLETED = "myStory/INSERT_MY_STORY_COMPLETED";
export const INSERT_MY_STORY_FAILED = "myStory/INSERT_MY_STORY_FAILED";

export const UPDATE_MY_STORY= "myStory/UPDATE_MY_STORY";
export const UPDATE_MY_STORY_COMPLETED = "myStory/UPDATE_MY_STORY_COMPLETED";
export const UPDATE_MY_STORY_FAILED = "myStory/UPDATE_MY_STORY_FAILED";

export const FETCH_QUESTION_LIST = "question/FETCH_QUESTION_LIST";
export const FETCH_QUESTION_LIST_COMPLETED = "question/FETCH_QUESTION_LIST_COMPLETED";
export const FETCH_QUESTION_LIST_FAILED = "question/FETCH_QUESTION_LIST_FAILED";

export const INSERT_QUESTION = "question/INSERT_QUESTION";
export const INSERT_QUESTION_COMPLETED = "question/INSERT_QUESTION_COMPLETED";
export const INSERT_QUESTION_FAILED = "question/INSERT_QUESTION_FAILED";

export const UPDATE_QUESTION = "question/UPDATE_QUESTION";
export const UPDATE_QUESTION_COMPLETED = "question/UPDATE_QUESTION_COMPLETED";
export const UPDATE_QUESTION_FAILED = "question/UPDATE_QUESTION_FAILED";

export const UPDATE_CONTRIBUTOR_QUESTION = "question/UPDATE_CONTRIBUTOR_QUESTION";
export const UPDATE_CONTRIBUTOR_QUESTION_COMPLETED = "question/UPDATE_CONTRIBUTOR_QUESTION_COMPLETED";
export const UPDATE_CONTRIBUTOR_QUESTION_FAILED = "question/UPDATE_CONTRIBUTOR_QUESTION_FAILED";

export const DELETE_QUESTION = "question/DELETE_QUESTION";
export const DELETE_QUESTION_COMPLETED = "question/DELETE_QUESTION_COMPLETED";
export const DELETE_QUESTION_FAILED = "question/DELETE_QUESTION_FAILED";

export const FETCH_CATEGORY_LIST = "category/FETCH_CATEGORY_LIST";
export const FETCH_CATEGORY_LIST_COMPLETED = "category/FETCH_CATEGORY_LIST_COMPLETED";
export const FETCH_CATEGORY_LIST_FAILED = "category/FETCH_CATEGORY_LIST_FAILED";

export const FETCH_QUESTION_LIST_BY_CATEGORY_LIST = "question/FETCH_QUESTION_LIST_BY_CATEGORY_LIST";
export const FETCH_QUESTION_LIST_BY_CATEGORY_COMPLETED = "question/FETCH_QUESTION_LIST_BY_CATEGORY_COMPLETED";
export const FETCH_QUESTION_LIST_BY_CATEGORY_FAILED = "question/FETCH_QUESTION_LIST_BY_CATEGORY_FAILED";

export const FETCH_QUESTION_LIST_BY_LANGUAGE = "question/FETCH_QUESTION_LIST_BY_LANGUAGE";
export const FETCH_QUESTION_LIST_BY_LANGUAGE_COMPLETED = "question/FETCH_QUESTION_LIST_BY_LANGUAGE_COMPLETED";
export const FETCH_QUESTION_LIST_BY_LANGUAGE_FAILED = "question/FETCH_QUESTION_LIST_BY_LANGUAGE_FAILED";

export const INSERT_CONTRIBUTOR = "contributor/INSERT_CONTRIBUTOR";
export const INSERT_CONTRIBUTOR_COMPLETED = "contributor/INSERT_CONTRIBUTOR_COMPLETED";
export const INSERT_CONTRIBUTOR_FAILED = "contributor/INSERT_CONTRIBUTOR_FAILED";

export const UPDATE_CONTRIBUTOR = "contributor/UPDATE_CONTRIBUTOR";
export const UPDATE_CONTRIBUTOR_COMPLETED = "contributor/UPDATE_CONTRIBUTOR_COMPLETED";
export const UPDATE_CONTRIBUTOR_FAILED = "contributor/UPDATE_CONTRIBUTOR_FAILED";

export const FETCH_CONTRIBUTOR_LIST = " contributor/FETCH_CONTRIBUTOR_LIST";
export const FETCH_CONTRIBUTOR_LIST_COMPLETED = "contributor/FETCH_CONTRIBUTOR_LIST_COMPLETED";
export const FETCH_CONTRIBUTOR_LIST_FAILED = "contributor/FETCH_CONTRIBUTOR_LIST_FAILED";

export const DELETE_CONTRIBUTOR = "contributor/DELETE_CONTRIBUTOR";
export const DELETE_CONTRIBUTOR_COMPLETED = "contributor/DELETE_CONTRIBUTOR_COMPLETED";
export const DELETE_CONTRIBUTOR_FAILED = "contributor/DELETE_CONTRIBUTOR_FAILED";

export const FETCH_LIST = "master/FETCH_LIST";
export const FETCH_LIST_COMPLETED = "master/FETCH_LIST_COMPLETED";
export const FETCH_LIST_FAILED = "master/FETCH_LIST_FAILED";

export const PDF_SEND = "pdf/PDF_SEND";
export const SEND_STORY_TO_INVITEE = "invitee/SEND_STORY_TO_INVITEE";
export const SEND_QUESTION = "user/SEND_QUESTION";


export const INSERT_ORDERBOOK = "orderBook/INSERT_ORDERBOOK";
export const INSERT_ORDERBOOK_COMPLETED = "orderBook/INSERT_ORDERBOOK_COMPLETED";
export const INSERT_ORDERBOOK_FAILED = "orderBook/INSERT_ORDERBOOK_FAILED";

export const FETCH_ORDERBOOK_LIST = "orderBook/FETCH_ORDERBOOK_LIST";
export const FETCH_ORDERBOOK_COMPLETED = "orderBook/FETCH_ORDERBOOK_COMPLETED";
export const FETCH_ORDERBOOK_FAILED = "orderBook/FETCH_ORDERBOOK_FAILED";

export const DELETE_ORDERBOOK = "orderBook/DELETE_ORDERBOOK";
export const DELETE_ORDERBOOK_COMPLETED = "orderBook/DELETE_ORDERBOOK_COMPLETED";
export const DELETE_ORDERBOOK_FAILED = "orderBook/DELETE_ORDERBOOK_FAILED";

export const UPDATE_ORDERBOOK = "orderBook/UPDATE_ORDERBOOK";
export const UPDATE_ORDERBOOK_COMPLETED = "orderBook/UPDATE_ORDERBOOK_COMPLETED";
export const UPDATE_ORDERBOOK_FAILED = "orderBook/UPDATE_ORDERBOOK_FAILED";


export const INSERT_ADDITIONAL_ORDERBOOK = "orderBook/INSERT_ADDITIONAL_ORDERBOOK";
export const INSERT_ADDITIONAL_ORDERBOOK_COMPLETED = "orderBook/INSERT_ADDITIONAL_ORDERBOOK_COMPLETED";
export const INSERT_ADDITIONAL_ORDERBOOK_FAILED = "orderBook/INSERT_ADDITIONAL_ORDERBOOK_FAILED";

export const PAYMENT_SUCCESSFULLY = "payment/PAYMENT_SUCCESSFULLY";
export const PAYMENT_SUCCESSFULLY_COMPLETED = "payment/PAYMENT_SUCCESSFULLY_COMPLETED";
export const PAYMENT_SUCCESSFULLY_FAILED = "payment/PAYMENT_SUCCESSFULLY_FAILED";

export const FETCH_ADDITIONAL_ORDERBOOK_LIST = "orderBook/FETCH_ADDITIONAL_ORDERBOOK_LIST";
export const FETCH_ADDITIONAL_ORDERBOOK_COMPLETED = "orderBook/FETCH_ADDITIONAL_ORDERBOOK_COMPLETED";
export const FETCH_ADDITIONAL_ORDERBOOK_FAILED = "orderBook/FETCH_ADDITIONAL_ORDERBOOK_FAILED";

export const DELETE_ADDITIONAL_ORDERBOOK = "orderBook/DELETE_ADDITIONAL_ORDERBOOK";
export const DELETE_ADDITIONAL_ORDERBOOK_COMPLETED = "orderBook/DELETE_ADDITIONAL_ORDERBOOK_COMPLETED";
export const DELETE_ADDITIONAL_ORDERBOOK_FAILED = "orderBook/DELETE_ADDITIONAL_ORDERBOOK_FAILED";

export const UPDATE_ADDITIONAL_ORDERBOOK = "orderBook/UPDATE_ADDITIONAL_ORDERBOOK";
export const UPDATE_ADDITIONAL_ORDERBOOK_COMPLETED = "orderBook/UPDATE_ADDITIONAL_ORDERBOOK_COMPLETED";
export const UPDATE_ADDITIONAL_ORDERBOOK_FAILED = "orderBook/UPDATE_ADDITIONAL_ORDERBOOK_FAILED";

export const FETCH_ADDITIONAL_ORDERBOOK_COVER_COLOR = "coverColor/FETCH_ADDITIONAL_ORDERBOOK_COVER_COLOR";
export const FETCH_ADDITIONAL_ORDERBOOK_COVER_COLOR_COMPLETED = "coverColor/FETCH_ADDITIONAL_ORDERBOOK_COVER_COLOR_COMPLETED";
export const FETCH_ADDITIONAL_ORDERBOOK_COVER_COLOR_FAILED = "coverColor/FETCH_ADDITIONAL_ORDERBOOK_COVER_COLOR_FAILED";