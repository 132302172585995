import React, { Fragment } from 'react';
import { Container, CardBody, Col, Form, Row, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import GoogleFontLoader from 'react-google-font-loader';
import { myStoryOperations } from '../../state/ducks/story';
import story from '../../assets/images/datatable/story.png';
import '../Button.css';
import { FaTimes } from 'react-icons/fa';
import '../ViewThisStory.css';
import { toast } from 'react-toastify';
import * as API_URL from "../../env.js";
import request from "../../utils/request";

class YourWebPages extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      allQuestionData: [],
      modalOpen: false,
      modalSendOpen: false,
      inviteeName:'',
      inviteeEmail:'',
      inviteeMessage:'',
      selectedStoryData:{},
      selectedUserData: {},
      selectedUserOrderData: [],
      selectedGiftUserData: {},
      selectedGiftUserOrderData: [],

    };
  }

  async componentDidMount() {
    try {
    const{loggedInUserId} = this.props;
    if(!loggedInUserId) {
      console.log("No user ID found");
      return;
    }
    const response = await this.props.listMyStory({ "id": loggedInUserId });
    this.setState({ selectedStoryData: response.payload });

    const options = {
      url: API_URL.BACKEND_SERVICE_API_URL + `/user/order-details`,
      method: "GET",
      headers: {
        authorization: localStorage.getItem("token"),
      },
    };
    const options1 = {
      url: API_URL.BACKEND_SERVICE_API_URL + `/user/gift-order-details`,
      method: "GET",
      headers: {
        authorization: localStorage.getItem("token"),
      },
    };
    let responseOrderDetails = await request(options);
    let responseGiftOrderDetails = await request(options1);

    this.setState({ selectedUserData: responseOrderDetails.payload.user });
    this.setState({ selectedUserOrderData: responseOrderDetails.payload.userOrders });
    this.setState({ selectedGiftUserData: responseGiftOrderDetails.payload.user });
    this.setState({
      selectedGiftUserOrderData: responseGiftOrderDetails.payload.userOrders,
    });

    } catch (error) {
      console.log(error);
    }
  }

  onReadThisStoryClick() {
    window.open("/your-web-pages/read-this-story/", "_self")
  }

  handleShowBook = () => {
    // console.log("hello open");
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen,
      modalSendOpen: false
    }));
  };

  handleShowSend = () => {
    // console.log("hello open");
    this.setState(prevState => ({
      modalSendOpen: !prevState.modalSendOpen,
      modalOpen: false
    }));
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
};

  handleSendInvite = async (e) => {
    e.preventDefault();
    const { inviteeName, inviteeEmail, inviteMessage } = this.state;
    const inviteData = {
        name: inviteeName,
        email: inviteeEmail,
        message: inviteMessage
    };
    try {
        await this.props.SendAnInviteToStory(inviteData);
        // toast.success('Invite sent successfully');
        this.setState({ modalSendOpen: true, // Show send invite success modal
        modalOpen: false, // Close the main invite modal
        inviteeName: '',
        inviteeEmail: '',
        inviteMessage: ''});
        // this.setState({ isInviteFormOpen: false, inviteSentMessage: 'An invite has been sent.' });
    } catch (error) {
        toast.error(error.response?.data?.message, { autoClose: 10000 });
        console.error('Error sending invite:', error);
    }
}


  render() {
    const {modalOpen, modalSendOpen, inviteeName, inviteeEmail, inviteMessage,selectedStoryData,selectedUserOrderData} = this.state;
    // console.log("selectedUserOrderData=>>>",selectedUserOrderData);
        // Check if any order has a package with "Web Page" or "Book & Web Page"
        const hasRelevantPackage = selectedUserOrderData.some(order => 
          order.packages.some(pkg => pkg.package === "Web Page" || pkg.package === "Book & Web Page")
      );
      // console.log("hasRelevantPackage===>>",hasRelevantPackage);

      // Render the JSX code only if the relevant package is found
  //   if (!hasRelevantPackage) {
  //     return null; // or you can return an alternative UI or message if needed
  // }
    const liStyle = {
      display: 'flex',
      alignItems: 'center',
      fontSize: "12px"
    };
    const ulStyle = {
      display: 'flex',
      listStyle: 'none',
      padding: 0,
      justifyContent: 'center',
      fontFamily: 'Fira Sans',
      fontWeight: "500"
    };
    const spanStyle = {
      background: "#404040",
      display: "inline-block",
      height: "15px",
      width: "1px",
      margin: "0 10px"
    };
    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [400, 300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [300, 700, 500, 400],
            },
          ]}
        />
        <Container fluid={false} className='container'>
          <CardBody style={{maxWidth: "950px", margin:"0 auto"}}>
            <Form>
              <Row>
                <h2 className='mt-5 mainPageTitle' style={{textAlign: 'center', font: 'normal normal 300 2.5rem/1.4 Martel', color: "#000" }}>Your Web Pages</h2>
                <div style={{ textAlign: 'center' }}>
                  <span style={{ borderBottom: "2px solid #FEC1EB", height: "1px", width: "102px", display: "inline-block" }}></span>
                </div>
              </Row>
              <Row className='mt-3'>
                <div style={{ font: "normal normal 300 1.1rem/1.5 Fira Sans", color: "#404040" }}>
                  <span >These are the stories you've chosen to convert to a web page. The web page gives you a unique link that you can share with friends and family all over the world.</span>
                </div>
              </Row>
             {
              hasRelevantPackage ? (
                <>
                 <Row className='mt-3 ' style={{ display: 'flex', justifyContent: 'center' }}>
                <Col xl='6'>
                  <div className='storyBox'>
                    <div style={{ background: "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 2%, rgba(0,0,0,0) 6%)", height: "260px", width: "40%", boxShadow: "-4px 5px 12px 0px #bababa", borderRadius: "2px" }}>
                      <div style={{ textAlign: "center", right: "9%", left: "9%", top: "10%", bottom: "0" }}>
                        <div className='pt-5' style={{ padding: "0% 20% 0% 20%", fontSize: "0.8em", fontWeight: "300", fontFamily: "Fira Sans", letterSpacing: ".05em", lineHeight: "1.2", textAlign: "center", textTransform: "uppercase" }}>Your story - {this.props.loggedInUserName}</div>
                        <div style={{ padding: "15% 10% 20% 13%" }}><img src={selectedStoryData.image} width="120" height="120" alt='story' /></div>
                      </div>
                    </div>
                    <div className="storyBgBox" style={{ paddingLeft: "8%" }}>
                      <h4 className='mt-3' style={{ fontWeight: "400", fontFamily: "Martel" , fontSize: "20px" }}>{selectedStoryData.title}</h4>
                      <div className='mt-3'>
                        <button type='button' className='custom-button' onClick={this.onReadThisStoryClick}>{'Read this story'}&nbsp;<span style={{content: "\f345", fontFamily: "dashicons", fontSize: "px", width: "12px"}}>
                          
                        <svg xmlns="http://www.w3.org/2000/svg" class="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"><polyline points="9 18 15 12 9 6"/></svg>
                          
                          
                          </span></button>
                      </div>
                      <div className='mt-2'>
                        <span to="#" onClick={this.handleShowBook} style={{ textTransform: "uppercase", letterSpacing: ".122rem", fontSize: "0.9rem", fontWeight: "300", fontFamily: "Fira Sans", color: "#8A1A75" }}>&nbsp;Share this story</span>
                      </div>
                <Modal isOpen={modalOpen} toggle={() => this.handleShowBook()} style={{ maxWidth: "820px" }}>
                  <div className="model-invite-form">

                    <Form onSubmit={this.handleSendInvite}> 
                    <div style={{borderBottom:"1px solid #dee2e6",font:"normal normal 300 1.25rem / 1.4 Martel"}}>
                    <ModalHeader 
                        close={<FaTimes className="close" onClick={this.handleShowBook} style={{ cursor: 'pointer' }} />}>Send invite to view this story</ModalHeader>
                    </div>
                      
                      <ModalBody>
                            <p>Send an invite which contains a unique url to view this book</p>
                            <div style={{ marginBottom: "10px" }}>
                                    <label>Invitee name</label>
                                    <input type="name" name="inviteeName" value={inviteeName} onChange={this.handleInputChange} />
                                </div>
                                <div style={{ marginBottom: "10px" }}>
                                    <label>Invitee email</label>
                                    <input type="email" name="inviteeEmail" value={inviteeEmail} onChange={this.handleInputChange} />
                                </div>
                                <div style={{ marginBottom: "10px" }}>
                                    <label>Message</label>
                                    <textarea style={{height: "288px"}} name="inviteMessage" value={inviteMessage} onChange={this.handleInputChange} />
                                </div>
                                <button onClick={this.handleShowSend} className='custom-button' type="submit">Send Invite</button>
                           
                          
                      </ModalBody>  
                    
                    </Form>
                  </div>
                </Modal>

                <Modal isOpen={modalSendOpen} toggle={() => this.handleShowSend()} style={{ maxWidth: "900px" }}>
                  <div className="model-invite-form">
                    <div style={{borderBottom:"1px solid #dee2e6",font:"normal normal 300 1.25rem / 1.4 Martel"}}>
                    <ModalHeader 
                        close={<FaTimes className="close" onClick={this.handleShowSend} style={{ cursor: 'pointer' }} />}>Send invite to view this story</ModalHeader>
                    </div>                      
                      <ModalBody>
                            <p>An invite has been sent.</p>
                      </ModalBody>  
                  </div>
                </Modal>
                </div>
                </div>
                </Col>
              </Row>
                </>
              ):(
                <></>
              )
             }
              <Row className='mt-4'>
                <span style={{ borderBottom: "1.5px solid #ccc", height: "2px", width: "100%", margin: "20px auto" }}></span>
              </Row>
              <Row className='mt-1 mb-5'>
                <ul style={ulStyle}>
                  <li style={liStyle}><Link to="https://gys.coalescets.com/">BUY A WEB PAGE</Link></li>
                  <span style={spanStyle}></span>
                  <li style={liStyle}><Link to="https://gys.coalescets.com/">BUY A STORY</Link></li>
                  <span style={spanStyle}></span>
                  <li style={liStyle}><Link to="https://gys.coalescets.com/">GIFT A STORY</Link></li>
                </ul>
              </Row>
              
              <Row className='mt-1 mb-5'></Row>
            </Form>
          </CardBody>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { loggedInUserId: state.auth.session.user.id }
};

const mapDispatchToProps = {
  SendAnInviteToStory: myStoryOperations.SendAnInviteToStory,
  listMyStory: myStoryOperations.listMyStory
  // listQuestion: myStoryOperations.listQuestion,
};

export default connect(mapStateToProps, mapDispatchToProps)(YourWebPages);