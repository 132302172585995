import React, { Fragment } from 'react';
import { Container, Col, Row, Label, Input } from 'reactstrap';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import GoogleFontLoader from 'react-google-font-loader';
import { myStoryOperations } from '../../state/ducks/story';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import '../Button.css';

class Pdf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: 'option1',
      selectedQuestionData: '',
      showPdfContent: false,
      email: '', // Add email to state
      message: 'Please find my question PDF attached.',
    };
  }

  async componentDidMount() {
    const questionId = localStorage.getItem("selectedQuestionId");
    // console.log("questionId===>>>>",questionId);
    const response = await this.props.listQuestionDetails({ id: questionId });
    const questionData = response.payload;
    // console.log("questions===>>>",questionData);
    this.setState({
      selectedQuestionData: questionData,
      text: questionData.answer,
      content: questionData.question,
    });
  }

  handleOptionChange = (e) => {
    const selectedOption = e.target.value;
    this.setState({ selectedOption });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  generatePDF = () => {
    const { selectedOption } = this.state;

    if (selectedOption === 'option2') {
      // Call the API to send the PDF via email
      this.sendPDFToEmail();
    } else {
      const timestamp = new Date().toISOString().replace(/[-T:\.Z]/g, '');
      const fileName = `document_${timestamp}.pdf`;
      // Generate PDF preview
      this.setState({ showPdfContent: true }, () => {
        const input = document.getElementById('pdf-content');
        if (!input) {
          console.error('Element with id "pdf-content" not found.');
          return;
        }
        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgWidth = 210; // A4 size width
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

            // Create a Blob object representing the PDF document
            const pdfBlob = pdf.output('blob');

            // Create a URL for the Blob object
            const pdfUrl = URL.createObjectURL(pdfBlob);
             
            // Open the PDF in a new tab
            window.open(pdfUrl);

            //saveAs(pdfBlob, fileName);

            // Optional: Revoke the URL after some time to release memory
            /*setTimeout(() => {
              URL.revokeObjectURL(pdfUrl);
            }, 10000); // 10 seconds*/

            // Hide the PDF content after generating the PDF
            this.setState({ showPdfContent: false });
          })
          .catch((error) => {
            console.error('Error generating PDF:', error);
            this.setState({ showPdfContent: false });
          });
      });
    }
  };

  sendPDFToEmail = async () => {
    try {
      const { selectedQuestionData, email, message } = this.state;

      const payload = {
        id: selectedQuestionData.id,
        email,
        message,
      };
      // console.log("seleQuestion data====",selectedQuestionData);
      const response = await this.props.sendPDF(payload);
      toast.success(response.message, { autoClose: 10000 });
    } catch (error) {
      console.error("Error sending invitation:", error);
      toast.error("Error sending invitation. Please try again later.", { autoClose: 10000 });
    }
  }

  render() {
    const { selectedOption, text, content, showPdfContent, email, message } = this.state;
    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [300, 700, 400],
            },
          ]}
        />
        <Container fluid={true}>
          <ToastContainer />
          <Row>
            <h2 className='mt-5' style={{ textAlign: 'center', font: 'normal normal 300 2.2rem/1.4 Martel', color: "#000" }}>Preview your PDF or share it with</h2>
            <h2 style={{ textAlign: 'center', font: 'normal normal 300 2.2rem/1.4 Martel', color: "#000" }}>the email of your choice</h2>
            <div style={{ textAlign: 'center' }}>
              <span style={{ borderBottom: "1px solid #FEC1EB", height: "1px", width: "102px", display: "inline-block" }}></span>
            </div>
          </Row>
          <span style={{ borderBottom: "2px solid #FEC1EB", height: "2px", width: "102px", margin: "20px auto" }}></span>
          <Row>
            <Col sm="3"></Col>
            <Col sm="8">
              <div>
                <label style={{ fontSize: "14px", fontFamily: 'Fira Sans', fontWeight: "300" }}>
                  <input
                    type="radio"
                    value="option1"
                    checked={selectedOption === 'option1'}
                    onChange={this.handleOptionChange}
                  />&nbsp;&nbsp;Preview PDF
                </label><br></br>
                <label style={{ fontSize: "14px", fontFamily: 'Fira Sans', fontWeight: "300" }}>
                  <input
                    type="radio"
                    value="option2"
                    checked={selectedOption === 'option2'}
                    onChange={this.handleOptionChange}
                  />&nbsp;&nbsp;Send PDF to an email address
                </label>
              </div>
            </Col>
            <Col sm="1"></Col>  
          </Row>
          {selectedOption === 'option2' && (
            <Row className='mt-1'>
              <Col sm="3"></Col>
              <Col sm="7">
                <span>
                  <Label style={{ fontSize: "14px", fontWeight: "700", fontFamily: 'Fira Sans' }}>Please enter an Email address&nbsp;<span style={{ color: "#c02b0a", fontSize: "12px", fontWeight: "400", fontStyle: "italic" }}>(Required)</span></Label>
                  <Input
                    type="text"
                    style={{ fontSize: "14px", fontWeight: "400", border: "1px solid #767676", fontFamily: 'Fira Sans' }}
                    name="email"
                    id="email"
                    value={email}
                    onChange={this.handleInputChange}
                  />
                  <Label style={{ fontSize: "14px", fontWeight: "700", fontFamily: 'Fira Sans' }} className='mt-3'>Email message</Label>
                  <Input
                    type="textarea"
                    style={{ height: "288px", fontSize: "14px", fontWeight: "400", border: "1px solid #767676", fontFamily: 'Fira Sans' }}
                    name="message"
                    id="message"
                    value={message}
                    onChange={this.handleInputChange}
                  />
                </span>
              </Col>
            </Row>
          )}
          <Row className='mt-3 mb-5'>
            <Col sm="3"></Col>
            <Col sm="8">
              <button type='button' className='custom-button' onClick={this.generatePDF}>Generate PDF</button>
            </Col>
            <Col sm="1"></Col>
          </Row>
          { showPdfContent && (
            <div id="pdf-content" style={{ padding: "20px", fontFamily: 'Fira Sans', fontSize: '14px' }} >
              <h3 style={{  textAlign: "center" }}>{content}</h3>
              <div className='wrapped-generated' dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          )}
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
  listQuestionDetails: myStoryOperations.listQuestionDetails,
  sendPDF: myStoryOperations.sendPDF,
}

export default connect(mapStateToProps, mapDispatchToProps)(Pdf);
