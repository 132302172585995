import React, { Fragment } from "react";
import { Container, CardBody, Input, Col, Form, Row, Label } from "reactstrap";
import { myStoryOperations } from "../../state/ducks/story";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { AddQuestions } from "../../Constant";
import request from "../../utils/request";
import * as API_URL from "../../env.js";
import GoogleFontLoader from "react-google-font-loader";
import "../Button.css";

class AddQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "",
      showDetails: false,
      selectedCategory: "",
      selectedStoryData: "",
      showSecondDropdown: false,
      showInputField: false,
      selectedQuestionText: "",
      selectedQuestionDescription: "",
      category: [],
      question: [],
      contributor: [],
      language: [],
      selectedQuestion: "",
      contributor_name: "",
      selectedContributor: "",
      language_name: "",
      filteredQuestions: [],
      filteredContributors: [],
    };
  }

  // async componentDidMount() {
  //   try {
  //     this.loadLocalStorageData();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  async componentDidMount() {
    try {
      this.loadLocalStorageData();
      // console.log('Stored Token:', localStorage.getItem('token'));

    } catch (err) {
      console.log(err);
    }
  }
  
  loadLocalStorageData = async () => {
    const token = localStorage.getItem('token');
    // console.log('Stored Token:', token);
  
    let category = localStorage.getItem("category");
    if (category) {
      category = JSON.parse(category);
      this.setState({ category });
    } else {
      let response = await this.props.listCategory();
      // console.log('Category Response:', response);
      if (response.payload.length !== 0) {
        const searchString = JSON.stringify(response.payload);
        localStorage.setItem("category", searchString);
        category = JSON.parse(searchString);
        this.setState({ category });
      }
    }
  
    
    let language = localStorage.getItem("language");
    if (language) {
      language = JSON.parse(language);
      this.setState({ language });
    } else {
      let response = await this.props.listLanguage();
      // console.log('Language Response:', response);
      if (response.payload.length !== 0) {
        const searchString = JSON.stringify(response.payload);
        // console.log("searchString=====",searchString);
        localStorage.setItem("language", searchString);
        language = JSON.parse(searchString);
        this.setState({ language });
      }
    }

    const storyId = localStorage.getItem("selectedStoryId");
    // console.log("selectedStoryId:", storyId);
    const response = await this.props.getMyStory({ id: storyId });
    const storyData = response.payload;
    // console.log("Story Data:", storyData);
    this.setState({ selectedStoryData: storyData });
    
    let contributor = localStorage.getItem("contributor");
    if (contributor) {
      contributor = JSON.parse(contributor);
      this.setState({ contributor });
    } else {
      let response = await this.props.contributorNameList();
      // console.log('Contributor Response:', response);
      if (response.payload.length !== 0) {
        const searchString = JSON.stringify(response.payload);
        localStorage.setItem("contributor", searchString);
        contributor = JSON.parse(searchString);
        this.setState({ contributor });
      }
    }
    
  };
  

  // loadLocalStorageData = async () => {
  //   // fetch Store category
  //   let category = localStorage.getItem("category");
  //   if (category) {
  //     category = JSON.parse(category);
  //     this.setState({ category });
  //   } else {
  //     let response = await this.props.listCategory();
  //     if (response.payload.length !== 0) {
  //       const searchString = JSON.stringify(response.payload);
  //       localStorage.setItem("category", searchString);
  //       category = JSON.parse(searchString);
  //       this.setState({ category });
  //     }
  //   }

  //   //fetch contributor data
  //   let contributor = localStorage.getItem("contributor");
  //   if (contributor) {
  //     contributor = JSON.parse(contributor);
  //     this.setState({ contributor });
  //   } else {
  //     let response = await this.props.listContributor();
  //     if (response.payload.length !== 0) {
  //       const searchString = JSON.stringify(response.payload);
  //       localStorage.setItem("contributor", searchString);
  //       contributor = JSON.parse(searchString);
  //       this.setState({ contributor });
  //     }
  //   }
  //   //fetch Store Language
  //   let language = localStorage.getItem("language");
  //   if (language) {
  //     language = JSON.parse(language);
  //     this.setState({ language });
  //   } else {
  //     let response = await this.props.listLanguage();
  //     if (response.payload.length !== 0) {
  //       const searchString = JSON.stringify(response.payload);
  //       localStorage.setItem("language", searchString);
  //       language = JSON.parse(searchString);
  //       this.setState({ language });
  //     }
  //   }

  //   //fetch story data

  //   const storyId = localStorage.getItem("selectedStoryId");
  //   console.log("questionId===>>>>", storyId);
  //   const response = await this.props.getMyStory({ id: storyId });
  //   const storyData = response.payload;
  //   console.log("questions===>>>", storyData);
  //   this.setState({
  //     selectedStoryData: storyData,
  //   });
  // };

  onInsert = async (event) => {
    event.preventDefault();
    try {
      // console.log("seltestorydata====>", this.state.selectedStoryData);
      const { selectedStoryData } = this.state;
      if (!selectedStoryData) {
        console.log("No selected story data available");
        return;
      }
      const payload = {
        language: this.state.language_name,
        question: this.state.showSecondDropdown
          ? this.state.selectedQuestionData.question
          : this.state.question,
        description: this.state.showSecondDropdown
          ? this.state.selectedQuestionData.question_description
          : this.state.description,
        contributor_name: this.state.contributor_name,
        email: this.state.email,
        story_id: selectedStoryData.id,
        author_name: selectedStoryData.author,
      };
      // console.log("payload===", payload);
      const response = await this.props.addQuestion(payload);
      toast.success(response.message, { autoClose: 10000 });
      window.open("/my-stories/view-question/", "_self");
    } catch (err) {
      toast.error(err.response?.data?.message, { autoClose: 10000 });
      console.log(err.response.data.message);
    }
  };

  handleOptionChange = (e) => {
    const selectedOption = e.target.value;
    this.setState({ selectedOption, showDetails: true });
  };

  handleOptionQuestionChange = async (e) => {
    const selectedQuestionId = e.target.value;
    try {
      const options = {
        url:
          API_URL.BACKEND_SERVICE_API_URL +
          `/question/get-Question-details/${selectedQuestionId}`, // change this static id to wooCommerce UserId
        method: "GET",
        headers: {
          authorization: localStorage.getItem("token"), // change this token to wooCommerce user token
        },
      };
      let response = await request(options);
      // console.log(response.payload);
      const selectedQuestionData = response.payload;
      this.setState({ selectedQuestionData });
    } catch (err) {
      console.log(err);
    }
    // this.setState({ selectedQuestionId: response.payload, });
  };

  handleCategoryChange = async (e) => {
    const selectedCategory = e.target.value;

    this.setState({
      selectedCategory,
      showSecondDropdown: selectedCategory !== "",
    });
    let response = await this.props.listQuestionByCategory({
      category_id: selectedCategory,
    });
    if (response.payload && response.payload.length !== 0) {
      const searchString = JSON.stringify(response.payload);
      localStorage.setItem("question", searchString);
      const question = JSON.parse(searchString);
      this.setState({ searchString });
      this.setState({ question });
    } else {
      console.log("No questions fetched for the selected category.");
      localStorage.setItem("question", JSON.stringify([]));
      this.setState({ question: [] });
    }
  };

  handleContributorChange = (e) => {
    const selectedContributorId = parseInt(e.target.value, 10);
    const selectedContributor = this.state.contributor.find((c) => {
      return c.id === selectedContributorId;
    });
    if (selectedContributor) {
      this.setState({
        selectedContributor,
        contributor_name: selectedContributor.name,
        email: selectedContributor.email,
      });
    } else {
      this.setState({
        selectedContributor: null,
        contributor_name: "",
        email: "",
      });
    }
  };

  handleLanguageChange = async (e) => {
    const selectedLanguage = e.target.value;
    let category = localStorage.getItem("category");
    category = JSON.parse(category);
    const language = selectedLanguage === "English" ? category : [];

    this.setState({
      selectedLanguage,
      category: language,
      selectedQuestionData: selectedLanguage === "English",
    });
    let response = await this.props.listQuestionByLanguage({
      language_name: selectedLanguage,
    });
    if (response.payload && response.payload.length !== 0) {
      const searchString = JSON.stringify(response.payload);
      // console.log("searchingString====>>><><<><><><", searchString);
      localStorage.setItem("question", searchString);
      const question = JSON.parse(searchString);
      this.setState({ searchString });
      this.setState({ question });
    } else {
      console.log("No questions fetched for the selected language.");
      localStorage.setItem("question", JSON.stringify([]));
      this.setState({ question: [] });
    }
  };

  // handleLanguageChange = (e) => {
  //   const selectedLanguageId = parseInt(e.target.value, 10);
  //   const selectedLanguage = this.state.language.find(c => {
  //     return c.id === selectedLanguageId;
  //   });
  //   if (selectedLanguage) {
  //     this.setState({ selectedLanguage, language_name: selectedLanguage.language_name });
  //   } else {
  //     this.setState({ selectedLanguage: null });
  //   }
  // };

  handleCheckboxChange = (e) => {
    this.setState({ showInputField: e.target.checked });
  };

  render() {
    const {
      showInputField,
      category,
      contributor,
      question,
      selectedQuestionData,
      language,
    } = this.state;
  
    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: "Martel",
              weights: [300, 700],
            },
            {
              font: "Fira Sans",
              weights: [300, 700, 400],
            },
          ]}
        />
        <Container fluid={true}>
          <CardBody className="mPadding10">
            <Form
              onSubmit={this.onInsert}
              style={{ maxWidth: "950px", margin: "0 auto" }}
            >
              <Row>
                <h2
                  className="mt-5"
                  style={{
                    textAlign: "center",
                    font: "normal normal 400 2.2rem/1.4 Martel",
                    color: "#000",
                  }}
                >
                  Add question
                </h2>
                <div style={{ textAlign: "center" }}>
                  <span
                    style={{
                      borderBottom: "1px solid #FEC1EB",
                      height: "1px",
                      width: "102px",
                      display: "inline-block",
                    }}
                  ></span>
                </div>
              </Row>
              <Row
                className="mb-5 addQuestionMainPage"
                style={{ padding: "60px 10px 10px 10px" }}
              >
                {/* <Col md='2'></Col> */}
                <Col md="12">
                  <Row>
                    <Col
                      xs={12}
                      style={{
                        background: "#F4E4EF",
                        padding: "30px",
                        marginTop: "20px",
                        borderRadius: "20px",
                      }}
                    >
                      <Label
                        style={{
                          fontSize: "14px",
                          fontFamily: "Martel",
                          fontWeight: "400",
                        }}
                      >
                        Please select
                      </Label>
                      <div>
                        <label
                          style={{
                            font: "normal normal 300 1.0rem/1.5 Fira Sans",
                          }}
                        >
                          <input
                            type="radio"
                            value="option1"
                            checked={this.state.selectedOption === "option1"}
                            onChange={this.handleOptionChange}
                          />
                          &nbsp;Write your own question / Chapter.
                        </label>
                        <br></br>
                        <label
                          style={{
                            font: "normal normal 300 1.0rem/1.5 Fira Sans",
                          }}
                        >
                          <input
                            type="radio"
                            value="option2"
                            checked={this.state.selectedOption === "option2"}
                            onChange={this.handleOptionChange}
                          />
                          &nbsp;Select question from our library.
                        </label>
                        <br></br>
                        {/* <Label style={{ fontSize: "14px", fontFamily: "Martel", fontWeight: "400" }}>Please Select a Language</Label> */}
                        {/* <Input style={{
                          background: "#fff",
                          letterSpacing: "0.122rem",
                          border: "0",
                          padding: "12px 30px",
                          borderRadius: "50px",
                          margin: "10px 0 0",
                          font: "normal normal normal 15px/17px Fira Sans",
                          fontWeight: "400",
                          color: "#43107E"
                        }} type="select" name="language_name" id="language_name" onChange={this.handleLanguageChange} >
                          <option value=''>Select Language</option>
                          {language.map((data, i) =>
                            <option key={i} value={data.id}>{data.language_name}</option>
                          )}
                        </Input> */}
                      </div>
                    </Col>
                    <Input
                      type="select"
                      id="language_name"
                      name="language_name"
                      style={{
                        backgroundColor: "#F4E4EF",
                        letterSpacing: "0.122rem",
                        border: "0",
                        padding: "12px 30px",
                        borderRadius: "50px",
                        margin: "10px 0 0",
                        font: "normal normal normal 15px/17px Fira Sans",
                        fontWeight: "400",
                        color: "#43107E",
                      }}
                      onChange={this.handleLanguageChange}
                    >
                      <option value="">SELECT A LANGUAGE</option>
                      {language.map((data) => (
                        <option>{data.language_name}</option>
                      ))}
                    </Input>
                    {this.state.showDetails && (
                      <React.Fragment>
                        {this.state.selectedOption === "option1" ? (
                          <Col
                            xs={12}
                            style={{
                              background: "#eee",
                              padding: "30px",
                              marginTop: "20px",
                              borderRadius: "20px",
                            }}
                          >
                            <Label
                              style={{
                                font: "normal normal 300 1.0rem/1.5 Fira Sans",
                              }}
                            >
                              Question
                              <span style={{ color: "#F61C04" }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              name="question"
                              id="question"
                              onChange={(e) =>
                                this.setState({ question: e.target.value })
                              }
                            />
                            <Label
                              style={{
                                font: "normal normal 300 1.0rem/1.5 Fira Sans",
                              }}
                              className="mt-3"
                            >
                              Question Description
                            </Label>
                            <Input
                              type="textarea"
                              name="description"
                              id="description"
                              onChange={(e) =>
                                this.setState({ description: e.target.value })
                              }
                            />
                          </Col>
                        ) : (
                          <React.Fragment>
                            <Input
                              type="select"
                              id="select_category"
                              name="select_category"
                              style={{
                                backgroundColor: "#F4E4EF",
                                letterSpacing: "0.122rem",
                                border: "0",
                                padding: "12px 30px",
                                borderRadius: "50px",
                                margin: "10px 0 0",
                                font: "normal normal normal 15px/17px Fira Sans",
                                fontWeight: "400",
                                color: "#43107E",
                              }}
                              onChange={this.handleCategoryChange}
                              value={this.state.selectedCategory}
                            >
                              <option value="">SELECT A CATEGORY</option>
                              {category?.map((cat, i) => (
                                <option key={i} value={cat.id}>
                                  {cat.category_name}
                                </option>
                              ))}
                            </Input>
                            {this.state.showSecondDropdown && (
                              <Input
                                type="select"
                                id="second_dropdown"
                                name="second_dropdown"
                                style={{
                                  backgroundColor: "#F4E4EF",
                                  letterSpacing: "0.122rem",
                                  border: "0",
                                  padding: "12px 30px",
                                  borderRadius: "50px",
                                  margin: "10px 0 0",
                                  font: "normal normal normal 15px/17px Fira Sans",
                                  fontWeight: "400",
                                  color: "#43107E",
                                }}
                                onChange={this.handleOptionQuestionChange}
                                // value={this.state.selectedQuestion}
                              >
                                <option value="">SELECT A QUESTION</option>
                                {question.map((question, i) => (
                                  <option key={i} value={question.id}>
                                    {" "}
                                    {question.question}{" "}
                                  </option>
                                ))}
                              </Input>
                            )}
                            <Col
                              xs={12}
                              style={{
                                background: "#eee",
                                padding: "30px",
                                marginTop: "20px",
                                borderRadius: "20px",
                              }}
                            >
                              <Label
                                style={{
                                  font: "normal normal 300 1.0rem/1.5 Fira Sans",
                                }}
                              >
                                Question
                                <span style={{ color: "#F61C04" }}>*</span>
                              </Label>
                              <Input
                                type="text"
                                name="question"
                                id="question"
                                value={
                                  selectedQuestionData
                                    ? selectedQuestionData.question
                                    : ""
                                }
                              />
                              <Label
                                style={{
                                  font: "normal normal 300 1.0rem/1.5 Fira Sans",
                                }}
                                className="mt-3"
                              >
                                Question Description
                              </Label>
                              <Input
                                type="textarea"
                                name="description"
                                id="description"
                                value={
                                  selectedQuestionData
                                    ? selectedQuestionData.question_description
                                    : ""
                                }
                              />
                            </Col>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                    {showInputField === false ? (
                      <Col
                        xs={12}
                        style={{
                          background: "#F4E4EF",
                          padding: "30px",
                          marginTop: "20px",
                          borderRadius: "20px",
                        }}
                      >
                        <Label
                          style={{
                            fontSize: "14px",
                            fontFamily: "Martel",
                            fontWeight: "400",
                          }}
                        >
                          Select a contributor (optional)
                        </Label>
                        <br></br>
                        <Input
                          type="select"
                          id="contributor"
                          name="contributor"
                          style={{
                            backgroundColor: "#fff",
                            border: "0",
                            padding: "15px 15px 12px 30px",
                            borderRadius: "50px",
                            margin: "10px 0 0",
                            font: "normal normal normal 15px/17px Fira Sans",
                            fontWeight: "400",
                            color: "#43107E",
                          }}
                          onChange={this.handleContributorChange}
                        >
                          <option value="">Please Select a Contributor</option>
                          {contributor && contributor.length > 0 ? (
                            contributor.map((data, i) => (
                              <option key={i} value={data.id}>
                                {data.name}
                              </option>
                            ))
                          ) : (
                            <option disabled>No contributors available</option>
                          )}
                        </Input>
                        <br></br>
                        <Label
                          style={{
                            fontSize: "14px",
                            fontFamily: "Martel",
                            fontWeight: "400",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            onChange={this.handleCheckboxChange}
                          />
                          &nbsp; Add a new contributor
                        </Label>
                      </Col>
                    ) : (
                      <Col
                        xs={12}
                        style={{
                          background: "#F4E4EF",
                          padding: "30px",
                          marginTop: "20px",
                          borderRadius: "20px",
                        }}
                      >
                        <Label
                          style={{
                            fontSize: "14px",
                            fontFamily: "Martel",
                            fontWeight: "400",
                          }}
                        >
                          <input
                            type="checkbox"
                            onChange={this.handleCheckboxChange}
                            checked={showInputField}
                          />
                          &nbsp; Add a new contributor
                        </Label>
                        <br></br>
                        <Label
                          style={{
                            font: "normal normal 300 1.0rem/1.5 Fira Sans",
                          }}
                          className="mt-3"
                        >
                          Contributor Name
                        </Label>
                        <Input
                          type="text"
                          name="contributor_name"
                          id="contributor_name"
                          onChange={(e) =>
                            this.setState({ contributor_name: e.target.value })
                          }
                        />
                        <Label
                          style={{
                            font: "normal normal 300 1.0rem/1.5 Fira Sans",
                          }}
                          className="mt-3"
                        >
                          Contributor Email
                        </Label>
                        <Input
                          type="text"
                          name="cemail"
                          id="cemail"
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      </Col>
                    )}
                  </Row>
                  <Row className="mt-3">
                    {/* <Col xs={5}></Col> */}
                    <Col xs={12} style={{ textAlign: "center" }}>
                      <button
                        type="submit"
                        className="custom-button"
                        style={{ margin: "0 auto" }}
                      >
                        {AddQuestions}
                      </button>
                    </Col>
                    {/* <Col xs={4}></Col> */}
                  </Row>
                </Col>
                {/* <Col md='2'></Col> */}
              </Row>
            </Form>
          </CardBody>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUserId: state.auth.session.user.id,
    loggedInUserName: state.auth.session.user.user_name,
  };
};

const mapDispatchToProps = {
  addQuestion: myStoryOperations.addQuestion,
  contributorNameList: myStoryOperations.contributorNameList,
  listCategory: myStoryOperations.listCategory,
  listQuestionByCategory: myStoryOperations.listQuestionByCategory,
  listQuestionByLanguage: myStoryOperations.listQuestionByLanguage,
  listLanguage: myStoryOperations.listLanguage,
  // listMyStory: myStoryOperations.listMyStory
  getMyStory: myStoryOperations.getMyStory,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddQuestion);
