import React, { Fragment } from 'react';
import { Container, CardBody, Col, Form, Row } from 'reactstrap';
import story from '../../assets/images/datatable/story.png';
import { myStoryOperations } from '../../state/ducks/story';
import { connect } from 'react-redux';
import GoogleFontLoader from 'react-google-font-loader';
import { Link } from 'react-router-dom';
import '../Button.css';
import * as API_URL from "../../env.js";
class MyStories extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedStoryData: []
    }
  }

  async componentDidMount() {
    const { loggedInUserId } = this.props;
    if (!loggedInUserId) {
      console.log("No user ID found");
      return;
    }
    const response = await this.props.listMyStory({ "id": loggedInUserId });
    // console.log("response===>>>>", response);
    this.setState({ selectedStoryData: response.payload});
  }

  handleClick = (e) => {
    const storyId = e.currentTarget.getAttribute("data-test-id");
    // console.log("storyId====>>>",storyId);
    localStorage.setItem("selectedStoryId", storyId);
    window.open("/my-stories/edit-my-stories/", "_self");
  };

  handleAddQuestionClick = (e) => {
    const storyId = e.currentTarget.getAttribute("data-test-id");
    // console.log("storyId====>>>",storyId);
    localStorage.setItem("selectedStoryId", storyId);
    window.open("/my-stories/add-question/", "_self");
  };

  handleViewQuestionClick = (e) => {
    const storyId = e.currentTarget.getAttribute("data-test-id");
    // console.log("storyId====>>>",storyId);
    localStorage.setItem("selectedStoryId", storyId);
    window.open("/my-stories/view-question/", "_self");
  };

  render() {
    const { selectedStoryData } = this.state;
    const apiUrl = API_URL.API_URL;
    // console.log("selectedStoryData-===========",apiUrl, selectedStoryData.cover_page);
    const liStyle = {
      fontWeight: "500",
      display: 'flex',
      alignItems: 'center',
      fontSize: "12px"
    };
    const ulStyle = {
      display: 'flex',
      listStyle: 'none',
      padding: 0,
      justifyContent: 'center'
    };
    const spanStyle = {
      background: "#b3b3b3",
      display: "inline-block",
      height: "15px",
      width: "1px",
      margin: "0 10px"
    };

    // Style for the container holding story cards
    const cardContainerStyle = {
      display: 'flex',
      flexWrap: 'wrap', // Allows the cards to wrap to the next line if needed
      gap: '20px', // Adjust the spacing between cards
      justifyContent: 'center'
    };

    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [200, 500],
            },
          ]}
        />
        <Container>
          <CardBody>
            <Form style={{ padding: "10px" }}>
              <Row>
                <h2 className='mt-5 mainPageTitle' style={{ textAlign: 'center', font: 'normal normal 400 2.2rem/1.4 Martel', color: "#000" }}>My Stories</h2>
                <div style={{ textAlign: 'center' }}>
                  <span style={{ borderBottom: "2px solid #FEC1EB", height: "1px", width: "102px", display: "inline-block" }}></span>
                </div>
              </Row>
              <Row>
                <div className="myStoryDesc" style={{ font: "normal normal 300 1.1rem/1.5 Fira Sans", color: "#000", textAlign: "justify" }}>
                  Welcome! Below are the stories YOU are writing. Personalise your book cover in <span style={{ fontStyle: "italic" }}>story settings</span>, add or view questions, order more copies of your book, or just start writing!
                </div>
              </Row>
              {/* <Row style={cardContainerStyle}>
                {selectedStoryData.map(story => (
                  <div key={story.id} className='story-settings story-setting-container'>
                    <div className='my-book-details'>
                      <div className='details-book small-thumbnail' style={{ background: "#7c8f77", height: "240px", width: "200px", boxShadow: "-4px 5px 12px 0px #bababa", borderRadius: "2px" }}>
                        <div style={{ padding: "20% 10% 20% 13%" }}>
                          <img src={story.image || ''} width="120" height="120" alt='story' />
                        </div>
                        <div style={{ fontSize: ".6rem", textAlign: "center" }}>{story.title || ''}</div>
                      </div>
                      <div className='mt-3 my-book-title' style={{ paddingLeft: "5%", width: "60%" }}>
                        <div style={{ font: "normal normal 300 1.2rem/1.4 Martel", textTransform: "capitalize", color: "#000" }}>{story.title || ''}</div>
                        <div className='my-book-actions' style={{ color: "#8A1A75", display: "flex", flexDirection: "column", fontSize: "12px", padding: "1%", fontWeight: "500", fontFamily: "Fira Sans" }}>
                          <Link to='' data-test-id={story.id} onClick={this.handleClick} style={{ padding: "4px 0", justifyContent: "left", minHeight: "1px", marginBottom: "0px" }}>{'STORY SETTINGS >'}</Link>
                          <Link to='' data-test-id={story.id} onClick={this.handleAddQuestionClick}style={{ padding: "4px 0", justifyContent: "left", minHeight: "1px", marginBottom: "0px" }}>{'ADD QUESTIONS >'}</Link>
                          <Link to='' data-test-id={story.id} onClick={this.handleViewQuestionClick} style={{ padding: "4px 0", justifyContent: "left", minHeight: "1px", marginBottom: "0px" }}>{'VIEW QUESTIONS >'}</Link>
                          <a href='/my-stories/order-books/' style={{ padding: "4px 0", justifyContent: "left", minHeight: "1px", marginBottom: "0px" }}>{'PRINT MY BOOKS >'}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Row> */}
              <Row style={cardContainerStyle}>
              <div className='story-settings story-setting-container'>
              
                    <div className='my-book-details'>
                      <div className='details-book small-thumbnail' style={{ height: "240px", width: "200px", boxShadow: "-4px 5px 12px 0px #bababa", borderRadius: "2px" }}>
                        <div >
                       

      {selectedStoryData && selectedStoryData.cover_page ? (
        <img 
        //  src="/images/ac5900c6-a824-4a59-9619-8cf9367e33d7.png"
        src={`${apiUrl}/images/${selectedStoryData.cover_page.split('/').pop()}`} //use live
        //src={`${apiUrl}${selectedStoryData.cover_page}`}
          //src={`${apiUrl}/images/${selectedStoryData.cover_page.split('\\').pop()}`}   //use local
          alt='Cover Page' 
          style={{ width: "200px", height: "240px", objectFit: "cover" }}
          
        />
      ) : (
        <p>No cover image available</p>
      )}
                        </div>
                        {/* <div style={{ fontSize: ".6rem", textAlign: "center" }}>{selectedStoryData.title || ''}</div> */}

                      </div>
                      <div className='mt-3 my-book-title' style={{ paddingLeft: "5%", width: "60%" }}>
                        <div style={{ font: "normal normal 300 1.2rem/1.4 Martel", textTransform: "capitalize", color: "#000" }}>{selectedStoryData.title || ''}</div>
                        <div className='my-book-actions' style={{ color: "#8A1A75", display: "flex", flexDirection: "column", fontSize: "12px", padding: "1%", fontWeight: "500", fontFamily: "Fira Sans" }}>
                          <Link to='' data-test-id={selectedStoryData.id} onClick={this.handleClick} style={{ padding: "4px 0", justifyContent: "left", minHeight: "1px", marginBottom: "0px" }}>{'STORY SETTINGS >'}</Link>
                          <Link to='' data-test-id={selectedStoryData.id} onClick={this.handleAddQuestionClick}style={{ padding: "4px 0", justifyContent: "left", minHeight: "1px", marginBottom: "0px" }}>{'ADD QUESTIONS >'}</Link>
                          <Link to='' data-test-id={selectedStoryData.id} onClick={this.handleViewQuestionClick} style={{ padding: "4px 0", justifyContent: "left", minHeight: "1px", marginBottom: "0px" }}>{'VIEW QUESTIONS >'}</Link>
                          <a href='/my-stories/order-books/' style={{ padding: "4px 0", justifyContent: "left", minHeight: "1px", marginBottom: "0px" }}>{'PRINT MY BOOKS >'}</a>
                        </div>
                      </div>
                    </div>
                    </div>
                
              </Row>
              <Row className='mt-5 mb-4'>
                <div className="myStoriesLine" style={{ borderBottom: "1px solid #ccc", height: "2px", width: "100%" }}></div>
              </Row>
              <Row className='mt-1' style={{ marginBottom: "7%" }}>
                <ul style={ulStyle}>
                  <li style={liStyle}><Link to="https://gys.coalescets.com/">BUY A STORY</Link></li>
                  <span style={spanStyle}></span>
                  <li style={liStyle}><Link to="https://gys.coalescets.com/">GIFT A STORY</Link></li>
                </ul>
              </Row>
            </Form>
          </CardBody>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUserId: state.auth.session.user.id
  }
};

const mapDispatchToProps = {
  listMyStory: myStoryOperations.listMyStory
};

export default connect(mapStateToProps, mapDispatchToProps)(MyStories);
