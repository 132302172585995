import React, { Fragment } from "react";
import { Container, Card, CardBody, Col, Form, Row } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class PrintOrderConfirmed extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="page-title"></div>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card style={{boxShadow:"none"}}>
                <h1
                  style={{
                    textAlign: "center",
                    marginTop: "2.3%",
                    font: "normal normal 300 2.5rem/1.4 Martel",
                  }}
                >
                  Print order confirmed
                </h1>
                <span
                  style={{
                    borderBottom: "2px solid #FEC1EB",
                    height: "11px",
                    width: "102px",
                    margin: "10px auto",
                  }}
                ></span>
                <Card></Card>
              </Card>
            </Col>
          </Row>
          <div>
            <div style={{
                  background: "#eeeeee",
                  padding: "80px 0",
                  width: "100vw",
                  position: "relative",
                  left: "calc(-1*(100vw - 100%) / 2)",
                }}>
              <Container
                
              ></Container>

              <div>
                <row></row>
                <div>
                  <col></col>
                  <div>
                    <col mb-4 pt-0></col>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintOrderConfirmed);

