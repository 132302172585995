import React, { Fragment } from 'react';
import { Col } from 'reactstrap';
import { UL } from '../../../AbstractElements';
import { connect } from 'react-redux';
import user from '../../../assets/images/svg-icon/account-icon.svg';
import { Link } from 'react-router-dom';

class RightHeader extends React.Component {

  render() {
    var spanStyle = {
      background: "#e9d4e4",
      border: "2px solid #EDD3E5",
      color: "##404040",
      borderRadius: "30px",
      padding: "0.3rem 0.6rem",
      fontSize: "16px"
    };
    return (
      <Fragment>
        <Col xxl='7' xl='6' md='7' className='nav-right pull-right right-header col-8 p-0 ms-auto'>
          <UL attrUL={{ className: 'simple-list nav-menus flex-row' }}>
            <input type="hidden" id="custId" name="custId" value="3487"></input>
            <li className='profile-nav onhover-dropdown pe-0 py-0'>
              <div className='media profile-media'>
              <Link to = "/billing/"><button type='button' className='headerMyAccountBtn' style={spanStyle}><img src={user} width="20" height="20" alt='user' /><span style={{color: "#404040"}}>
                {this.props.isLoggedinHeader ? 'Log in' : 'My Account'}
                </span></button></Link>
              </div>
            </li>
          </UL>
        </Col>
      </Fragment>
    );
  }
}

const mapStateToProps = () => { return {} };

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RightHeader)