import React, { Fragment } from "react";
import { Container, Card, CardBody, Col, Form, Row } from "reactstrap";
import { Link } from "react-router-dom";
import * as API_URL from "../../env.js";
import request from "../../utils/request";
import GoogleFontLoader from "react-google-font-loader";
import "../Menu.css";
import { connect } from "react-redux";
import { myStoryOperations } from "../../state/ducks/story";

class Billing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOneDetails: false,
      showTwoDetails: false,
      selectedUserData: {},
      selectedUserOrderData: [],
      selectedGiftUserData: {},
      selectedGiftUserOrderData: [],
      selectedMenu: "",
      selectedStoryData: "",
    };
    this.toggleOneDetails = this.toggleOneDetails.bind(this);
    this.toggleTwoDetails = this.toggleTwoDetails.bind(this);
  }

  toggleOneDetails() {
    this.setState((prevState) => ({
      showOneDetails: !prevState.showOneDetails,
    }));
  }

  toggleTwoDetails() {
    this.setState((prevState) => ({
      showTwoDetails: !prevState.showTwoDetails,
    }));
  }

  async componentDidMount() {
    try {
      const { loggedInUserId } = this.props;
      if (!loggedInUserId) {
        console.log("No user ID found");
        return;
      }
      const storyResponse = await this.props.listMyStory({
        id: loggedInUserId,
      });
      this.setState({ selectedStoryData: storyResponse.payload });

      // this.loadLocalStorageData();
      // const questionId = localStorage.getItem("selectedQuestionId");
      const options = {
        url: API_URL.BACKEND_SERVICE_API_URL + `/user/order-details`,
        method: "GET",
        headers: {
          authorization: localStorage.getItem("token"),
        },
      };
      const options1 = {
        url: API_URL.BACKEND_SERVICE_API_URL + `/user/gift-order-details`,
        method: "GET",
        headers: {
          authorization: localStorage.getItem("token"),
        },
      };
      let response = await request(options);
      let response1 = await request(options1);

      // console.log("response1--------======>>>",response1);
      this.setState({ selectedUserData: response.payload.user });
      this.setState({ selectedUserOrderData: response.payload.userOrders });
      this.setState({ selectedGiftUserData: response1.payload.user });
      this.setState({
        selectedGiftUserOrderData: response1.payload.userOrders,
      });
      // this.setState({ selectedOrderData: response.payload });
    } catch (err) {
      console.log(err);
    }
  }

  onSendQuestions() {
    window.open("/send-questions/", "_self");
  }

  handleMenuClick = (menu) => {
    this.setState({ selectedMenu: menu });
  };

  render() {
    const {
      selectedMenu,
      selectedGiftUserData,
      selectedGiftUserOrderData,
      selectedUserData,
      selectedUserOrderData,
      showOneDetails,
      showTwoDetails,
      selectedStoryData,
    } = this.state;
    // console.log("selectedUserOrderData======>>>",selectedGiftUserData);
    // console.log("selectedGiftUserOrderData  ======>>>",selectedGiftUserOrderData);
    const filteredGiftUserOrderData = selectedGiftUserOrderData.filter(
      (order) => order.giftUserName.trim() !== ""
    );
    // console.log("filteredGiftUserOrderData=====",filteredGiftUserOrderData);
    const ulStyle = {
      display: "flex",
      color: "#a186be",
      listStyle: "none",
      padding: 0,
      textAlign: "center",
      justifyContent: "center",
      fontWeight: "300",
      fontFamily: "Fira Sans",
      fontSize: ".8rem",
    };

    const spanStyle = {
      background: "#fdc1ea",
      display: "inline-block",
      height: "20px",
      width: "1px",
      margin: "0 20px",
    };

    const liStyle = {
      cursor: "pointer",
      paddingBottom: "5px", // Add some padding to make the border more visible
    };
    const discountValue =
      selectedUserOrderData.length > 0 ? selectedUserOrderData[0].discount : "";
    // const discount = selectedUserOrderData.length > 0 ? selectedUserOrderData[0]?.discount: '';
    // console.log("discount====>>",discountValue);
    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: "Martel",
              weights: [300, 700],
            },
            {
              font: "Fira Sans",
              weights: [300, 700, 400],
            },
          ]}
        />
        <Container fluid={true}>
          <CardBody>
            <Form>
              <Row>
                <h2
                  className="mt-5"
                  style={{
                    textAlign: "center",
                    font: "normal normal 300 2.2rem/1.4 Martel",
                    color: "#000",
                  }}
                >
                  My Account
                </h2>
                <div style={{ textAlign: "center" }}>
                  <span
                    style={{
                      borderBottom: "1px solid #FEC1EB",
                      height: "1px",
                      width: "102px",
                      display: "inline-block",
                    }}
                  ></span>
                </div>
                <ul className="mt-4 myAccountMenu" style={ulStyle}>
                  <li
                    style={
                      selectedMenu === "billing"
                        ? { ...liStyle, borderBottom: "2px solid #000" }
                        : liStyle
                    }
                    onClick={() => this.handleMenuClick("billing")}
                  >
                    <Link
                      to="/billing/"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                        fontSize: "14.4px",
                      }}
                    >
                      BILLING
                    </Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li
                    style={
                      selectedMenu === "my-details"
                        ? { ...liStyle, borderBottom: "2px solid #000" }
                        : liStyle
                    }
                    onClick={() => this.handleMenuClick("my-details")}
                  >
                    <Link
                      to="/my-details/"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                        fontSize: "14.4px",
                      }}
                    >
                      MY DETAILS
                    </Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li
                    style={
                      selectedMenu === "emails"
                        ? { ...liStyle, borderBottom: "2px solid #000" }
                        : liStyle
                    }
                    onClick={() => this.handleMenuClick("emails")}
                  >
                    <Link
                      to="/emails/"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                        fontSize: "14.4px",
                      }}
                    >
                      EMAILS
                    </Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li
                    style={
                      selectedMenu === "reset-password"
                        ? { ...liStyle, borderBottom: "2px solid #000" }
                        : liStyle
                    }
                    onClick={() => this.handleMenuClick("reset-password")}
                  >
                    <Link
                      to="/reset-password/"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                        fontSize: "14.4px",
                      }}
                    >
                      RESET PASSWORD
                    </Link>
                  </li>
                </ul>
              </Row>
              <Row>
                <Col md="2"></Col>
                <Col md="8">
                  <div
                    className="billingMyStoryTitle"
                    style={{
                      fontSize: "17px",
                      fontWeight: "400",
                      fontFamily: "Martel",
                      borderBottom: "1px solid #fdc1ea",
                      paddingBottom: "12px",
                      marginBottom: "30px",
                    }}
                  >
                    My Stories
                  </div>
                </Col>
                <Col md="2"></Col>

                <Col md="2"></Col>
                <Col md="8">
                  {selectedUserOrderData.length > 0 ? (
                    selectedUserOrderData.map((order, index) => (
                      <Card>
                        <Row className="billingMyStoryGrid">
                          <Col
                            xs={10}
                            style={{
                              background: "#EEE",
                              padding: "2% 0% 3% 4%",
                            }}
                          >
                            <p
                              className="billingIteamListingTitle"
                              style={{ fontSize: "30px", fontFamily: "Martel" }}
                            >
                              Story:{selectedStoryData.title || ""}
                            </p>
                            <div
                              style={{
                                marginBottom: "1rem",
                                marginTop: "0",
                                fontSize: "17px",
                                color: "#615F67",
                              }}
                            >
                              Package{" "}
                              <strong
                                style={{ color: "#615F67", fontSize: "17px" }}
                              >
                                {order.packages[0]?.package || ""}
                                {/* {selectedUserOrderData.length > 0 ? selectedUserOrderData[0].packages[0].package : ''} */}
                              </strong>
                            </div>
                            <a href="#">
                              <div
                                onClick={this.toggleOneDetails}
                                style={{ color: "#8A1A75", fontSize: "12px" }}
                              >
                                {showOneDetails
                                  ? "VIEW PACKAGE DETAILS >"
                                  : "VIEW PACKAGE DETAILS >"}
                              </div>
                            </a>
                          </Col>
                          <Col
                            xs={2}
                            style={{
                              background: "#E0E0E0",
                              textAlign: "center",
                              padding: "2% 1% 3% 1%",
                            }}
                          >
                            <div
                              style={{ fontSize: "15px", fontFamily: "Martel" }}
                            >
                              Expiry
                            </div>
                            <div
                              style={{ fontSize: "24px", fontFamily: "Martel" }}
                            >
                              {order.expiryDate || ""}

                              {/* {selectedUserOrderData.length > 0 ? selectedUserOrderData[0].expiryDate : ''} */}
                            </div>
                          </Col>
                        </Row>

                        {showOneDetails && (
                          <Row>
                            <Col
                              xs={8}
                              style={{
                                background: "#8A1A75",
                                fontFamily: "Fira Sans",
                                color: "#fff",
                              }}
                            >
                              <div style={{ padding: "1%" }}>
                                Order ID:Order ID: {order.orderId || ""}
                              </div>

                              {/* <div style={{ padding: "1%" }}>Order ID: {selectedUserOrderData.length > 0 ? selectedUserOrderData[0].orderId : ''}</div> */}
                              {/* <div style={{ padding: "1%" }}>Invoice Number: GYS00190</div> */}
                            </Col>
                            <Col
                              xs={4}
                              style={{ background: "#8A1A75", color: "#fff" }}
                            >
                              <div style={{ padding: "2%" }}>
                                Time of receipt: {order.timeOfReceipt || ""}
                              </div>

                              {/* <div style={{ padding: "2%" }}>Time of receipt: {selectedUserOrderData.length > 0 ? selectedUserOrderData[0].timeOfReceipt : ''}</div> */}
                              {/* <div style={{ padding: "2%" }}>Transaction ID: 4567787678987</div> */}
                            </Col>
                            <Col
                              xs={12}
                              style={{
                                background: "#EEE",
                                padding: "2% 0% 2% 4%",
                                borderBottom: "2px solid #FEC1EB",
                              }}
                            >
                              <div>
                                Number of items: {order.numberOfItems || ""}
                              </div>

                              {/* <div>Number of items: {selectedUserOrderData.length > 0 ? selectedUserOrderData[0].numberOfItems : ''}</div> */}
                              {/* <div>Number of printed books: 1</div> */}
                            </Col>

                            {discountValue && (
                              <>
                                <Col
                                  xs={9}
                                  style={{
                                    background: "#EEE",
                                    fontSize: "17.6px",
                                    fontWeight: "200",
                                    padding: "1% 4%",
                                    borderBottom: "1px solid #00000026",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: "#615F67",
                                      fontFamily: "Fira Sans",
                                    }}
                                  >
                                    Discount
                                  </div>
                                </Col>
                                <Col
                                  xs={3}
                                  style={{
                                    background: "#EEE",
                                    fontSize: "17.6px",
                                    fontWeight: "bolder",
                                    color: "#615F67",
                                    padding: "1% 4%",
                                    borderBottom: "1px solid #00000026",
                                    textAlign: "right",
                                  }}
                                >
                                  <div>£{discountValue}</div>
                                </Col>
                              </>
                            )}

                            <Col
                              xs={9}
                              style={{
                                background: "#EEE",
                                fontSize: "17.6px",
                                fontWeight: "200",
                                padding: "1% 4%",
                                borderBottom: "1px solid #00000026",
                              }}
                            >
                              <div
                                style={{
                                  color: "#615F67",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                Subtotal
                              </div>
                            </Col>
                            <Col
                              xs={3}
                              style={{
                                background: "#EEE",
                                fontSize: "17.6px",
                                fontWeight: "bolder",
                                color: "#615F67",
                                padding: "1% 4%",
                                borderBottom: "1px solid #00000026",
                                textAlign: "right",
                              }}
                            >
                              {/* <div>£{parseFloat(selectedUserOrderData[0]?.subtotal).toFixed(2) || ''}</div> */}
                              <div>
                                £{parseFloat(order.subtotal).toFixed(2) || ""}
                              </div>
                            </Col>

                            <Col
                              xs={9}
                              style={{
                                background: "#EEE",
                                fontSize: "17.6px",
                                fontWeight: "200",
                                padding: "1% 4%",
                                borderBottom: "",
                              }}
                            >
                              <div
                                style={{
                                  color: "#615F67",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                Total
                              </div>
                            </Col>
                            <Col
                              xs={3}
                              style={{
                                background: "#EEE",
                                fontSize: "17.6px",
                                fontWeight: "bolder",
                                color: "#615F67",
                                padding: "1% 4%",
                                borderBottom: "",
                                textAlign: "right",
                              }}
                            >
                              {/* <div>£{parseFloat(selectedUserOrderData[0]?.total).toFixed(2) || ''}</div> */}
                              <div>
                                £{parseFloat(order.total).toFixed(2) || ""}
                              </div>
                            </Col>

                            <Col
                              xs={12}
                              style={{ background: "#EEE", padding: "1%" }}
                            ></Col>
                          </Row>
                        )}
                      </Card>
                    ))
                  ) : (
                    <p>No orders found.</p>
                  )}
                </Col>
                <Col md="2"></Col>
                <Col md="2"></Col>
                <Col md="8">
                  <div
                    style={{
                      fontSize: "1.25rem",
                      borderBottom: "2px solid #fdc1ea",
                      paddingBottom: "12px",
                      marginBottom: "30px",
                      fontFamily: "Martel",
                    }}
                  >
                    Stories I've gifted
                  </div>
                </Col>
                <Col md="2"></Col>
                <Col md="2"></Col>
                <Col md="8">
                  {filteredGiftUserOrderData.length > 0 ? (
                    filteredGiftUserOrderData.map((orderData, index) => (
                      <Card key={index}>
                        <Row className="billingMyStoryGrid row mobileCenterGiftedTo">
                          <Col
                            xs={10}
                            style={{
                              background: "#F7EBF3",
                              padding: "2% 0% 3% 4%",
                            }}
                          >
                            {/* <p style={{ fontSize: "30px", fontFamily: "Martel" }}>Gifted to {selectedGiftUserOrderData[0]?.giftUserName || ''}</p> */}
                            <p
                              style={{ fontSize: "30px", fontFamily: "Martel" }}
                            >
                              Gifted to {orderData.giftUserName || ""}
                            </p>

                            <div
                              style={{
                                marginBottom: "1rem",
                                marginTop: "0",
                                fontSize: "17px",
                                color: "#615F67",
                              }}
                            >
                              Package{" "}
                              <strong
                                style={{ color: "#615F67", fontSize: "17px" }}
                              >
                                {/* {selectedUserOrderData.length > 0 ? selectedUserOrderData[0].packages[0].package : ''} */}
                                {orderData.packages[0]?.package || ""}
                              </strong>
                            </div>
                            <div
                              onClick={this.toggleTwoDetails}
                              style={{
                                color: "#8A1A75",
                                paddingBottom: "1%",
                                fontSize: "12px",
                              }}
                            >
                              {showTwoDetails
                                ? "VIEW PACKAGE DETAILS >"
                                : "VIEW PACKAGE DETAILS >"}
                            </div>
                            <button
                              type="button"
                              style={{
                                background: "#8A1A75",
                                letterSpacing: "0.15rem",
                                border: "2px solid #8A1A75",
                                fontWeight: "300",
                                color: "#fff",
                                borderRadius: "30px",
                                padding: "0 20px",
                                textTransform: "uppercase",
                                minHeight: "45px",
                              }}
                              onClick={this.onSendQuestions}
                            >
                              {"Send questions >"}
                            </button>
                          </Col>
                          <Col
                            xs={2}
                            style={{
                              background: "#EDD3E5",
                              textAlign: "center",
                              padding: "2% 1% 3% 1%",
                            }}
                          >
                            <div
                              style={{ fontSize: "15px", fontFamily: "Martel" }}
                            >
                              Expiry
                            </div>
                            <div
                              style={{ fontSize: "24px", fontFamily: "Martel" }}
                            >
                              {/* {selectedGiftUserOrderData.length > 0 ? selectedGiftUserOrderData[0].expiryDate : ''} */}
                              {orderData.expiryDate || ""}
                            </div>
                          </Col>

                          {showTwoDetails && (
                            <Row>
                              <Col
                                xs={8}
                                style={{ background: "#8A1A75", color: "#fff" }}
                              >
                                <div style={{ padding: "1%" }}>
                                  Order ID:
                                  {/* {selectedUserOrderData.length > 0 ? selectedUserOrderData[0].orderId : ''} */}
                                  {orderData.orderId || ""}
                                </div>
                                {/* <div style={{ padding: "1%" }}>Invoice Number: GYS00190</div> */}
                              </Col>
                              <Col
                                xs={4}
                                style={{ background: "#8A1A75", color: "#fff" }}
                              >
                                <div style={{ padding: "2%" }}>
                                  Time of receipt:
                                  {/* {selectedGiftUserOrderData.length > 0 ? selectedGiftUserOrderData[0].timeOfReceipt : ''} */}
                                  {orderData.timeOfReceipt || ""}
                                </div>
                                {/* <div style={{ padding: "2%" }}>Transaction ID: 4567787678987</div> */}
                              </Col>

                              <Col
                                xs={12}
                                style={{
                                  background: "#EEE",
                                  fontSize: "15px",
                                  padding: "2% 4%",
                                  borderBottom: "2px solid #FEC1EB",
                                }}
                              >
                                <div>
                                  <b>
                                    Your story -
                                    {/* {selectedGiftUserOrderData[0]?.giftUserName || '' } */}
                                    {orderData.giftUserName || ""}
                                  </b>
                                </div>
                                <div>
                                  You purchased a subscription including one
                                  book for{" "}
                                  <b>
                                    {/* {selectedGiftUserOrderData[0]?.giftUserName || ''} */}
                                    {orderData.giftUserName || ""}
                                  </b>
                                </div>
                                <div>
                                  Emailed to{" "}
                                  <b>
                                    {/* {selectedGiftUserData?.email || ''} */}
                                    {orderData.email || ""}
                                  </b>
                                </div>
                                <div>
                                  Package{" "}
                                  <b>
                                    {/* {selectedUserOrderData.length > 0 ? selectedUserOrderData[0].packages[0].package : ''} */}
                                    {orderData.packages[0]?.package || ""}
                                  </b>
                                </div>
                              </Col>
                              <Col xs={9}> </Col>
                              {/* <Col xs={12} style={{ background: "#EEE", padding: "2% 4%", borderBottom: "2px solid #FEC1EB" }}>
              <div>Number of items: {selectedUserOrderData.length > 0 ? selectedUserOrderData[0].numberOfItems : ''}</div>
              <div>Number of printed books: 1</div>
            </Col> */}

                              {discountValue && (
                                <>
                                  <Col
                                    xs={9}
                                    style={{
                                      background: "#EEE",
                                      fontSize: "17.6px",
                                      fontWeight: "200",
                                      padding: "1% 0%",
                                      borderBottom: "1px solid #00000026",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#615F67",
                                        fontFamily: "Fira Sans",
                                      }}
                                    >
                                      Discount
                                    </div>
                                  </Col>
                                  <Col
                                    xs={3}
                                    style={{
                                      background: "#EEE",
                                      fontSize: "17.6px",
                                      fontWeight: "bolder",
                                      color: "#615F67",
                                      padding: "1% 0%",
                                      borderBottom: "1px solid #00000026",
                                      textAlign: "right",
                                    }}
                                  >
                                    <div>£{discountValue}</div>
                                  </Col>
                                </>
                              )}

                              <Col
                                xs={9}
                                style={{
                                  background: "#EEE",
                                  fontSize: "17.6px",
                                  fontWeight: "200",
                                  padding: "1% 4%",
                                  borderBottom: "1px solid #00000026",
                                }}
                              >
                                <div
                                  style={{
                                    color: "#615F67",
                                    fontFamily: "Fira Sans",
                                  }}
                                >
                                  Subtotal
                                </div>
                              </Col>
                              <Col
                                xs={3}
                                style={{
                                  background: "#EEE",
                                  fontSize: "17.6px",
                                  fontWeight: "bolder",
                                  color: "#615F67",
                                  padding: "1% 4%",
                                  borderBottom: "1px solid #00000026",
                                  textAlign: "right",
                                }}
                              >
                                {/* <div>£{parseFloat(selectedUserOrderData[0]?.subtotal).toFixed(2) || ''}</div> */}
                                <div>
                                  £
                                  {parseFloat(orderData?.subtotal).toFixed(2) ||
                                    ""}
                                </div>
                              </Col>

                              <Col
                                xs={9}
                                style={{
                                  background: "#EEE",
                                  fontSize: "17.6px",
                                  fontWeight: "200",
                                  padding: "1% 4%",
                                  borderBottom: "",
                                }}
                              >
                                <div
                                  style={{
                                    color: "#615F67",
                                    fontFamily: "Fira Sans",
                                  }}
                                >
                                  Total
                                </div>
                              </Col>
                              <Col
                                xs={3}
                                style={{
                                  background: "#EEE",
                                  fontSize: "17.6px",
                                  fontWeight: "bolder",
                                  color: "#615F67",
                                  padding: "1% 4%",
                                  borderBottom: "",
                                  textAlign: "right",
                                }}
                              >
                                {/* <div>£{parseFloat(selectedUserOrderData[0]?.total).toFixed(2) || ''}</div> */}
                                <div>
                                  £
                                  {parseFloat(orderData?.total).toFixed(2) ||
                                    ""}
                                </div>
                              </Col>

                              <Col
                                xs={12}
                                style={{ background: "#EEE", padding: "1%" }}
                              ></Col>
                            </Row>
                          )}
                        </Row>
                      </Card>
                    ))
                  ) : (
                    <div>No gifts found</div>
                  )}
                </Col>
                <Col md="2"></Col>
              </Row>
            </Form>
          </CardBody>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { loggedInUserId: state.auth.session.user.id };
};

const mapDispatchToProps = {
  SendAnInviteToStory: myStoryOperations.SendAnInviteToStory,
  listMyStory: myStoryOperations.listMyStory,
  // listQuestion: myStoryOperations.listQuestion,
};
export default connect(mapStateToProps, mapDispatchToProps)(Billing);
// export default Billing;
