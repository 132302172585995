import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, CardBody, Col, Form, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import GoogleFontLoader from 'react-google-font-loader';
import * as API_URL from "../../env.js";
import request from "../../utils/request.js";

const Contributors = () => {
  const [allContributorData, setAllContributorData] = useState({});
  const [allQuestionData, setAllQuestionData] = useState([]);

  const { hashkey } = useParams(); // Get userId from URL parameters

  useEffect(() => {

    if (hashkey) {
      sessionStorage.setItem('contributorHashKey', hashkey);
    }
    const fetchContributorData = async () => {
      try {
        const options = {
          url: `${API_URL.BACKEND_SERVICE_API_URL}/contributor/contributor-question-list/${hashkey}`,
          method: "GET",
        };
        let response = await request(options);
        setAllContributorData(response.payload);

        // Mapping questions
        const mappedQuestions = response.payload.questions.map(q => ({
          question_id: q.id,
          question_text: q.question,
          question_last_edited: q.last_edited,
        }));

        setAllQuestionData(mappedQuestions);
      } catch (err) {
        console.log(err);
      }
    };
    fetchContributorData();
  }, [hashkey]);

  const handleEditClick = (e) => {
    const questionId = e.currentTarget.getAttribute('data-test-id');
    localStorage.setItem('selectedQuestionId', questionId);
    window.open('/question-details/', '_self');
  };

  const handleViewClick = (e) => {
    const questionId = e.currentTarget.getAttribute('data-test-id');
    localStorage.setItem('selectedQuestionId', questionId);
    window.open('/contributor-view-question/', '_self');
  };

  return (
    <React.Fragment>
      <GoogleFontLoader
        fonts={[
          { font: 'Martel', weights: [300, 700] },
          { font: 'Fira Sans', weights: [300, 400, 700] },
        ]}
      />
      <Container fluid={true}>
        <CardBody>
          <Form style={{ maxWidth: "930px", margin: "0 auto" }}>
            <Row>
              <h2 className='mt-5' style={{ textAlign: 'center', font: 'normal normal 300 2.5rem/1.4 Martel', color: "#000" }}>Contributor dashboard</h2>
              <div style={{ textAlign: 'center' }}>
                <span style={{ borderBottom: "1px solid #FEC1EB", height: "1px", width: "102px", display: "inline-block" }}></span>
              </div>
            </Row>
            <Row className='mt-3'>
              <Col md='12'>
                <div className='mt-3' style={{ textAlign: 'left' }}>
                  <span style={{ font: 'normal normal 300 1.6rem / 1.4 Martel', color: '#000', padding: "0px 0px 16px" }}>Hi {allContributorData.name} </span>
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Col md='12'>
                <div style={{ textAlign: 'left' }}>
                  <span style={{ font: 'normal normal 300 1.1rem / 1.5 Fira Sans', color: '#404040' }}>This is your dashboard where you can view all questions assigned to you, along with progress status</span>
                </div>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col md='12'>
                <div className='mt-3' style={{ textAlign: 'left' }}>
                  <span style={{ font: 'normal normal 700 1.25rem Fira Sans', color: '#000', padding: "0px 0px 16px" }}>Questions </span>
                </div>
              </Col>
            </Row>
            <div key={allContributorData.id} style={{ marginBottom: "40px", marginTop: "40px" }}>
              <Row>
                <Col md='12'>
                  <div style={{ background: "#eee", padding: "1.5rem 2rem", font: "normal normal 400 .75rem/1 Fira Sans" }}>
                    {allQuestionData.map(question => (
                      <div className="contributorIteam" key={question.question_id} style={{ marginTop: "20px", background: "#fff", border: "0", borderRadius: "9px", position: "relative", fontWeight: "400", display: "flex" }}>
                        <div className='contributorIteamQ' style={{ background: "#8A1A75", borderRadius: "10px 0 0 10px", width: "25px", display: "flex", alignItems: "center", justifyContent: "center", color: "#fff" }}><span>Q</span></div>
                        <div style={{ paddingLeft: "20px", flexGrow: 1, paddingTop: "1%" }}>
                          <span style={{ font: "normal normal 300 1.1rem / 1.1 Martel", display: "block", marginBottom: "0" }}>{question.question_text}</span>
                          <span style={{ font: "normal normal 300 1.1rem / 1.1 Martel", display: "block", marginBottom: "1%" }}><span style={{ fontWeight: "300", fontFamily: 'Fira Sans', paddingBottom: "5px" }}>Last edited:</span> {question.question_last_edited}</span>
                        </div>
                        <div className='contributorIteamEditOption' style={{ background: "#fff", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "0 10px 10px 0" }}>
                          <ul style={{ display: 'flex', padding: "2px 0px 10px 0px", fontSize: "12px", fontWeight: "400" }}>
                            <li style={{ padding: "0 10px" }} data-test-id={question.question_id} onClick={handleViewClick}><Link to="#">VIEW</Link></li>
                            <span style={{ background: "#FEC1EB", color: "transparent", display: "inline-block", position: "relative", verticalAlign: "bottom", height: "15px", width: "2px" }}></span>
                            <li style={{ padding: "0 10px" }} data-test-id={question.question_id} onClick={handleEditClick}><Link to="#">EDIT</Link></li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        </CardBody>
      </Container>
    </React.Fragment>
  );
};

export default Contributors;
