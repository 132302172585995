import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  Container,
  CardBody,
  Col,
  Form,
  Row,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import story from "../../assets/images/datatable/story.png";
import { Btn } from "../../AbstractElements";
import { FaTimes } from "react-icons/fa";
import GoogleFontLoader from "react-google-font-loader";
import "../Button.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { myStoryOperations } from "../../state/ducks/story";
import { Table } from "react-bootstrap";
import { isCancel } from "axios";
import AddAdditionalOrderBook from "./AddAdditionalOrderBook";
import PaymentComponent from '../Payment/Payment'; 
// import StripeProvider from '../Payment/StripeProvider'; 
class OrderBooks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInputField: false,
      modalBookOpen: false,
      modalAdditionalBookOpen: false,
      modalAdditionalBookOpenEdit: false,
      modalOrderBookOpenEdit: false,
      selected: "colour",
      selectedOption: "",
      selectedStoryData: {},
      print_color_id: null, // initialize the state variable
      colorPrice: 0,
      copies: 1,
      shippingPrice: 0,
      subtotal: 0,
      additionalBooks: [],
      total: "",
      orderBooks: [],
      selectedAdditionalOrderData: {},
      selectedOrderBookData: {},
      isDeleteConfirm: false,
      isCancelConfirm: false,
      showPayment: false,
    };
  }

  async componentDidMount() {
    const { loggedInUserId } = this.props;
    if (!loggedInUserId) {
      console.log("No user ID found");
      return;
    }
    const response = await this.props.listMyStory({ "id": loggedInUserId });
    // console.log("response===>>>>", response);
    this.setState({ selectedStoryData: response.payload });
  
    await this.fetchAdditionalBooks();
    await this.fetchOrderBooks();
  }

  async componentDidUpdate(prevProps, prevState) {
    // Recalculate subtotal if prices or options change
    if (
      prevState.colorPrice !== this.state.colorPrice ||
      prevState.shippingPrice !== this.state.shippingPrice
    ) {
      this.calculateSubtotal();
    }

    // Fetch additional books only if the flag is true
    if (this.state.shouldFetchAdditionalBooks) {
      await this.fetchAdditionalBooks();
    }
    if (this.state.shouldFetchOrderBooks) {
      await this.fetchOrderBooks();
    }
  }

  fetchAdditionalBooks = async () => {

    try {
      const response = await this.props.listAdditionalOrderBook();
      // console.log("response===>>>", response);
      this.setState({
        id: response.payload.id,
        additionalBooks: response.payload.orderBooksWithShipping,
        total: response.payload.totalSubtotal,
        shouldFetchAdditionalBooks: false, // Reset the flag after fetching
      });
    } catch (error) {
      this.setState({
        error: error.message,
        shouldFetchAdditionalBooks: false, // Reset the flag if there's an error
      });
    }
  };

  fetchOrderBooks = async () => {
    try {
      const response = await this.props.listOrderBook();
      // console.log("response===>>>", response);
      this.setState({
        orderBooks: response.payload,
        shouldFetchOrderBooks: false, // Reset the flag after fetching
      });
    } catch (error) {
      this.setState({
        error: error.message,
        shouldFetchOrderBooks: false, // Reset the flag if there's an error
      });
    }
  };

  calculateSubtotal = () => {
    const subtotal = this.state.colorPrice + this.state.shippingPrice;
    this.setState({ subtotal });
  };

  onInsertAdditional = async (event) => {
    event.preventDefault();
    try {
      const payload = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        copies: this.state.copies,
        address: this.state.address,
        address2: this.state.address2,
        city: this.state.city,
        state: this.state.state,
        postcode: this.state.postcode,
        shipping_id: this.state.selectedOption,
        print_color_id: this.state.print_color_id,
        subtotal: this.state.subtotal,
      };
      const response = await this.props.addAdditionalOrderBook(payload);
      toast.success(response.message, { autoClose: 10000 });
      window.open("/my-stories/order-books/", "_self");
    } catch (err) {
      toast.error(err.response?.data?.message, { autoClose: 10000 });
      console.log(err);
    }
  };

  onCancelConfirm = (e) => {
    if (this.state.isCancelConfirm) {
      this.handleShowAdditionalBookEdit(e);
    } else {
      this.setState({
        isCancelConfirm: true,
      });
      setTimeout(() => {
        this.setState({
          isCancelConfirm: false,
        });
      }, 3000);
    }
  };

  onDeleteConfirm = (e) => {
    if (this.state.isDeleteConfirm) {
      this.onDeleteClick(e);
    } else {
      this.setState({
        isDeleteConfirm: true,
      });
      setTimeout(() => {
        this.setState({
          isDeleteConfirm: false,
        });
      }, 3000);
    }
  };

  onDeleteClick = async (e) => {
    e.preventDefault();
    try {
      let additionalOrderId = e.target.getAttribute("id");
      // console.log("delete id===>>", additionalOrderId);
      const response = await this.props.deleteAdditionalOrderBook({
        id: additionalOrderId,
      });
      toast.success(response.message, { autoClose: 10000 });
      window.open("/my-stories/order-books/", "_self");
      // this.setState((prevState) =>({
      //   additionalBooks: prevState.additionalBooks.filter(book => book.id !== additionalOrderId)
      // }));
    } catch (err) {
      console.log(err);
    }
  };

  combinedClickHandler = async (e) => {
    e.preventDefault();
    const additionalOrderId = e.target.getAttribute("data-test-id");
    try {
      // Fetch the data for the specific item from the server
      const response = await this.props.fetchAdditionalOrderBookById({
        orderBookId: additionalOrderId,
      });
      // console.log("response---===>>>>>>", response);
      const additionalOrderData = response.payload;

      // Map shipping_name to the correct radio button value, if necessary
      const shippingOptionMapping = {
        "UK and Europe": "1",
        USA: "2",
        Canada: "3",
        Israel: "4",
        Dubai: "5",
      };

      const selectedOption =
        shippingOptionMapping[additionalOrderData.shipping_name] || "";

      // Set the fetched data to the component state
      this.setState({
        selectedAdditionalOrderData: additionalOrderData,
        id: additionalOrderData.id,
        copies: additionalOrderData.copies,
        first_name: additionalOrderData.first_name,
        last_name: additionalOrderData.last_name,
        address: additionalOrderData.address,
        address2: additionalOrderData.address2,
        city: additionalOrderData.city,
        state: additionalOrderData.state,
        postcode: additionalOrderData.postcode,
        selectedOption: selectedOption,
        // selectedOption: additionalOrderData.shipping_name,
        // shippingName: additionalOrderData.shipping_name,
        print_color_id: additionalOrderData.print_color_id,
        subtotal: additionalOrderData.subtotal,
        modalAdditionalBookOpenEdit: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // handleInput = async (e) => {
  //   try {
  //     const { id, value } = e.target;

  //     const validValue = Math.max(1, Number(value));
  //     this.setState(prevState => ({
  //       selectedAdditionalOrderData: {
  //         ...prevState.selectedAdditionalOrderData,
  //         [id]: value
  //       }
  //     }));
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  handleGoToPayment = async (event) => {
    event.preventDefault();
    
    try {
      const { total } = this.state;
      // console.log("subtotal====",total * 100);
      const amountInPence = total * 100;
      let paymentResponse = await this.props.Payment({
        amount: amountInPence,
        payment_method_id: "pm_card_visa"
      });
      const url = paymentResponse.payload;
    if (url) {
      window.location.href = url; // Redirects to the payment page
  } else {
      toast.error("No payment URL provided.", { autoClose: 10000 });
  }
    } catch (err) {
      toast.error(err.response?.data?.message || "An unexpected error occurred", { autoClose: 10000 });
      console.log(err);
    }
    this.setState({ showPayment: true });
  };

  handleInput = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (value > 0) {
      updatedValue = value;
    } else {
      updatedValue = "";
    }
    const { colorPrice, shippingPrice } = this.state;
    // if (name === "copies") {
    //   // Ensure that the value doesn't start with 0 or - and replace the initial 1 if it's there
    //   if (Number(value) < 0) {
    //     updatedValue = "";
    //   } else if (value.startsWith("0")) {
    //     updatedValue = value.replace(/^0/, ""); // Remove the initial '0'
    //   }
    // }
    this.setState({
      selectedAdditionalOrderData: {
        ...this.state.selectedAdditionalOrderData,
        [name]: updatedValue,
      },
      subtotal: value * (colorPrice + shippingPrice),
    });
    // this.updateSubtotal()
  };
  onInsert = async (event) => {
    event.preventDefault();
    try {
      const payload = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        address: this.state.address,
        address2: this.state.address2,
        city: this.state.city,
        state: this.state.state,
        postcode: this.state.postcode,
        shipping_id: this.state.selectedOption,
        print_color_id: this.state.print_color_id,
        subtotal: this.state.subtotal,
      };
      const response = await this.props.addOrderBook(payload);
      toast.success(response.message, { autoClose: 10000 });
      window.open("/my-stories/order-books/", "_self");
    } catch (err) {
      toast.error(err.response?.data?.message, { autoClose: 10000 });
      console.log(err);
    }
  };

  onOrderBookDeleteClick = async (e) => {
    e.preventDefault();
    try {
      let OrderId = e.target.getAttribute("data-test-id");
      // console.log(OrderId);
      const response = await this.props.deleteOrderBook({ id: OrderId });
      toast.success(response.message, { autoClose: 10000 });
      window.open("/my-stories/order-books/", "_self");
    } catch (err) {
      console.log(err);
    }
  };

  onCancelClick = () => {
    window.open("/my-stories/order-books/", "_self");
  };

  handleInputOrderBook = async (e) => {
    try {
      const { id, value } = e.target;
      this.setState((prevState) => ({
        selectedOrderBookData: {
          ...prevState.selectedOrderBookData,
          [id]: value,
        },
      }));
    } catch (err) {
      console.log(err);
    }
  };

  onUpdateOrderBook = async (e) => {
    e.preventDefault();
    try {
      const { selectedOrderBookData } = this.state;
      // console.log("selectedOrderBookData==",selectedOrderBookData);
      let updateResponse = await this.props.updateOrderBook({
        id: selectedOrderBookData.id,
        first_name: selectedOrderBookData.first_name,
        last_name: selectedOrderBookData.last_name,
        address: selectedOrderBookData.address,
        address2: selectedOrderBookData.address2,
        city: selectedOrderBookData.city,
        state: selectedOrderBookData.state,
        postcode: selectedOrderBookData.postcode,
        selectedOption: selectedOrderBookData.shipping_Name,
        print_color_id: selectedOrderBookData.print_color_id,
        subtotal: selectedOrderBookData.subtotal,
      });
      // console.log("selectedOrderBookData==",updateResponse);

      window.open("/my-stories/order-books/", "_self");
      toast.success(updateResponse.message, { autoClose: 10000 });
      // window.open("/question-category/", "_self");
    } catch (err) {
      toast.error(err.response?.data?.message, { autoClose: 10000 });
      console.log(err);
    }
  };

  combinedClickHandlerOrderBook = async (e) => {
    e.preventDefault();
    const OrderId = e.target.getAttribute("data-test-id");
    // console.log("additionalOrderId==>>>",OrderId);
    try {
      // Fetch the data for the specific item from the server
      const response = await this.props.fetchOrderBookById({
        orderBookId: OrderId,
      });
      // console.log("response===>>>=====>",response);
      const OrderBookData = response.payload;
      // Set the fetched data to the component state
      this.setState({
        selectedOrderBookData: OrderBookData,
        first_name: OrderBookData.first_name,
        last_name: OrderBookData.last_name,
        address: OrderBookData.address,
        address2: OrderBookData.address2,
        city: OrderBookData.city,
        state: OrderBookData.state,
        postcode: OrderBookData.postcode,
        selectedOption: OrderBookData.shipping_name,
        // shippingName: additionalOrderData.shipping_name,
        print_color_id: OrderBookData.print_color_id,
        subtotal: OrderBookData.subtotal,
        modalOrderBookOpenEdit: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  onUpdate = async (e) => {
    e.preventDefault();
    try {
      const { selectedAdditionalOrderData } = this.state;
      // console.log("selectedAdditionalOrderData==",selectedAdditionalOrderData);
      let updateResponse = await this.props.updateAdditionalOrderBook({
        id: selectedAdditionalOrderData.id,
        copies: selectedAdditionalOrderData.copies,
        first_name: selectedAdditionalOrderData.first_name,
        last_name: selectedAdditionalOrderData.last_name,
        address: selectedAdditionalOrderData.address,
        address2: selectedAdditionalOrderData.address2,
        city: selectedAdditionalOrderData.city,
        state: selectedAdditionalOrderData.state,
        postcode: selectedAdditionalOrderData.postcode,
        shipping_Name: selectedAdditionalOrderData.selectedOption,
        print_color_id: selectedAdditionalOrderData.print_color_id,
        subtotal: selectedAdditionalOrderData.subtotal,
      });
      // console.log("selectedAdditionalOrderData==",updateResponse);

      window.open("/my-stories/order-books/", "_self");
      toast.success(updateResponse.message, { autoClose: 10000 });
      // window.open("/question-category/", "_self");
    } catch (err) {
      toast.error(err.updateResponse?.data?.message, { autoClose: 10000 });
      console.log(err);
    }
  };

  handleCheckboxChange = (e) => {
    this.setState({ showInputField: e.target.checked });
  };

  handleShowBook = () => {
    this.setState((prevState) => ({
      modalBookOpen: !prevState.modalBookOpen,
    }));
  };

  handleShowAdditionalBook = () => {
    this.setState((prevState) => ({
      modalAdditionalBookOpen: !prevState.modalAdditionalBookOpen,
    }));
  };

  handleShowAdditionalBookEdit = (e) => {
  
    this.setState((prevState) => ({
      modalAdditionalBookOpenEdit: !prevState.modalAdditionalBookOpenEdit,
    }));
    if(this.state.modalAdditionalBookOpenEdit){
    this.fetchAdditionalBooks(e);

    }

  };

  handleShowOrderBookEdit = () => {
    this.setState((prevState) => ({
      modalOrderBookOpenEdit: !prevState.modalOrderBookOpenEdit,
    }));
  };

  handleSelect = (option) => {
    let print_color_id = null;
    // let colorPrice = 0;
    if (option === "colour") {
      print_color_id = "2"; // ID for Colour inner pages
      // colorPrice = 50;
    } else if (option === "bw") {
      print_color_id = "1"; // ID for Black and white inner pages
      // colorPrice = 30;
    }

    this.setState({
      selected: option,
      print_color_id: print_color_id, // update print_color_id based on selection
    });
  };

  handleColorSelect = (option) => {
    // let print_color_id = null;
    let colorPrice = 0;
    if (option === 2) {
      // print_color_id = "2"; // ID for Colour inner pages
      colorPrice = 50;
    } else if (option === 1) {
      // print_color_id = "1"; // ID for Black and white inner pages
      colorPrice = 30;
    }
    const { copies, shippingPrice } = this.state;
    this.setState({
      // selected: option,
      print_color_id: option, // update print_color_id based on selection
      colorPrice: colorPrice, // update color price based on selection
      subtotal: copies * (colorPrice + shippingPrice),
    });
    // this.updateSubtotal()
  };

  updateSubtotal = () => {
    const { copies, colorPrice, shippingPrice } = this.state;
    const subtotal = copies * (colorPrice + shippingPrice);
    this.setState({ subtotal });
  };

  handleOptionChange = (event) => {
    const shippingId = event.target.value;
    // console.log("shippingId", shippingId);
    let shippingPrice = 0;

    // Set shipping price based on selected option
    switch (shippingId) {
      case "1":
        shippingPrice = 7;
        break;
      case "2":
        shippingPrice = 7;
        break;
      case "3":
        shippingPrice = 9;
        break;
      case "4":
        shippingPrice = 12;
        break;
      case "5":
        shippingPrice = 6;
        break;
      default:
        shippingPrice = 0;
    }
    const { copies, colorPrice } = this.state;

    this.setState({
      selectedOption: shippingId,
      shippingPrice: shippingPrice,
      subtotal: copies * (colorPrice + shippingPrice),
    });
    // this.updateSubtotal()
  };

  render() {
    const {
      id,
      isDeleteConfirm,
      isCancelConfirm,
      showInputField,
      modalBookOpen,
      modalAdditionalBookOpen,
      selected,
      subtotal,
      additionalBooks,
      orderBooks,
      modalAdditionalBookOpenEdit,
      selectedAdditionalOrderData,
      selectedOrderBookData,
      selectedStoryData,
      showPayment, 
      print_color_id,
    } = this.state;
    // console.log("isDeleteConfirm===>>", isDeleteConfirm);
    const theadStyle = {
      background: "#f2f2f2",
      border: "none",
      fontSize: "13px",
      fontWeight: "700",
      fontFamily: "Fira Sans",
    };
    const tdStyle = {
      background: "#ffffff",
      border: "none",
      fontSize: "13px",
      fontWeight: "300",
      fontFamily: "Fira Sans",
    };

    return (
      <Fragment className="">
        <GoogleFontLoader
          fonts={[
            {
              font: "Martel",
              weights: [300, 700],
            },
            {
              font: "Fira Sans",
              weights: [300, 700, 400, 600],
            },
          ]}
        />
        <Container fluid={true}>
          <CardBody>
            <Form>
              <Row>
                <h2
                  className="mt-5"
                  style={{
                    textAlign: "center",
                    font: "normal normal 300 2.2rem/1.4 Martel",
                    color: "#000",
                  }}
                >
                  Order Books
                </h2>
                <div style={{ textAlign: "center" }}>
                  <span
                    style={{
                      borderBottom: "1px solid #FEC1EB",
                      height: "1px",
                      width: "102px",
                      display: "inline-block",
                    }}
                  ></span>
                </div>
              </Row>
              <Row
                className="mt-3"
                style={{ background: "#EEE", padding: "3%" }}
              >
                <Col md="1"></Col>
                <Col md="2">
                  <div
                    className=""
                    style={{
                      background: "#7c8f77",
                      height: "232px",
                      width: "160px",
                      boxShadow: "-4px 5px 12px 0px #bababa",
                      borderRadius: "2px",
                      margin: "0 auto 15px auto",
                    }}
                  >
                    <div style={{ padding: "20% 10% 20% 13%" }}>
                      <img src={selectedStoryData.image} width="120" height="120" alt="story" />
                    </div>
                    <div style={{ fontSize: ".6rem", textAlign: "center" }}>
                      {/* Your Story: {this.props.loggedInUserName}  */}
                      {selectedStoryData.title}
                    </div>
                  </div>
                </Col>
                <Col md="8">
                  {/* <div style={{ fontSize: "0.95rem", fontWeight: "700", fontFamily: "Fira Sans" }}>Included prints</div> */}
                  {/* <div className='mt-3' style={{ clear: "both", fontSize: "15px", fontWeight: "300", fontFamily: "Fira Sans", color: "#404040", lineHeight: "inherit" }}>1 book(s) included in your subscription. It will be printed with a black and white interior but the book cover will always be printed in colour. You can upgrade your book to a colour interior for an additional cost.</div> */}

                  {/* {orderBooks.length > 0 ? (
                     <Table ref="tbl" striped hover responsive bordered id="data-table-zero"> */}

                  {/* <thead > */}
                  {/* <tr style={{border: "1px solid #ccc"}} > */}
                  {/* <th style={theadStyle}>How many copies?</th> */}
                  {/* <th style={theadStyle}>Delivery address</th>         */}
                  {/* <th style={theadStyle}>Shipping</th>
                         <th style={theadStyle}>Sub total</th> */}
                  {/* <th style={theadStyle}></th> */}
                  {/* </tr>
                     </thead>
                     <tbody>
                       {orderBooks.map((data, i) =>
                       <tr key={i}> 
                      
                       <td style={tdStyle}>{data.address}<br />{data.address2}<br />
                         {data.city},&nbsp;
                         {data.state}&nbsp;
                         {data.postcode}</td>  
                        */}
                  {/* <td style={{background: "#ffffff", border: "none", fontSize: "13px", fontWeight: "400",  fontFamily: "Fira Sans",}}> */}
                  {/* <a herf='#' type='button'data-test-id={data.id}onClick={this.combinedClickHandlerOrderBook} style={{textDecoration:"underline"}}>Edit</a>&nbsp; */}
                  {/* <a herf='#' data-test-id={data.id}onClick={this.onOrderBookDeleteClick} style={{ color:"#c00",textDecoration:"underline"}}>Delete</a> */}
                  {/* </td>
                       </tr>      
                        )} 
                     </tbody>
                   </Table>
                 
                  ) : (
                  <>  */}
                  {/* <div className='mt-3' style={{ background: "#f2f2f2", border: "1px solid #ccc", fontSize: "13px", fontWeight: "700", padding: "15px", fontFamily: "Fira Sans" }}>Delivery Address</div>
                  <div style={{ background: "#fff", border: "1px solid #ccc", padding: "15px", fontSize: "14px", textAlign: "center", fontFamily: "Fira Sans", fontWeight: "300" }}>There are no books.</div>
                  </>
                  )} */}
                  {/* <Modal isOpen={this.state.modalOrderBookOpenEdit} toggle={() => this.handleShowOrderBookEdit()} style={{ maxWidth: "900px" }}>
                    <Form onSubmit={this.onUpdateOrderBook}>
                      <ModalHeader style={{ background: "#8A1A75", color: "#fff", fontSize: "1.2rem", fontWeight: "300", fontFamily: "Fira Sans" }}
                        close={<FaTimes className="close" onClick={this.handleShowOrderBookEdit} style={{ cursor: 'pointer' }} />}>Add Additional Book</ModalHeader>
                      <ModalBody>
                        <Row style={{ padding: "2%" }}>
                          <div style={{ fontSize: "14px", fontWeight: "700", fontFamily: "Fira Sans" }}>Would you like the inside pages of your book to be printed in colour?</div>
                          <div style={{ float: "left", display: "flex", padding: "1% 5% 2% 6%" }}>
                            <div onClick={() => this.handleColorSelect('bw')} style={{
                              border: `2px solid ${selected === 'bw' ? '#8A1A75' : 'grey'}`,
                              borderRadius: "5px", padding: "2%", width: "45%", marginRight: "2%", position: 'relative', cursor: 'pointer'
                            }}>
                              <div style={{ fontSize: "1.2rem", fontWeight: "600", fontFamily: "Fira Sans" }}>Black and white inner pages</div>
                              <div style={{ fontWeight: "300", fontFamily: "Fira Sans" }}>Photos on inner pages will be printed in black and white</div>
                              <div className='mt-3' style={{ fontWeight: "700", fontFamily: "Fira Sans" }}>£30.00</div>
                              {selected === 'bw' && (
                                <div style={{
                                  position: 'absolute', top: '-10px', right: '-10px', backgroundColor: '#8A1A75',
                                  color: '#fff', padding: '2px 6px', borderRadius: '5px', fontSize: '0.8rem'
                                }}>Selected</div>
                              )}
                            </div>
                            <div onClick={() => this.handleColorSelect('colour')}
                              style={{
                                border: `2px solid ${selected === 'colour' ? '#8A1A75' : 'grey'}`, borderRadius: "5px",
                                padding: "2%", width: "45%", position: 'relative', cursor: 'pointer'
                              }}>
                              <div style={{ fontSize: "1.2rem", fontWeight: "600", fontFamily: "Fira Sans" }}>Colour inner pages</div>
                              <div style={{ fontWeight: "300", fontFamily: "Fira Sans" }}>Photos on inner pages will be printed in full colour</div>
                              <div className='mt-3' style={{ fontWeight: "700", fontFamily: "Fira Sans" }}>+£50.00 per book</div>
                              {selected === 'colour' && (
                                <div style={{
                                  position: 'absolute', top: '-10px', right: '-10px', backgroundColor: '#8A1A75',
                                  color: '#fff', padding: '2px 6px', borderRadius: '5px', fontSize: '0.8rem'
                                }}>Selected</div>
                              )}
                            </div>
                          </div>
                          <div className='mt-3' style={{ fontSize: "14px", fontWeight: "700", fontFamily: "Fira Sans" }}>How many copies?<span style={{ fontStyle: "italic", fontSize: "12px", fontWeight: "400", fontFamily: "Fira Sans", color: "#c02b0a" }}>(Required)</span></div>
                          <div className='mt-3' style={{ fontSize: "14px", fontWeight: "700", fontFamily: "Fira Sans" }}>Name</div>
                          <Col md='6' className='mt-3'>
                            <label style={{ fontWeight: "300", fontFamily: "Fira Sans" }}>First name</label>
                            <Input type="text" style={{ border: "1px solid #808080" }} id='name' name='name'  value={selectedOrderBookData.name || ''} onChange={this.handleInput}/>
                          </Col>
                          <Col md='6' className='mt-3'>
                            <label style={{ fontWeight: "300", fontFamily: "Fira Sans" }}>Last name</label>
                            <Input type="text" style={{ border: "1px solid #808080" }} id='last_name' name='last_name'  value={selectedOrderBookData.last_name || ''} onChange={this.handleInput}/>
                          </Col>
                          <div className='mt-3' style={{ fontSize: "14px", fontWeight: "700", fontFamily: "Fira Sans" }}>Delivery address</div>
                          <Col md='12' className='mt-3'>
                            <label style={{ fontWeight: "300", fontFamily: "Fira Sans" }}>Street Address</label>
                            <Input type="text" style={{ border: "1px solid #808080" }} id='address' name='address'  value={selectedOrderBookData.address || ''} onChange={this.handleInput}/>
                          </Col>
                          <Col md='12' className='mt-3'>
                            <label style={{ fontWeight: "300", fontFamily: "Fira Sans" }}>Address Line 2</label>
                            <Input type="text" style={{ border: "1px solid #808080" }} id='address2' name='address2'  value={selectedOrderBookData.address2 || ''}  onChange={this.handleInput}/>
                          </Col>
                          <Col md='6' className='mt-3'>
                            <label style={{ fontWeight: "300", fontFamily: "Fira Sans" }}>City</label>
                            <Input type="text" style={{ border: "1px solid #808080" }} id='city' name='city'  value={selectedOrderBookData.city || ''} onChange={this.handleInput}/>
                          </Col>
                          <Col md='6' className='mt-3'>
                            <label style={{ fontWeight: "300", fontFamily: "Fira Sans" }}>State / Province / Region</label>
                            <Input type="text" style={{ border: "1px solid #808080" }} id='state' name='state'  value={selectedOrderBookData.state || ''} onChange={this.handleInput}/>
                          </Col>
                          <Col md='6' className='mt-3'>
                            <label style={{ fontWeight: "300", fontFamily: "Fira Sans" }}>Postcode / ZIP</label>
                            <Input type="text" style={{ border: "1px solid #808080" }} id='postcode' name='postcode' value={selectedOrderBookData.postcode || ''}  onChange={this.handleInput}/>
                          </Col>
                          <div className='mt-3' style={{ fontSize: "14px", fontWeight: "700", fontFamily: "Fira Sans" }}>Shipping<span style={{ fontStyle: "italic", fontSize: "12px", fontWeight: "400", fontFamily: "Fira Sans", color: "#c02b0a" }}>(Required)</span></div>
                          <label className='mt-3' style={{ fontWeight: "300", fontFamily: "Fira Sans" }}>
                            <input
                              type="radio"
                              value='1'
                              checked={this.state.selectedOption === "1"}
                              onChange={this.handleOptionChange}
                            />&nbsp;&nbsp;&nbsp;&nbsp;UK and Europe
                          </label>
                          <br />
                          <label style={{ fontWeight: "300", fontFamily: "Fira Sans" }}>
                            <input
                              type="radio"
                              value="2"
                              checked={this.state.selectedOption === "2"}
                              onChange={this.handleOptionChange}
                            />&nbsp;&nbsp;&nbsp;&nbsp;USA
                          </label>
                          <br />
                          <label style={{ fontWeight: "300", fontFamily: "Fira Sans" }}>
                            <input
                              type="radio"
                              value="3"
                              checked={this.state.selectedOption === "3"}
                              onChange={this.handleOptionChange}
                            />&nbsp;&nbsp;&nbsp;&nbsp;Canada
                          </label>
                          <br />
                          <label style={{ fontWeight: "300", fontFamily: "Fira Sans" }}>
                            <input
                              type="radio"
                              value="4"
                              checked={this.state.selectedOption === "4"}
                              onChange={this.handleOptionChange}
                            />&nbsp;&nbsp;&nbsp;&nbsp;Israel
                          </label>
                          <br />
                          <label style={{ fontWeight: "300", fontFamily: "Fira Sans" }}>
                            <input
                              type="radio"
                              value="5"
                              checked={this.state.selectedOption === "5"}
                              onChange={this.handleOptionChange}
                            />&nbsp;&nbsp;&nbsp;&nbsp;Dubai
                          </label>
                          <div className='mt-3' style={{ fontSize: "14px", fontWeight: "700", fontFamily: "Fira Sans" }}>Additional print(s) sub-total</div>
                          <div style={{ textAlign: "right" }}>
                            <span style={{ fontWeight: "300", fontFamily: "Fira Sans" }}>Price: </span>
                            <span style={{ color: "#8A1A75", fontWeight: "600", fontFamily: "Fira Sans" }}>£{subtotal}</span>
                          </div>
                        </Row>
                      </ModalBody>
                      <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: '100%', textAlign: 'center', fontWeight: "700", fontFamily: "Fira Sans" }}>
                          <Btn attrBtn={{type: "reset", color: "primary"}}>Delete</Btn>&nbsp;&nbsp;
                          <Btn attrBtn={{ type: "reset", color: "primary" }}>Cancel</Btn>&nbsp;&nbsp;
                          <Btn attrBtn={{ color: "primary", type: "submit" }}>Add Additional Book</Btn>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal> */}

                  {/*                  
                  <div className='mt-2'>
                    <button type='button' className='white-button' onClick={this.handleShowBook}>ADD BOOK</button>
                  </div> */}
                  <Modal
                    isOpen={modalBookOpen}
                    toggle={() => this.handleShowBook()}
                    style={{ maxWidth: "900px" }}
                  >
                    <Form onSubmit={this.onInsert}>
                      <ModalHeader
                        style={{
                          background: "#8A1A75",
                          color: "#fff",
                          fontSize: "1.2rem",
                          fontWeight: "300",
                          fontFamily: "Fira Sans",
                        }}
                        close={
                          <FaTimes
                            className="close"
                            onClick={this.handleShowBook}
                            style={{ cursor: "pointer" }}
                          />
                        }
                      >
                        Add Book
                      </ModalHeader>
                      <ModalBody>
                        <Row style={{ padding: "2%" }}>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            Would you like the inside pages of your book to be
                            printed in colour?
                          </div>
                          <div
                            style={{
                              float: "left",
                              display: "flex",
                              padding: "1% 5% 2% 6%",
                            }}
                          >
                            <div
                              onClick={() => this.handleSelect("colour")}
                              style={{
                                border: `2px solid ${
                                  selected === "colour" ? "#8A1A75" : "grey"
                                }`,
                                borderRadius: "5px",
                                padding: "2%",
                                width: "45%",
                                marginRight: "2%",
                                position: "relative",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "1.2rem",
                                  fontWeight: "600",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                Colour inner pages
                              </div>
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                Photos on inner pages will be printed in full
                                colour
                              </div>
                              <div
                                className="mt-3"
                                style={{
                                  fontWeight: "700",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                Free
                              </div>
                              {selected === "colour" && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "-10px",
                                    right: "-10px",
                                    backgroundColor: "#8A1A75",
                                    color: "#fff",
                                    padding: "2px 6px",
                                    borderRadius: "5px",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  Selected
                                </div>
                              )}
                            </div>
                            <div
                              onClick={() => this.handleSelect("bw")}
                              style={{
                                border: `2px solid ${
                                  selected === "bw" ? "#8A1A75" : "grey"
                                }`,
                                borderRadius: "5px",
                                padding: "2%",
                                width: "45%",
                                position: "relative",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "1.2rem",
                                  fontWeight: "600",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                Black and white inner pages
                              </div>
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                Photos on inner pages will be printed in black
                                and white
                              </div>
                              <div
                                className="mt-3"
                                style={{
                                  fontWeight: "700",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                Free
                              </div>
                              {selected === "bw" && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "-10px",
                                    right: "-10px",
                                    backgroundColor: "#8A1A75",
                                    color: "#fff",
                                    padding: "2px 6px",
                                    borderRadius: "5px",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  Selected
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            Name
                          </div>
                          <Col md="6" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              First
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="first"
                              name="first"
                              onChange={(e) =>
                                this.setState({ first_name: e.target.value })
                              }
                            />
                          </Col>
                          <Col md="6" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              Last
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="last"
                              name="last"
                              onChange={(e) =>
                                this.setState({ last_name: e.target.value })
                              }
                            />
                          </Col>
                          <div
                            className="mt-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            Delivery address
                          </div>
                          <Col md="12" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              Street Address
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="address"
                              name="address"
                              onChange={(e) =>
                                this.setState({ address: e.target.value })
                              }
                            />
                          </Col>
                          <Col md="12" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              Address Line 2
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="address2"
                              name="address2"
                              onChange={(e) =>
                                this.setState({ address2: e.target.value })
                              }
                            />
                          </Col>
                          <Col md="6" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              City
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="city"
                              name="city"
                              onChange={(e) =>
                                this.setState({ city: e.target.value })
                              }
                            />
                          </Col>
                          <Col md="6" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              State / Province / Region
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="state"
                              name="state"
                              onChange={(e) =>
                                this.setState({ state: e.target.value })
                              }
                            />
                          </Col>
                          <Col md="6" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              Postcode / ZIP
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="code"
                              name="code"
                              onChange={(e) =>
                                this.setState({ postcode: e.target.value })
                              }
                            />
                          </Col>
                          <div
                            className="mt-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            Shipping
                            <span
                              style={{
                                fontStyle: "italic",
                                fontSize: "12px",
                                fontWeight: "400",
                                fontFamily: "Fira Sans",
                                color: "#c02b0a",
                              }}
                            >
                              (Required)
                            </span>
                          </div>
                          <label
                            className="mt-3"
                            style={{
                              fontWeight: "300",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            <input
                              type="radio"
                              value="1"
                              checked={this.state.selectedOption === "1"}
                              onChange={this.handleOptionChange}
                              id="shipping_price"
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;UK and Europe
                          </label>
                          <br />
                          <label
                            style={{
                              fontWeight: "300",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            <input
                              type="radio"
                              value="2"
                              checked={this.state.selectedOption === "2"}
                              onChange={this.handleOptionChange}
                              id="shipping_price"
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;USA
                          </label>
                          <br />
                          <label
                            style={{
                              fontWeight: "300",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            <input
                              type="radio"
                              value="3"
                              checked={this.state.selectedOption === "3"}
                              onChange={this.handleOptionChange}
                              id="shipping_price"
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;Canada
                          </label>
                          <br />
                          <label
                            style={{
                              fontWeight: "300",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            <input
                              type="radio"
                              value="4"
                              id="shipping_price"
                              checked={this.state.selectedOption === "4"}
                              onChange={this.handleOptionChange}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;Israel
                          </label>
                          <br />
                          <label
                            style={{
                              fontWeight: "300",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            <input
                              type="radio"
                              value="5"
                              id="shipping_price"
                              checked={this.state.selectedOption === "5"}
                              onChange={this.handleOptionChange}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;Dubai
                          </label>
                          <div
                            className="mt-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            Subtotal
                          </div>
                          <div
                            style={{
                              fontSize: "15px",
                              fontWeight: "700",
                              color: "#8A1A75",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            £{subtotal}
                          </div>
                        </Row>
                      </ModalBody>
                      <ModalFooter
                        style={{
                          position: "sticky",
                          zIndex: "999",
                          bottom: "0",
                          background: "#fff",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            textAlign: "right",
                            fontWeight: "700",
                            fontFamily: "Fira Sans",
                          }}
                        >
                          <Btn
                            attrBtn={{
                              color: "primary",
                              background: "#450d3a",
                              type: "reset",
                            }}
                          >
                            Cancel
                          </Btn>
                          &nbsp;&nbsp;
                          <Btn attrBtn={{ color: "primary", type: "submit" }}>
                            Add Book
                          </Btn>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <div
                    className="mt-3"
                    style={{
                      fontSize: "0.95rem",
                      fontWeight: "700",
                      fontFamily: "Fira Sans",
                    }}
                  >
                    Order extra copies
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      clear: "both",
                      fontSize: "15px",
                      fontWeight: "300",
                      fontFamily: "Fira Sans",
                      color: "#404040",
                      lineHeight: "inherit",
                    }}
                  >
                    Want more copies of this book? Please add additional books
                    below and where you would like them delivered to.
                  </div>

                  {additionalBooks.length > 0 ? (
                    <Table
                      ref="tbl"
                      striped
                      hover
                      responsive
                      bordered
                      id="data-table-zero"
                    >
                      <thead>
                        <tr style={{ border: "1px solid #ccc" }}>
                          <th style={theadStyle}>How many copies?</th>
                          <th style={theadStyle}>Delivery address</th>
                          <th style={theadStyle}>Shipping</th>
                          <th style={theadStyle}>Sub total</th>
                          <th style={theadStyle}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {additionalBooks.map((data, i) => (
                          <tr key={i}>
                            <td style={tdStyle}>{data.copies}</td>
                            <td style={tdStyle}>
                              {data.address}
                              <br />
                              {data.address2}
                              <br />
                              {data.city},&nbsp;
                              {data.state}&nbsp;
                              {data.postcode}
                            </td>
                            <td style={tdStyle}>{data.shipping_price}</td>
                            <td style={tdStyle}>£{data.subtotal}</td>
                            <td
                              style={{
                                background: "#ffffff",
                                border: "none",
                                fontSize: "13px",
                                fontWeight: "400",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              <a
                                herf="#"
                                type="button"
                                data-test-id={data.id}
                                onClick={this.combinedClickHandler}
                                style={{ textDecoration: "underline" }}
                              >
                                Edit
                              </a>
                              &nbsp;
                              <a
                                herf="#"
                                id={data.id}
                                onClick={this.onDeleteClick}
                                style={{
                                  color: "#c00",
                                  textDecoration: "underline",
                                }}
                              >
                                Delete
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <>
                      {" "}
                      <div
                        className="mt-3"
                        style={{
                          background: "#f2f2f2",
                          border: "1px solid #ccc",
                          fontSize: "13px",
                          fontWeight: "700",
                          padding: "15px",
                          fontFamily: "Fira Sans",
                        }}
                      >
                        <span style={{ marginRight: "12%" }}>
                          How many copies?
                        </span>
                        <span style={{ marginRight: "12%" }}>
                          Delivery address
                        </span>
                        <span style={{ marginRight: "12%" }}>Shipping</span>
                        <span>Sub total</span>
                      </div>
                      <div
                        style={{
                          background: "#fff",
                          border: "1px solid #ccc",
                          padding: "15px",
                          fontSize: "14px",
                          textAlign: "center",
                          fontFamily: "Fira Sans",
                          fontWeight: "300",
                        }}
                      >
                        <spna>There are no additional books.</spna>
                      </div>
                    </>
                  )}

                  
                  <AddAdditionalOrderBook isEdit={true} isvisible={modalAdditionalBookOpenEdit} toggle={(e) => this.handleShowAdditionalBookEdit(e)} additionalOrderData={this.state.selectedAdditionalOrderData}  onDeleteClick={this.onDeleteConfirm}/>

                  {/* <Modal
                    isOpen={this.state.modalAdditionalBookOpenEdit}
                    toggle={() => this.handleShowAdditionalBookEdit()}
                    style={{ maxWidth: "900px" }}
                  >
                    <Form onSubmit={this.onUpdate}>
                      <ModalHeader
                        style={{
                          background: "#8A1A75",
                          color: "#fff",
                          fontSize: "1.2rem",
                          fontWeight: "300",
                          fontFamily: "Fira Sans",
                        }}
                        close={
                          <FaTimes
                            className="close"
                            onClick={this.handleShowAdditionalBookEdit}
                            style={{ cursor: "pointer" }}
                          />
                        }
                      >
                        Add Additional Book
                      </ModalHeader>
                      <ModalBody>
                        <Row style={{ padding: "2%" }}>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            Would you like the inside pages of your book to be
                            printed in colour?
                          </div>
                          <div
                            style={{
                              float: "left",
                              display: "flex",
                              padding: "1% 5% 2% 6%",
                            }}
                          >
                            <div
                              onClick={() => this.handleColorSelect(1)}
                              style={{
                                border: `2px solid ${
                                  print_color_id === 1 ? "#8A1A75" : "grey"
                                }`,
                                borderRadius: "5px",
                                padding: "2%",
                                width: "45%",
                                marginRight: "2%",
                                position: "relative",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "1.2rem",
                                  fontWeight: "600",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                Black and white inner pages
                              </div>
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                Photos on inner pages will be printed in black
                                and white
                              </div>
                              <div
                                className="mt-3"
                                style={{
                                  fontWeight: "700",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                £30.00
                              </div>
                              {print_color_id === 1 && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "-10px",
                                    right: "-10px",
                                    backgroundColor: "#8A1A75",
                                    color: "#fff",
                                    padding: "2px 6px",
                                    borderRadius: "5px",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  Selected
                                </div>
                              )}
                            </div>
                            <div
                              onClick={() => this.handleColorSelect(2)}
                              style={{
                                border: `2px solid ${
                                  print_color_id === 2 ? "#8A1A75" : "grey"
                                }`,
                                borderRadius: "5px",
                                padding: "2%",
                                width: "45%",
                                position: "relative",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "1.2rem",
                                  fontWeight: "600",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                Colour inner pages
                              </div>
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                Photos on inner pages will be printed in full
                                colour
                              </div>
                              <div
                                className="mt-3"
                                style={{
                                  fontWeight: "700",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                +£50.00 per book
                              </div>
                              {print_color_id === 2 && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "-10px",
                                    right: "-10px",
                                    backgroundColor: "#8A1A75",
                                    color: "#fff",
                                    padding: "2px 6px",
                                    borderRadius: "5px",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  Selected
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className="mt-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            How many copies?
                            <span
                              style={{
                                fontStyle: "italic",
                                fontSize: "12px",
                                fontWeight: "400",
                                fontFamily: "Fira Sans",
                                color: "#c02b0a",
                              }}
                            >
                              (Required)
                            </span>
                          </div>
                          <Col md="6" className="mt-3">
                            <Input
                              type="number"
                              style={{ border: "1px solid #808080" }}
                              id="copies"
                              name="copies"
                              value={selectedAdditionalOrderData.copies || ""}
                              onChange={this.handleInput}
                            />
                          </Col>
                          <div
                            className="mt-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            Name
                          </div>
                          <Col md="6" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              First name
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="name"
                              name="name"
                              value={selectedAdditionalOrderData.name || ""}
                              onChange={this.handleInput}
                            />
                          </Col>
                          <Col md="6" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              Last name
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="last_name"
                              name="last_name"
                              value={
                                selectedAdditionalOrderData.last_name || ""
                              }
                              onChange={this.handleInput}
                            />
                          </Col>
                          <div
                            className="mt-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            Delivery address
                          </div>
                          <Col md="12" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              Street Address
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="address"
                              name="address"
                              value={selectedAdditionalOrderData.address || ""}
                              onChange={this.handleInput}
                            />
                          </Col>
                          <Col md="12" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              Address Line 2
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="address2"
                              name="address2"
                              value={selectedAdditionalOrderData.address2 || ""}
                              onChange={this.handleInput}
                            />
                          </Col>
                          <Col md="6" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              City
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="city"
                              name="city"
                              value={selectedAdditionalOrderData.city || ""}
                              onChange={this.handleInput}
                            />
                          </Col>
                          <Col md="6" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              State / Province / Region
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="state"
                              name="state"
                              value={selectedAdditionalOrderData.state || ""}
                              onChange={this.handleInput}
                            />
                          </Col>
                          <Col md="6" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              Postcode / ZIP
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="postcode"
                              name="postcode"
                              value={selectedAdditionalOrderData.postcode || ""}
                              onChange={this.handleInput}
                            />
                          </Col>
                          <div
                            className="mt-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            Shipping
                            <span
                              style={{
                                fontStyle: "italic",
                                fontSize: "12px",
                                fontWeight: "400",
                                fontFamily: "Fira Sans",
                                color: "#c02b0a",
                              }}
                            >
                              (Required)
                            </span>
                          </div>
                          <label
                            className="mt-3"
                            style={{
                              fontWeight: "300",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            <input
                              type="radio"
                              value="1"
                              checked={this.state.selectedOption === "1"}
                              onChange={this.handleOptionChange}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;UK and Europe
                          </label>
                          <br />
                          <label
                            style={{
                              fontWeight: "300",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            <input
                              type="radio"
                              value="2"
                              checked={this.state.selectedOption === "2"}
                              onChange={this.handleOptionChange}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;USA
                          </label>
                          <br />
                          <label
                            style={{
                              fontWeight: "300",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            <input
                              type="radio"
                              value="3"
                              checked={this.state.selectedOption === "3"}
                              onChange={this.handleOptionChange}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;Canada
                          </label>
                          <br />
                          <label
                            style={{
                              fontWeight: "300",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            <input
                              type="radio"
                              value="4"
                              checked={this.state.selectedOption === "4"}
                              onChange={this.handleOptionChange}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;Israel
                          </label>
                          <br />
                          <label
                            style={{
                              fontWeight: "300",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            <input
                              type="radio"
                              value="5"
                              checked={this.state.selectedOption === "5"}
                              onChange={this.handleOptionChange}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;Dubai
                          </label>
                          <div
                            className="mt-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            Additional print(s) sub-total
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <span
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              Price:{" "}
                            </span>
                            <span
                              style={{
                                color: "#8A1A75",
                                fontWeight: "600",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              £{subtotal}
                            </span>
                          </div>
                        </Row>
                      </ModalBody>
                      <ModalFooter style={{ display: "flex" }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            fontWeight: "700",
                            fontFamily: "Fira Sans",
                          }}
                        >
                          <Btn
                            attrBtn={{
                              type: "reset",
                              color: "",
                              id: id,
                              onClick: this.onDeleteConfirm,
                              style: {
                                backgroundColor: "#e74c3c",
                                color: "#FFF",
                              },
                            }}
                          >
                            {isDeleteConfirm ? "Are you sure?" : "Delete"}
                          </Btn>

                          <div>
                            <Btn
                              attrBtn={{
                                type: "reset",
                                color: "",
                                onClick: this.onCancelConfirm,
                                style: {
                                  backgroundColor: "#450d3a",
                                  color: "#FFF",
                                },
                              }}
                            >
                              {isCancelConfirm ? "Are you sure?" : "Cancel"}
                            </Btn>
                            <Btn
                              attrBtn={{
                                color: "primary",
                                type: "submit",
                                style: { marginLeft: "20px" },
                              }}
                            >
                              Update Additional Book
                            </Btn>
                          </div>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal> */}

                  <div className="mt-2">
                    <button
                      type="button"
                      className="white-button"
                      onClick={this.handleShowAdditionalBook}
                    >
                      ADD ADDITIONAL BOOK
                    </button>
                  </div>
                  <AddAdditionalOrderBook isvisible={modalAdditionalBookOpen} toggle={() => this.handleShowAdditionalBook()} />

                  {/* <Modal
                    isOpen={modalAdditionalBookOpen}
                    toggle={() => this.handleShowAdditionalBook()}
                    style={{ maxWidth: "900px" }}
                  >
                    <Form onSubmit={this.onInsertAdditional}>
                      <ModalHeader
                        style={{
                          background: "#8A1A75",
                          color: "#fff",
                          fontSize: "1.2rem",
                          fontWeight: "300",
                          fontFamily: "Fira Sans",
                        }}
                        close={
                          <FaTimes
                            className="close"
                            onClick={this.handleShowAdditionalBook}
                            style={{ cursor: "pointer" }}
                          />
                        }
                      >
                        Add Additional Book
                      </ModalHeader>
                      <ModalBody>
                        <Row style={{ padding: "2%" }}>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            Would you like the inside pages of your book to be
                            printed in colour?
                          </div>
                          <div
                            style={{
                              float: "left",
                              display: "flex",
                              padding: "1% 5% 2% 6%",
                            }}
                          >
                            <div
                              onClick={() => this.handleColorSelect("bw")}
                              style={{
                                border: `2px solid ${
                                  selected === "bw" ? "#8A1A75" : "grey"
                                }`,
                                borderRadius: "5px",
                                padding: "2%",
                                width: "45%",
                                marginRight: "2%",
                                position: "relative",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "1.2rem",
                                  fontWeight: "600",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                Black and white inner pages
                              </div>
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                Photos on inner pages will be printed in black
                                and white
                              </div>
                              <div
                                className="mt-3"
                                style={{
                                  fontWeight: "700",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                £30.00
                              </div>
                              {selected === "bw" && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "-10px",
                                    right: "-10px",
                                    backgroundColor: "#8A1A75",
                                    color: "#fff",
                                    padding: "2px 6px",
                                    borderRadius: "5px",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  Selected
                                </div>
                              )}
                            </div>
                            <div
                              onClick={() => this.handleColorSelect("colour")}
                              style={{
                                border: `2px solid ${
                                  selected === "colour" ? "#8A1A75" : "grey"
                                }`,
                                borderRadius: "5px",
                                padding: "2%",
                                width: "45%",
                                position: "relative",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "1.2rem",
                                  fontWeight: "600",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                Colour inner pages
                              </div>
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                Photos on inner pages will be printed in full
                                colour
                              </div>
                              <div
                                className="mt-3"
                                style={{
                                  fontWeight: "700",
                                  fontFamily: "Fira Sans",
                                }}
                              >
                                +£50.00 per book
                              </div>
                              {selected === "colour" && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "-10px",
                                    right: "-10px",
                                    backgroundColor: "#8A1A75",
                                    color: "#fff",
                                    padding: "2px 6px",
                                    borderRadius: "5px",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  Selected
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className="mt-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            How many copies?
                            <span
                              style={{
                                fontStyle: "italic",
                                fontSize: "12px",
                                fontWeight: "400",
                                fontFamily: "Fira Sans",
                                color: "#c02b0a",
                              }}
                            >
                              (Required)
                            </span>
                          </div>
                          <Col md="6" className="mt-3">
                            <Input
                              type="number"
                              style={{ border: "1px solid #808080" }}
                              value={this.state.copies}
                              id="number"
                              name="number"
                              onChange={(e) => {
                                let value = e.target.value;
                                if (value > 0) {
                                  this.setState({ copies: value });
                                } else {
                                  this.setState({ copies: "" });
                                }
                              }}
                            />
                          </Col>
                          <div
                            className="mt-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            Name
                          </div>
                          <Col md="6" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              First name
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="first"
                              name="first"
                              onChange={(e) =>
                                this.setState({ first_name: e.target.value })
                              }
                            />
                          </Col>
                          <Col md="6" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              Last name
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="last"
                              name="last"
                              onChange={(e) =>
                                this.setState({ last_name: e.target.value })
                              }
                            />
                          </Col>
                          <div
                            className="mt-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            Delivery address
                          </div>
                          <Col md="12" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              Street Address
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="address"
                              name="address"
                              onChange={(e) =>
                                this.setState({ address: e.target.value })
                              }
                            />
                          </Col>
                          <Col md="12" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              Address Line 2
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="address2"
                              name="address2"
                              onChange={(e) =>
                                this.setState({ address2: e.target.value })
                              }
                            />
                          </Col>
                          <Col md="6" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              City
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="city"
                              name="city"
                              onChange={(e) =>
                                this.setState({ city: e.target.value })
                              }
                            />
                          </Col>
                          <Col md="6" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              State / Province / Region
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="state"
                              name="state"
                              onChange={(e) =>
                                this.setState({ state: e.target.value })
                              }
                            />
                          </Col>
                          <Col md="6" className="mt-3">
                            <label
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              Postcode / ZIP
                            </label>
                            <Input
                              type="text"
                              style={{ border: "1px solid #808080" }}
                              id="code"
                              name="code"
                              onChange={(e) =>
                                this.setState({ postcode: e.target.value })
                              }
                            />
                          </Col>
                          <div
                            className="mt-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            Shipping
                            <span
                              style={{
                                fontStyle: "italic",
                                fontSize: "12px",
                                fontWeight: "400",
                                fontFamily: "Fira Sans",
                                color: "#c02b0a",
                              }}
                            >
                              (Required)
                            </span>
                          </div>
                          <label
                            className="mt-3"
                            style={{
                              fontWeight: "300",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            <input
                              type="radio"
                              value="1"
                              checked={this.state.selectedOption === "1"}
                              onChange={this.handleOptionChange}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;UK and Europe
                          </label>
                          <br />
                          <label
                            style={{
                              fontWeight: "300",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            <input
                              type="radio"
                              value="2"
                              checked={this.state.selectedOption === "2"}
                              onChange={this.handleOptionChange}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;USA
                          </label>
                          <br />
                          <label
                            style={{
                              fontWeight: "300",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            <input
                              type="radio"
                              value="3"
                              checked={this.state.selectedOption === "3"}
                              onChange={this.handleOptionChange}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;Canada
                          </label>
                          <br />
                          <label
                            style={{
                              fontWeight: "300",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            <input
                              type="radio"
                              value="4"
                              checked={this.state.selectedOption === "4"}
                              onChange={this.handleOptionChange}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;Israel
                          </label>
                          <br />
                          <label
                            style={{
                              fontWeight: "300",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            <input
                              type="radio"
                              value="5"
                              checked={this.state.selectedOption === "5"}
                              onChange={this.handleOptionChange}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;Dubai
                          </label>
                          <div
                            className="mt-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "Fira Sans",
                            }}
                          >
                            Additional print(s) sub-total
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <span
                              style={{
                                fontWeight: "300",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              Price:{" "}
                            </span>
                            <span
                              style={{
                                color: "#8A1A75",
                                fontWeight: "600",
                                fontFamily: "Fira Sans",
                              }}
                            >
                              £{subtotal}
                            </span>
                          </div>
                        </Row>
                      </ModalBody>
                      <ModalFooter
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontWeight: "700",
                            fontFamily: "Fira Sans",
                          }}
                        >
                          <Btn attrBtn={{ color: "primary", type: "reset" }}>
                            Cancel
                          </Btn>
                          &nbsp;&nbsp;
                          <Btn attrBtn={{ color: "primary", type: "submit" }}>
                            Add Additional Book
                          </Btn>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal> */}


                  <div
                    className="mt-5 pb-3"
                    style={{
                      font: "normal normal 300 1.4rem/1.4 Martel",
                      color: "#000",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    Payment
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "0.95rem",
                        fontWeight: "700",
                        fontFamily: "Fira Sans",
                      }}
                    >
                      Included copies
                    </span>
                    <div style={{ textAlign: "right" }}>
                      <span
                        style={{ fontWeight: "300", fontFamily: "Fira Sans" }}
                      >
                        Price:{" "}
                      </span>
                      <span
                        style={{
                          color: "#8A1A75",
                          fontWeight: "700",
                          fontFamily: "Fira Sans",
                        }}
                      >
                        £0.00
                      </span>
                    </div>
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "0.95rem",
                        fontWeight: "700",
                        fontFamily: "Fira Sans",
                      }}
                    >
                      Extra copies
                    </span>
                    <div style={{ textAlign: "right" }}>
                      <span
                        style={{ fontWeight: "300", fontFamily: "Fira Sans" }}
                      >
                        Price:{" "}
                      </span>
                      <span
                        style={{
                          color: "#8A1A75",
                          fontWeight: "700",
                          fontFamily: "Fira Sans",
                        }}
                      >
                        £{this.state.total}.00
                      </span>
                    </div>
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      background: "#fff",
                      border: "1px solid #ccc",
                      fontSize: "14px",
                      fontWeight: "700",
                      fontFamily: "Fira Sans",
                      padding: "10px",
                    }}
                  >
                    <div>Discount codes</div>
                    <input
                      type="text"
                      className="mt-2"
                      style={{ padding: "8px", border: "1px solid #808080" }}
                    />
                    &nbsp;&nbsp;
                    <button type="button" className="white-button">
                      APPLY
                    </button>
                  </div>
                  <div style={{ background: "#8A1A75", padding: "10px" }}>
                    <div
                      style={{
                        color: "#fff",
                        fontSize: "1.20rem",
                        fontWeight: "700",
                        fontFamily: "Fira Sans",
                      }}
                    >
                      Total
                    </div>
                    <div
                      style={{
                        color: "#fec1eb",
                        fontSize: "1.0rem",
                        fontWeight: "400",
                        fontFamily: "Fira Sans",
                      }}
                    >
                      £{this.state.total}.00
                    </div>
                  </div>
                  <div
                    className="mt-5 pb-3"
                    style={{
                      font: "normal normal 300 1.4rem/1.4 Martel",
                      color: "#000",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    Disclaimer
                  </div>
                  <div
                    className="mt-3 pb-4"
                    style={{
                      background: "#fff",
                      borderBottom: "1px solid #ccc",
                      padding: "10px 15px",
                      fontSize: "16px",
                      fontFamily: "Fira Sans",
                      fontWeight: "300",
                    }}
                  >
                    <div>
                      As a reminder, no one on our team reviews your stories.
                      What you see in the preview is what your book will look
                      like when printed.
                    </div>
                    <div className="mt-3">
                      Here are some examples of issues that our automated system
                      might not catch:
                    </div>
                    <div className="mt-3">
                      <ul
                        style={{ listStyleType: "disc", paddingLeft: "20px" }}
                      >
                        <li>Typos, misspellings and grammar mistakes</li>
                        <li>Missing images and low quality images</li>
                        <li>
                          Replies that you do not want to include in the book
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="pb-4"
                    style={{
                      background: "#fff",
                      padding: "10px 15px",
                      fontFamily: "Fira Sans",
                      fontWeight: "300",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          fontSize: "0.95rem",
                          fontWeight: "700",
                          fontFamily: "Fira Sans",
                        }}
                      >
                        I'm OK with this
                      </span>
                      &nbsp;
                      <span
                        style={{
                          fontStyle: "italic",
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#c02b0a",
                        }}
                      >
                        (Required)
                      </span>
                    </div>
                    <div className="mt-2">
                      <Label style={{ fontSize: "15px" }}>
                        <input
                          type="checkbox"
                          onChange={this.handleCheckboxChange}
                        />
                        &nbsp; Please print my book 'as is'
                      </Label>
                    </div>
                  </div>
                  {showInputField === false ? (
                    <></>
                  ) : (
                    <div className="mt-3">
                      <button
                        type="submit"
                        style={{
                          background: "#8A1A75",
                          letterSpacing: "0.15rem",
                          border: "2px solid #8A1A75",
                          fontWeight: "300",
                          color: "#fff",
                          borderRadius: "30px",
                          padding: "0 20px",
                          textTransform: "uppercase",
                          minHeight: "45px",
                        }}
                        onClick={this.handleGoToPayment}
                      >
                        Go to Payment
                      </button>
                      {/* {showPayment && <StripeProvider  />} */}
                    </div>
                  )}
                </Col>
                <Col md="1"></Col>
              </Row>
            </Form>
          </CardBody>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    loggedInUserName: state.auth.session.user.user_name, 
    loggedInUserId: state.auth.session.user.id

  };
};

const mapDispatchToProps = {
  listMyStory: myStoryOperations.listMyStory,
  addOrderBook: myStoryOperations.addOrderBook,
  listOrderBook: myStoryOperations.listOrderBook,
  deleteOrderBook: myStoryOperations.deleteOrderBook,
  fetchOrderBookById: myStoryOperations.fetchOrderBookById,
  updateOrderBook: myStoryOperations.updateOrderBook,
  addAdditionalOrderBook: myStoryOperations.addAdditionalOrderBook,
  listAdditionalOrderBook: myStoryOperations.listAdditionalOrderBook,
  deleteAdditionalOrderBook: myStoryOperations.deleteAdditionalOrderBook,
  fetchAdditionalOrderBookById: myStoryOperations.fetchAdditionalOrderBookById,
  updateAdditionalOrderBook: myStoryOperations.updateAdditionalOrderBook,
  Payment: myStoryOperations.Payment,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderBooks);
