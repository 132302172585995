import React, { Fragment ,createRef } from "react";
import {
  Container,
  Card,
  CardBody,
  Col,
  Form,
  Row,
  Label,
  Input,
} from "reactstrap";
import story from "../../assets/images/datatable/story.png";
import imgDelete from "../../assets/images/datatable/imgDelete.png";
import "../../assets/scss/ToggleSwitch.css";
import Dropzone from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { myStoryOperations } from "../../state/ducks/story";
import { UpdateMyBook } from "../../Constant";
import GoogleFontLoader from "react-google-font-loader";
import "../Button.css";
import "./TemplateSelector.css";
import html2canvas from "html2canvas";
import { jsPDF } from 'jspdf';
import TemplateSelector from "./TemplateSelector";


class EditMyStories extends React.Component {
  constructor(props) {
    super(props);
    this.captureRef = createRef();
    this.state = {
      imageUrl: "",
      isChecked: false,
      selectedStoryData: {},
      selectedStyle: "default",
      selectedColor: "#7c8f77",
      selectedCover: "",
      isFullImage: false,
      isInsertImage: false,
      isNoImage: false,
      activeIndex: 0,
      activeFullIndex: 0,
      activeNoImageIndex: 0,
      activeInsertImageIndex: 0,
      isSpanVisible: false,
      textPosition: "top",
    };
    // Define images array with default values
    this.images = [
      {
        src: "https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/inset-image-sub-title-pink.jpg",
        alt: "Pink",
        backgroundColor: "#ffc9c9",
      },
      {
        src: "https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/inset-image-sub-title-green.jpg",
        alt: "Green",
        backgroundColor: "#597c57",
      },
      {
        src: "https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/inset-image-sub-title-black.jpg",
        alt: "Black",
        backgroundColor: "#0a0a0a",
      },
      {
        src: "https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/inset-image-sub-title-white.jpg",
        alt: "White",
        backgroundColor: "#ffffff",
      },
    ];
    this.fullImages = [
      {
        src: "https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/full-image-no-text.jpg",
        alt: "Full image no text",
        backgroundColor: "#8a1a75",
      },
      {
        src: "https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/full-image.jpg",
        alt: "Full image with text",
        backgroundColor: "#8a1a75",
      },
    ];
    this.noImages = [
      {
        src: "https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/no-image-blue.jpg",
        alt: "Blue",
        backgroundColor: "#2eabad",
      },
      {
        src: "https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/no-image-pink.jpg",
        alt: "Pink",
        backgroundColor: "#ffc9c9",
      },
      {
        src: "https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/no-image-black.jpg",
        alt: "Black",
        backgroundColor: "#0a0a0a",
      },
      {
        src: "https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/no-image-white.jpg",
        alt: "White",
        backgroundColor: "#ffffff",
      },
    ];
    this.insertImages = [
      {
        src: "https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/inset-image-green.jpg",
        alt: "green",
        backgroundColor: "#597c57",
      },
      {
        src: "https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/inset-image-black.jpg",
        alt: "Black",
        backgroundColor: "#0a0a0a",
      },
      {
        src: "https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/inset-image-white.jpg",
        alt: "White",
        backgroundColor: "#ffffff",
      },
      {
        src: "https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/inset-image-pink.jpg",
        alt: "Pink",
        backgroundColor: "#ffc9c9",
      },
    ];
    this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this);
  }

  toggleCheckboxChange() {
    this.setState((prevState) => ({
      isChecked: !prevState.isChecked,
    }));
  }

  handleStyleChange = (style) => {
    this.setState({ selectedStyle: style });
  };

  async componentDidMount() {
    // console.log("capture======>>",this.captureRef.current);
    // console.log('Image src:', this.captureRef.current.querySelector('img').src);
    // console.log("Selected Story Data:", this.state.selectedStoryData);
    if (this.state.imageUrl) {
      URL.revokeObjectURL(this.state.imageUrl);
    }
    const activeIndex = localStorage.getItem('activeIndex');
    const selectedColor = localStorage.getItem('selectedColor');
    const activeFullIndex = localStorage.getItem('activeFullIndex');
    const isFullImage = localStorage.getItem('isFullImage') === 'true';
    const isSpanVisible = localStorage.getItem('isSpanVisible') === 'true';
    const activeNoImageIndex = localStorage.getItem('activeNoImageIndex');
    const isNoImage = localStorage.getItem('isNoImage') === 'true';
    const activeInsertImageIndex = localStorage.getItem('activeInsertImageIndex');
    const isInsertImage = localStorage.getItem('isInsertImage') === 'true';
  

    this.setState({
      activeIndex: activeIndex !== null ? Number(activeIndex) : this.state.activeIndex,
      selectedColor: selectedColor || this.state.selectedColor,
      activeFullIndex: activeFullIndex !== null ? Number(activeFullIndex) : this.state.activeFullIndex,
      isFullImage,
      isSpanVisible,
      activeNoImageIndex: activeNoImageIndex !== null ? Number(activeNoImageIndex) : this.state.activeNoImageIndex,
      isNoImage,
      activeInsertImageIndex: activeInsertImageIndex !== null ? Number(activeInsertImageIndex) : this.state.activeInsertImageIndex,
      isInsertImage,
    });
    const storyId = localStorage.getItem("selectedStoryId");
    // console.log("questionId===>>>>",storyId);
    const response = await this.props.getMyStory({ id: storyId });
    const storyData = response.payload;
    // console.log("storyData===>>>", storyData);
    this.setState({
      selectedStoryData: storyData,
      selectedColor: storyData.cover_color,
      selectedCover: story.cover_page,
      textPosition: story.book_text_position || "top",
      // text: questionData.answer,
      // content: questionData.question,
    });
  }

  handleInput = (e) => {
    const { id, value } = e.target;
    this.setState((prevState) => ({
      selectedStoryData: {
        ...prevState.selectedStoryData,
        [id]: value,
      },
    }));
  };

  // captureImage = () => {
  //   return new Promise((resolve, reject) => {
  //   const { imageUrl, selectedStoryData } = this.state;

  //   const img = new Image();
  //   img.crossOrigin = "anonymous";
  //   img.src = imageUrl
  //     ? URL.createObjectURL(imageUrl)
  //     : selectedStoryData.image || "";

  //   img.onload = async () => {
  //     try {
  //     console.log("Image loaded successfully");
  //     const element = this.captureRef.current;
  //     if (!element) {
  //       console.error("Element not found!");
  //       return;
  //     }
  //     const canvas = await html2canvas(element);
  //     const coverPageUrl = canvas.toDataURL("image/png");

  //     // Proceed with saving the canvas image as before
  //     // this.saveCoverPage(coverPageUrl);
  //     resolve(coverPageUrl);
  //   } catch (error) {
  //     reject(error);
  //   }
  //   };
  //   img.onerror = (error) => {
  //     console.error("Image load error:", error);
  //     reject(error);
  //   };
  // });

  // };

  // captureImage = () => {
  //   return new Promise((resolve, reject) => {
  //     const { selectedStoryData } = this.state;
  
  //     // Find the image element on the page using its ID or class
  //     // const element = document.querySelector('#imageElement'); // Replace with your image element selector
  //     const element = this.captureRef.current;
  //     if (!element) {
  //       console.error("Image element not found! Make sure captureRef is properly set.");
  //       return;
  //     }
  
  //     // Convert the image element to canvas
  //     html2canvas(element)
  //     .then(canvas => {
  //       // Convert canvas to a data URL (base64)
  //       const dataUrl = canvas.toDataURL("image/png");
  
  //       // Save the data URL (base64) to your server or directly to the database
  //       console.log("Captured Data URL:", dataUrl);
  
  //       // If you want to save this URL, resolve it
  //       resolve(dataUrl);
  //     }).catch(error => {
  //       console.error("Error capturing image:", error);
  //       reject(error);
  //     });
  //   });
  // };

  captureImage = () => {
    
    return new Promise((resolve, reject) => {
        const { imageUrl, selectedStoryData } = this.state;

        // Always use selectedStoryData.cover_image_url if available, 
        // else use the new uploaded imageUrl
        const imageSrc = this.state.selectedStoryData.image;
            // ? URL.createObjectURL(this.state.imageUrl)
            // : this.state.selectedStoryData.image || 'http://localhost:8080/images/b5a15767-b3e5-45fc-9f81-2a2ac4f10643.png';

        // console.log("imageSrc======", imageSrc);

        const img = new Image();
        img.crossOrigin = "anonymous";
        // For loading images from the same origin or when CORS handling is not needed
// img.crossOrigin = null;
// For loading images from a different origin with credentials
// img.crossOrigin = "use-credentials";

        img.src = `${imageSrc}?t=${new Date().getTime()}`; //imageSrc;

        img.onload = async () => {
            try {
                console.log("Image loaded successfully");
                const element = this.captureRef.current;
                if (!element) {
                    console.error("Element not found!");
                    return;
                }
                
                     // Clear the canvas before capturing the image
                     const canvas = document.createElement('canvas');
                     const context = canvas.getContext('2d');
                     context.clearRect(0, 0, canvas.width, canvas.height);
                // Wait for the image to be completely loaded and rendered in the DOM
                setTimeout(async () => {
                    const canvas = await html2canvas(element, { useCORS: true });
                    const coverPageUrl = canvas.toDataURL("image/png");

                    resolve(coverPageUrl);
                }, 100); // You might need to adjust this delay based on the rendering speed
            } catch (error) {
                reject(error);
            }
        };

        img.onerror = (error) => {
            console.error("Image load error:", error);
            reject(new Error(`Failed to load image from source: ${img.src}`));
        };
    });
};




//   capturePDF = () => {
//     const element = this.captureRef.current;
//     if (!element) {
//       console.error("Element not found!");
//       return;
//     }
    
//     html2canvas(element, { scale: 2 }).then((canvas) => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF();
//       const imgWidth = 210; // A4 width in mm
//       const pageHeight = 295; // A4 height in mm
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;
//       let heightLeft = imgHeight;

//       pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
//       heightLeft -= pageHeight;

//       while (heightLeft >= 0) {
//         pdf.addPage();
//         pdf.addImage(imgData, 'PNG', 0, -heightLeft, imgWidth, imgHeight);
//         heightLeft -= pageHeight;
//       }

//       // Save PDF to Blob and create URL
//       const pdfBlob = pdf.output('blob');
//       const pdfUrl = URL.createObjectURL(pdfBlob);
// console.log("pdfURl-====",pdfUrl);
//       // Proceed with saving the PDF URL
//       this.saveCoverPage(pdfUrl);
//     });
//   };

  saveCoverPage = async (coverPageUrl,pdfUrl) => {
    const { selectedStoryData, imageUrl, selectedColor, textPosition } =
      this.state;

    let formData = new FormData();
    formData.append("id", selectedStoryData.id);
    formData.append("title", selectedStoryData.title);
    formData.append("subtitle", selectedStoryData.subtitle);
    formData.append("author", selectedStoryData.author);
    formData.append("cover_color", selectedColor);
    formData.append("cover_page", coverPageUrl);
    formData.append("cover_page_pdf_url", pdfUrl); 
    formData.append("book_text_position", textPosition);

    if (imageUrl) {
      formData.append("cover_image_url", imageUrl);
    } else if (selectedStoryData.cover_image_url) {
      formData.append("cover_image_url", selectedStoryData.cover_image_url);
    }

    try {
      let updateResponse = await this.props.updateMyStory(formData);
      toast.success(updateResponse.message, { autoClose: 10000 });
      window.open("/my-stories/", "_self");
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message, { autoClose: 10000 });
    }
  };

  onUpdate = async (e) => {
    e.preventDefault();
    // this.captureImage();
    // this.capturePDF();
    try {
      // const coverPageUrl = await this.captureImage();
      // const pdfUrl = await this.capturePDF();
      // await this.saveCoverPage(coverPageUrl);
      await this.saveCoverPage();

    } catch (error) {
      console.error("Error during update:", error);
    }
  };

  // onUpdate = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const { selectedStoryData, imageUrl, selectedColor, textPosition  } = this.state;

  //     // Capture the element
  //     const element = this.captureRef.current;
  //     if (!element) {
  //       console.error("Element not found!");
  //       return;
  //     }
  //     // const element = document.querySelector("#capture-element"); // Ensure the correct element ID or selector
  //     // console.log("element=====>", element);

  //     // if (!element) {
  //     //   console.error("Element not found!");
  //     //   return
  //     // };
  //     const canvas = await html2canvas(element);
  //     // console.log("canvas=====>>", canvas);
  //     const coverPageUrl = canvas.toDataURL("image/png"); // Base64 Data URL
  //     console.log("Captured Image Base64: ", coverPageUrl);
  //     // Create a FormData object to handle file upload
  //     let formData = new FormData();
  //     formData.append("id", selectedStoryData.id);
  //     formData.append("title", selectedStoryData.title);
  //     formData.append("subtitle", selectedStoryData.subtitle);
  //     formData.append("author", selectedStoryData.author);
  //     formData.append("cover_color", selectedColor);
  //     formData.append('cover_page', coverPageUrl);
  //     formData.append("book_text_position", textPosition);

  //     // Append the image file if a new one was uploaded, otherwise append the existing cover_image_url URL
  //     if (imageUrl) {
  //       formData.append("cover_image_url", imageUrl); // Assuming the backend expects 'cover_image_url' field for the file upload
  //     } else if (selectedStoryData.cover_image_url) {
  //       formData.append("cover_image_url", selectedStoryData.cover_image_url); // Append the existing cover_image_url URL
  //     }

  //     let updateResponse = await this.props.updateMyStory(formData); // Assuming updateMyStory handles FormData
  //     toast.success(updateResponse.message, { autoClose: 10000 });
  //     window.open("/my-stories/", "_self");
  //   } catch (err) {
  //     console.log(err);
  //     toast.error(err.response.data.message, { autoClose: 10000 });
  //   }
  // };

  handleColorChange = (color) => {
    this.setState({ selectedColor: color.hex });
  };
  // onUpdate = async (e) => {
  //   e.preventDefault()
  //   try {
  //     const { selectedStoryData } = this.state;
  //     let updateResponse = await this.props.updateMyStory({
  //       id: selectedStoryData.id,
  //       title: selectedStoryData.title,
  //       subtitle: selectedStoryData.subtitle,
  //       author: selectedStoryData.author,
  //       image: selectedStoryData.image
  //     });
  //     toast.success(updateResponse.message, { autoClose: 10000 });
  //     window.open("/my-stories/", "_self");
  //   } catch (err) {
  //     console.log(err);
  //     toast.error(err.response.data.message, { autoClose: 10000 });
  //     console.log(err);
  //   }
  // }
  onDropImages = (files) => {
    if (files && files.length !== 0) {
      const ALLOWED_FORMAT = "image/jpg, image/jpeg, image/png";
      if (ALLOWED_FORMAT.includes(files[0].type)) {
        this.setState({ imageUrl: files[0] });
      } else {
        toast.error("This file format is not accepted.", { autoClose: 3000 });
      }
    }
  };

  handleDeleteImage = () => {
    this.setState({ imageUrl: "" });
  };

  handleButtonClick = (index) => {
    if (index >= 0 && index < this.images.length) {
      this.setState({
        isFullImage: false,
        isNoImage: false,
        isInsertImage: false,
        activeIndex: index,
        selectedColor: this.images[index].backgroundColor,
      });
      localStorage.setItem('activeIndex', index);
    localStorage.setItem('selectedColor', this.images[index].backgroundColor);
    localStorage.setItem('isFullImage', false); // Ensure the correct state
    localStorage.setItem('isNoImage', false); // Ensure the correct state
    localStorage.setItem('isInsertImage', false); // Ensure the correct state
    }
  };

  handleImageButtonClick = (index) => {
    if (index >= 0 && index < this.fullImages.length) {
      this.setState({
        activeFullIndex: index,
        isFullImage: true, //this.fullImages[index].alt !== "Full image no text",
        isNoImage: false,
        isInsertImage: false,
        isSpanVisible: this.fullImages[index].alt !== "Full image no text",
      });
      localStorage.setItem('activeFullIndex', index);
    localStorage.setItem('isFullImage', true);
    localStorage.setItem('isSpanVisible', false);
    localStorage.setItem('isNoImage', false);
    localStorage.setItem('isInsertImage', false);
    }
  };

  handleNoImageButtonClick = (index) => {
    if (index >= 0 && index < this.noImages.length) {
      this.setState({
        activeNoImageIndex: index,
        isNoImage: true,
        isFullImage: false,
        isInsertImage: false,
        selectedColor: this.noImages[index].backgroundColor,
      });
      localStorage.setItem('activeNoImageIndex', index);
    localStorage.setItem('isNoImage', true);
    localStorage.setItem('isFullImage', false);
    localStorage.setItem('isInsertImage', false);
    localStorage.setItem('selectedColor', this.noImages[index].backgroundColor);
    }
  };

  handleInsertImageButtonClick = (index) => {
    if (index >= 0 && index < this.insertImages.length) {
      this.setState({
        activeInsertImageIndex: index,
        isInsertImage: true,
        isNoImage: false,
        isFullImage: false,
        selectedColor: this.insertImages[index].backgroundColor,
      });
      localStorage.setItem('activeInsertImageIndex', index);
      localStorage.setItem('isInsertImage', true);
      localStorage.setItem('isNoImage', false);
      localStorage.setItem('isFullImage', false);
      localStorage.setItem('selectedColor', this.insertImages[index].backgroundColor);
    }
  };
  // Utility function to determine text color based on background color
  getTextColor = (backgroundColor) => {
    // Convert hex to RGB
    let r = parseInt(backgroundColor.slice(1, 3), 16);
    let g = parseInt(backgroundColor.slice(3, 5), 16);
    let b = parseInt(backgroundColor.slice(5, 7), 16);

    // Calculate luminance
    let luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    // Return light or dark text color
    return luminance < 128 ? "#FFFFFF" : "#000000"; // Light text on dark background, dark text on light background
  };
  // Utility function to determine border color based on background color
  getBorderColor = (backgroundColor) => {
    let r = parseInt(backgroundColor.slice(1, 3), 16);
    let g = parseInt(backgroundColor.slice(3, 5), 16);
    let b = parseInt(backgroundColor.slice(5, 7), 16);
    let luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luminance < 128 ? "#FFFFFF" : "#000000"; // Light border on dark background, dark border on light background
  };

  handleTextPositionChange = (event) => {
    this.setState({ textPosition: event.target.value });
  };

  render() {
    const {
      imageUrl,
      selectedStoryData,
      selectedStyle,
      selectedColor,
      activeIndex,
      activeFullIndex,
      activeInsertImageIndex,
      activeNoImageIndex,
      textPosition,
      isFullImage,
    } = this.state;

    // console.log('selectedStoryData:', this.state.selectedStoryData);
// console.log('selectedStoryData.cover_image_url:', this.state.selectedStoryData.image);

    const textPositionStyles =
      textPosition === "top"
        ? { top: "17%" } // adjust as needed
        : { top: "82%" }; // adjust as needed
    const borderColor = this.getBorderColor(selectedColor);

    const textColor = this.getTextColor(selectedColor);

    const images = this.images; // Safeguard for undefined images

    // Ensure `images` and `activeIndex` are valid
    if (
      !images ||
      images.length === 0 ||
      activeIndex < 0 ||
      activeIndex >= images.length
    ) {
      return <div>No images available</div>; // Fallback UI when images are not available
    }

    const currentImage = images[activeIndex] || images[0];

    const fullImages = this.fullImages;
    // Ensure `images` and `activeIndex` are valid
    if (
      !fullImages ||
      fullImages.length === 0 ||
      activeFullIndex < 0 ||
      activeFullIndex >= fullImages.length
    ) {
      return <div>No images available</div>; // Fallback UI when images are not available
    }

    const currentFullImage = fullImages[activeFullIndex] || fullImages[0];

    const noImages = this.noImages;
    // Ensure `images` and `activeIndex` are valid
    if (
      !noImages ||
      noImages.length === 0 ||
      activeNoImageIndex < 0 ||
      activeNoImageIndex >= noImages.length
    ) {
      return <div>No images available</div>; // Fallback UI when images are not available
    }

    const currentNoImage = noImages[activeNoImageIndex] || noImages[0];

    const insertImages = this.insertImages;
    // Ensure `images` and `activeIndex` are valid
    if (
      !insertImages ||
      insertImages.length === 0 ||
      activeInsertImageIndex < 0 ||
      activeInsertImageIndex >= insertImages.length
    ) {
      return <div>No images available</div>; // Fallback UI when images are not available
    }

    const currentInsertImage =
      this.insertImages[activeInsertImageIndex] || insertImages[0];

    // console.log("====>>>",selectedStoryData);
    const coverImagePath = selectedStoryData.image;
    return (
      <Fragment>
        <ToastContainer />
        <GoogleFontLoader
          fonts={[
            {
              font: "Martel",
              weights: [300, 700],
            },
            {
              font: "Fira Sans",
              weights: [200, 500],
            },
          ]}
        />
        <Container fluid={true}>
          <CardBody>
            <Form onSubmit={this.onUpdate}>
              <Row>
                <h2
                  className="mt-5"
                  style={{
                    textAlign: "center",
                    font: "normal normal 400 2.2rem/1.4 Martel",
                    color: "#000",
                  }}
                >
                  Edit your story details
                </h2>
                <div style={{ textAlign: "center" }}>
                  <span
                    style={{
                      borderBottom: "1px solid #FEC1EB",
                      height: "1px",
                      width: "102px",
                      display: "inline-block",
                    }}
                  ></span>
                </div>
              </Row>
              <Row className="mt-3">
                <Col
                  md="12"
                  style={{
                    background: "#EEE",
                    padding: "2% 0% 2% 2%",
                    fontFamily: "Martel",
                  }}
                >
                  <Row>
                    <Col xs={1}></Col>
                    <Col xs={5}>
                      {this.state.isFullImage ? (
                        <div
                        ref={this.captureRef}
                          style={{
                            backgroundImage: `url(${
                              imageUrl
                                ? URL.createObjectURL(imageUrl)
                                : selectedStoryData.image || ""
                            })`,
                            height: "564px",
                            maxWidth: "400px",
                            position: "relative", // Ensure the parent is positioned relative
                            boxShadow: "-4px 5px 12px 0px #bababa",
                            borderRadius: "2px",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                          }}
                        >
                          {this.state.isSpanVisible && (
                            <div
                              style={{
                                background: "#fff",
                                position: "absolute",
                                left: "10%",
                                right: "10%",
                                padding: "2%",
                                border: "1px solid #000",
                                textAlign: "center",
                                ...textPositionStyles, // Apply conditional styles
                                transform: "translateY(-50%)", // Adjust centering for better visibility
                              }}
                            >
                              <span
                                style={{
                                  textTransform: "uppercase",
                                  fontSize: "1.75em",
                                  letterSpacing: ".05em",
                                  lineHeight: "2.0",
                                  display: "block",
                                  width: "calc(100% - 4px)",
                                  height: "auto",
                                  border: "1px solid #000",
                                }}
                              >
                                {selectedStoryData.title || ""}
                              </span>
                            </div>
                          )}
                        </div>
                      ) : this.state.isInsertImage ? (
                        <>
                          <div
                            ref={this.captureRef}
                            id="capture-element"
                            style={{
                              color: textColor,

                              background: selectedColor,
                              height: "564px",
                              maxWidth: "400px",
                              position: "relative",
                              boxShadow: "-4px 5px 12px 0px #bababa",
                              borderRadius: "2px",
                            }}
                          >
                            <div
                              style={{
                                color: textColor,

                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column",
                                position: "absolute",
                                right: "9%",
                                left: "9%",
                                top: "10%",
                                bottom: 0,
                              }}
                            >
                              <span
                                style={{
                                  color: textColor,

                                  height: "82%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={
                                    imageUrl
                                      ? URL.createObjectURL(imageUrl)
                                      : selectedStoryData.image || ""
                                  }
                                  width="500"
                                  height="1000"
                                  alt="story"
                                />
                              </span>
                              <span
                                style={{
                                  color: textColor,
                                  fontSize: "1.4em",
                                  lineHeight: "1",
                                  textTransform: "uppercase",
                                  letterSpacing: ".05em",
                                }}
                              >
                                {selectedStoryData.title || ""}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : this.state.isNoImage ? (
                        <>
                          <div
                            ref={this.captureRef}
                            id="capture-element"
                            style={{
                              color: textColor,

                              background: selectedColor,
                              height: "564px",
                              maxWidth: "400px",
                              position: "relative",
                              boxShadow: "-4px 5px 12px 0px #bababa",
                              borderRadius: "2px",
                            }}
                          >
                            <div
                              style={{
                                color: textColor,
                                content: " ",
                                display: "block",
                                height: "100%",
                                width: "50%",
                                borderRight: `1px solid ${
                                  borderColor ? borderColor : "#fff"
                                }`,
                                position: "absolute",
                                borderColor: "#fff",
                              }}
                            ></div>
                            <div
                              style={{
                                background: selectedColor,
                                color: textColor,

                                // background: "#fff",
                                position: "absolute",
                                top: "45%",
                                left: "10%",
                                right: "10%",
                                padding: "5%",
                                border: `1px solid ${borderColor}`,
                                textAlign: "center",
                                borderColor: "#fff",
                              }}
                            >
                              <span
                                style={{
                                  textTransform: "uppercase",
                                  fontSize: "1.75em",
                                  letterSpacing: ".05em",
                                  lineHeight: "1.2",
                                }}
                              >
                                {selectedStoryData.title || ""}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          ref={this.captureRef}
                          id="capture-element"
                          style={{
                            background: selectedColor,
                            color: textColor,

                            height: "564px",
                            maxWidth: "400px",
                            position: "relative",
                            boxShadow: "-4px 5px 12px 0px #bababa",
                            borderRadius: "2px",
                          }}
                        >
                          <div
                            style={{
                              color: textColor,
                              fontSize: "1.4em",
                              textAlign: "center",
                              marginTop: "5%",
                              minHeight: "1%",
                              letterSpacing: ".05em",
                              textTransform: "uppercase",
                              padding: "20% 10% 0% 13%",
                            }}
                          >
                            {selectedStoryData.title || ""}
                          </div>
                          <div
                            style={{
                              padding: "10% 10% 6% 13%",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={
                                imageUrl
                                  ? URL.createObjectURL(imageUrl)
                                  : selectedStoryData.image || ""
                              }
                              width="290"
                              height="300"
                              alt="story"
                            />
                          </div>
                          <div
                            style={{
                              color: textColor,
                              fontSize: "12px",
                              textAlign: "center",
                              letterSpacing: ".05em",
                              textTransform: "uppercase",
                              paddingTop: "2px",
                            }}
                          >
                            {selectedStoryData.author || ""}
                          </div>
                        </div>
                      )}
                    </Col>

                    <Col xs={5} className="mt-3">
                      <Label>Title</Label>
                      <Input
                        type="text"
                        name="title"
                        id="title"
                        value={selectedStoryData.title || ""}
                        onChange={this.handleInput}
                      />
                      <Label className="mt-3">Subtitle (optional)</Label>
                      <Input
                        type="text"
                        name="subtitle"
                        id="subtitle"
                        value={selectedStoryData.subtitle || ""}
                        onChange={this.handleInput}
                      />
                      <Label className="mt-3">Author(s)</Label>
                      <Input
                        type="text"
                        name="author"
                        id="author"
                        value={selectedStoryData.author || ""}
                        onChange={this.handleInput}
                      />
                      {this.state.isFullImage && (
                        <div style={{ textAlign: "center", marginTop: "20px" }}>
                          <p>Text position block</p>
                          <label>
                            <input
                              type="radio"
                              name="textPosition"
                              value="top"
                              checked={this.state.textPosition === "top"}
                              onChange={this.handleTextPositionChange}
                            />
                            Text Top
                          </label>
                          <label style={{ marginLeft: "15px" }}>
                            <input
                              type="radio"
                              name="textPosition"
                              value="bottom"
                              checked={this.state.textPosition === "bottom"}
                              onChange={this.handleTextPositionChange}
                            />
                            Text Bottom
                          </label>
                        </div>
                      )}
                      <Label className="mt-3">
                        Upload cover image (optional)
                      </Label>
                      {/* <img src={story} ></img> */}
                      <div>
                        <Dropzone
                          onDrop={this.onDropImages}
                          maxFiles={1}
                          multiple={false}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              style={{
                                height: "167px",
                                fontWeight: "400",
                                fontSize: "20px",
                                border: "4px dashed #ddd",
                                textAlign: "center",
                                padding: "30px",
                              }}
                              {...getRootProps()}
                            >
                              <input
                                name="photos"
                                id="photos"
                                {...getInputProps()}
                                accept="image/jpg, image/jpeg, image/png"
                                minsize="0"
                                maxsize="5242880"
                              />
                              Drop files here to upload
                              <p>or</p>
                              <button type="button" className="white-button">
                                SELECT FILES
                              </button>
                            </div>
                          )}
                        </Dropzone>
                        {imageUrl && (
                          <span>
                            <img
                              src={URL.createObjectURL(imageUrl)}
                              alt="Uploaded Image"
                              style={{
                                width: "100px",
                                height: "100px",
                                marginTop: "5px",
                              }}
                            />
                            <img
                              onClick={this.handleDeleteImage}
                              style={{
                                marginTop: "-100px",
                                marginLeft: "-10px",
                              }}
                              src={imgDelete}
                              width="20"
                              height="20"
                            ></img>
                          </span>
                        )}
                      </div>
                      {/* <Label className="mt-3">Show in public bookshelf?</Label> */}
                      {/* <div className="toggle-switch">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={this.state.isChecked}
                                  onChange={this.toggleCheckboxChange}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}></Col>
                    <Col xs={6} style={{ textAlign: "center" }}>
                      <button type="submit" className="custom-button">
                        {UpdateMyBook}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="feather feather-chevron-right"
                          fill="none"
                          height="24"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <polyline points="9 18 15 12 9 6" />
                        </svg>
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>

            <div class="change_book_design">
              <h2 class="center-with-line"> Change your story design</h2>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: "30px" }}
              >
                <TemplateSelector
                  style={{ width: "33%" }}
                  onClickPrev={() =>
                    this.handleButtonClick(
                      (activeIndex - 1 + images.length) % images.length
                    )
                  }
                  onClickNext={() =>
                    this.handleButtonClick((activeIndex + 1) % images.length)
                  }
                  activeIndex={activeIndex}
                  images={images}
                  currentImage={currentImage}
                  handleSelected={(index) => this.handleButtonClick(index)}
                />

                <TemplateSelector
                  heddings="Insert image"
                  onClickPrev={() =>
                    this.handleButtonClick(
                      (activeInsertImageIndex - 1 + insertImages.length) %
                        insertImages.length
                    )
                  }
                  onClickNext={() =>
                    this.handleButtonClick(
                      (activeInsertImageIndex + 1) % insertImages.length
                    )
                  }
                  activeIndex={activeInsertImageIndex}
                  images={insertImages}
                  currentImage={currentInsertImage}
                  handleSelected={(index) =>
                    this.handleInsertImageButtonClick(index)
                  }
                />

                <TemplateSelector
                  heddings="No image"
                  onClickPrev={() =>
                    this.handleButtonClick(
                      (activeNoImageIndex - 1 + noImages.length) %
                        noImages.length
                    )
                  }
                  onClickNext={() =>
                    this.handleButtonClick(
                      (activeNoImageIndex + 1) % noImages.length
                    )
                  }
                  activeIndex={activeNoImageIndex}
                  images={noImages}
                  currentImage={currentNoImage}
                  handleSelected={(index) =>
                    this.handleNoImageButtonClick(index)
                  }
                />
              </div>

              <div style={{ marginTop: "80px", width: "33%" }}>
                <TemplateSelector
                  heddings="Full image"
                  onClickPrev={() =>
                    this.handleButtonClick(
                      (activeFullIndex - 1 + fullImages.length) %
                        fullImages.length
                    )
                  }
                  onClickNext={() =>
                    this.handleButtonClick(
                      (activeFullIndex + 1) % fullImages.length
                    )
                  }
                  activeIndex={activeFullIndex}
                  images={fullImages}
                  currentImage={currentFullImage}
                  handleSelected={(index) => this.handleImageButtonClick(index)}
                />
              </div>
            </div>
          </CardBody>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { loggedInUserId: state.auth.session.user.id };
};

const mapDispatchToProps = {
  listMyStory: myStoryOperations.listMyStory,
  getMyStory: myStoryOperations.getMyStory,
  updateMyStory: myStoryOperations.updateMyStory,
};
export default connect(mapStateToProps, mapDispatchToProps)(EditMyStories);
