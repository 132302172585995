import React, { Fragment } from 'react';
import { Container, CardBody, Col, Form, Row } from 'reactstrap';
import { connect } from 'react-redux';
import GoogleFontLoader from 'react-google-font-loader';
import '../Button.css';
import { myStoryOperations } from '../../state/ducks/story';
import '../ViewThisStory.css'; 
import { toast } from 'react-toastify';

class ReadThisStory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          allQuestionData: [],
          isSidebarOpen: false,
          isInviteFormOpen: false,
          inviteSentMessage: '',
          inviteeName: '',
          inviteeEmail: '',
          inviteMessage: '',
        };
         // Bind your methods here if not using arrow functions in class properties
    this.handleSendInvite = this.handleSendInvite.bind(this);
      }
    
      async componentDidMount() {
        try {
          const response = await this.props.listQuestion();
          
          this.setState({ allQuestionData: response.payload });
        } catch (error) {
          console.log(error);
        }
      }

      toggleSidebar = () => {
        this.setState(prevState => ({ isSidebarOpen: !prevState.isSidebarOpen }));
    }
    
    toggleInviteForm = () =>{
        this.setState(prevState =>({ isInviteFormOpen: !prevState.isInviteFormOpen }));
    }

      handleQuestionClick = (id) => {
        localStorage.setItem('selectedQuestionId', JSON.stringify(id));
        localStorage.setItem('allQuestionData', JSON.stringify(this.state.allQuestionData));
        window.location.href = '/your-web-pages/view-this-story'; // Adjust the URL according to your routing
    };
   
    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    
    handleSendInvite = async (e) => {
        e.preventDefault();
        const { inviteeName, inviteeEmail, inviteMessage } = this.state;
        const inviteData = {
            name: inviteeName,
            email: inviteeEmail,
            message: inviteMessage
        };
        try {
            await this.props.SendAnInviteToStory(inviteData);
            // toast.success('Invite sent successfully');
            this.setState({ inviteSentMessage: 'An invite has been sent.', isInviteFormOpen: false});
            // this.setState({ isInviteFormOpen: false, inviteSentMessage: 'An invite has been sent.' });
        } catch (error) {
            toast.error(error.response.data.message, { autoClose: 10000 });
            console.error('Error sending invite:', error);
        }
        // Implement your invite send logic here
        // this.setState({ inviteSentMessage: 'An invite has been sent.', isInviteFormOpen: false});
    }
    
    render() {
        const {allQuestionData,isSidebarOpen,isInviteFormOpen,inviteSentMessage,inviteeName,inviteeEmail,inviteMessage} = this.state;
        // console.log("Sidebar Open: ", isSidebarOpen);
        // console.log("Invite Form Open: ", isInviteFormOpen);
        // console.log("Invite Sent Message: ", inviteSentMessage);
        return (
            <Fragment>
                <GoogleFontLoader
                    fonts={[
                        {
                            font: 'Martel',
                            weights: [400, 500, 300, 700],
                        },
                        {
                            font: 'Fira Sans',
                            weights: [300, 700, 500, 400],
                        },
                    ]}
                />
                <Container fluid={true}>
                    <CardBody>
                        <Form>
                            <Row>
                                <div className='mt-5' style={{ textAlign: 'center' }}>
                                    <button type='button' className='custom-button' onClick={this.toggleSidebar} style={{margin: "0 auto"}}>{'Chapters '}<svg xmlns="http://www.w3.org/2000/svg" class="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"><polyline points="9 18 15 12 9 6"/></svg></button>
                                </div>
                            </Row>
                            <Row>
                                <h2 className='mt-5' style={{ textAlign: 'center', font: 'normal normal 300 2.2rem/1.4 Martel', color: "#000" }}>Your story - {this.props.loggedInUserName}</h2>
                                <div style={{ textAlign: 'center' }}>
                                    <span style={{ borderBottom: "1px solid #FEC1EB", height: "1px", width: "102px", display: "inline-block" }}></span>
                                </div>
                            </Row>
                            <Row className='mt-3'>
                                <div style={{ textAlign: 'center', fontSize: "17px", fontWeight: "300", fontFamily: "Fira Sans" }}>{this.props.loggedInUserName} 08th Feb 2024</div>
                            </Row>
                            <Row className='mt-12' style={{ display: 'flex', justifyContent: 'center', maxWidth:"560px", margin: "0 auto", paddingTop: "30px"}}>
                                <Col xl='12'>
                                    <ol className='readThisStoryList' style={{ fontSize: "17.6px", fontWeight: "500", fontFamily: "Fira Sans", display: "block" }}>
                                        {this.state.allQuestionData.map((question, index) => (
                                            <li key={index} style={{lineHeight: "1.8", borderBottom: "1px solid #ccc" }}>
                                            <a href='/your-web-pages/view-this-story/' onClick={ () => this.handleQuestionClick(question.id)}>{question.question}</a>
                                            </li>
                                        ))}
                                        {/* <li style={{ borderBottom: "1px solid #ccc" }}><a href='/your-web-pages/view-this-story/'>Was there a particular teacher or non-related adult who made an impression on you and why?</a></li> */}
                                    </ol>
                                    
                                </Col>
                            </Row>
                            <Row className='mt-4' style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ height: "2px", width: "15%", background: "#7f2b72" }}></div>
                            </Row>
                            <Row className='mt-1 mb-5'></Row>
                            <Row className='mt-1 mb-5'></Row>
                        </Form>
                    </CardBody>
                </Container>
                <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                    <div className='sidebarInner'>
                    <h2>Chapters</h2>
                    <ol>
                        {allQuestionData.map((question, index) => (
                            <li className='readThisStoryListIteam' key={index} onClick={() => this.handleQuestionClick(index)}>
                                {question.question}
                            </li>
                        ))}
                    </ol>
                    <button style={{background:"#fff", border:"none", padding: "21px 20px",display: "flex", justifyContent: "center", letterSpacing: "0.115rem", font: "normal normal normal 0.95rem / 1.2 Fira Sans"}} onClick={this.toggleInviteForm}> {'SEND AN INVITE TO THIS STORY'}&nbsp; <span style={{color:"#8A1A75"}}>{'>'}</span></button>
                    {isInviteFormOpen && (
                    <div className="invite-form">
                            <h4>Send invite to view this story</h4>
                            <p>Send an invite which contains a unique url to view this book</p>
                            <form onSubmit={this.handleSendInvite}>
                            <div style={{ marginBottom: "10px" }}>
                                    <label>Invitee name</label>
                                    <input type="name" name="inviteeName" value={inviteeName} onChange={this.handleInputChange} />
                                </div>
                                <div style={{ marginBottom: "10px" }}>
                                    <label>Invitee email</label>
                                    <input type="email" name="inviteeEmail" value={inviteeEmail} onChange={this.handleInputChange}/>
                                </div>
                                <div style={{ marginBottom: "10px" }}>
                                    <label>Message</label>
                                    <textarea style={{height: "288px"}}name="inviteMessage" value={inviteMessage} onChange={this.handleInputChange} />
                                </div>
                                <button type="submit" >Send Invite</button>
                            </form>
                        </div>
                        )}
                        {inviteSentMessage && (
                        <div className="invite-sent-message">
                            <h3>Send invite to view this story</h3>
                            <span>{inviteSentMessage}</span>
                        </div>
                    )}
                    </div>
                </div> 
                <div className={`sidebar-overlay ${isSidebarOpen ? 'open' : ''}`} onClick={this.toggleSidebar}></div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => { 
return { loggedInUserName: state.auth.session.user.user_name }; 
};

const mapDispatchToProps = {
    listQuestion: myStoryOperations.listQuestion,
    SendAnInviteToStory: myStoryOperations.SendAnInviteToStory,

};

export default connect(mapStateToProps, mapDispatchToProps)(ReadThisStory);