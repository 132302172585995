import React, { Fragment } from "react";
import { Container, CardBody, Col, Form, Row } from "reactstrap";
import click from "../../assets/images/datatable/click.png";
import { Link } from "react-router-dom";
import { myStoryOperations } from "../../state/ducks/story";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import GoogleFontLoader from "react-google-font-loader";
import { ToastContainer, toast } from "react-toastify";
import "../Button.css";

class ViewQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allQuestionData: [],
      selectedStoryData: {},
    };
  }

  async componentDidMount() {

    const storyId = localStorage.getItem("selectedStoryId");
    // console.log("storyId===>>>>",storyId);
    const response = await this.props.getMyStory({ id: storyId });
    const storyData = response.payload;
    // console.log("storys===>>>",storyData);
    this.setState({
      selectedStoryData: storyData,
    });
    try {
      const response = await this.props.listQuestion();
      // console.log("responsequestions==>>",response);
      const savedOrder = JSON.parse(localStorage.getItem("questionOrder"));
      const orderedQuestions = savedOrder
        ? savedOrder.map((id) =>
            response.payload.find((question) => question.id === id)
          )
        : response.payload;
      this.setState({ allQuestionData: orderedQuestions });
    } catch (error) {
      console.log(error);
    }
  }

  onAddQuestionClick = () => {
    window.open("/my-stories/add-question/", "_self");
  };

  handlePDFClick = (e) => {
    const questionId = e.currentTarget.getAttribute("data-test-id");
    localStorage.setItem("selectedQuestionId", questionId);
    window.open("/my-stories/view-question/pdf/", "_self");
  };

  onDeleteClick = async (e) => {
    e.preventDefault();
    const confirmed = window.confirm("Are you sure, you want to delete it?");
    if (!confirmed) return;
    try {
      let questionId = e.target.getAttribute("data-test-id");
      const response = await this.props.deleteQuestion({ id: questionId });
      toast.success(response.message, { autoClose: 10000 });
      window.open("/my-stories/view-question/", "_self");
    } catch (err) {
      console.log(err);
    }
  };
onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
  
    const reorderedData = Array.from(this.state.allQuestionData);
    const [movedItem] = reorderedData.splice(result.source.index, 1);
    reorderedData.splice(result.destination.index, 0, movedItem);

    this.setState({ allQuestionData: reorderedData }, () => {
      const questionOrder = reorderedData.map((question) => question.id);
      localStorage.setItem("questionOrder", JSON.stringify(questionOrder));
    });
  };

  handleEditClick = (e) => {
    const questionId = e.currentTarget.getAttribute("data-test-id");
    localStorage.setItem("selectedQuestionId", questionId);
    window.open("/my-stories/view-question/edit/", "_self");
  };

  handleViewClick = (e) => {
    const questionId = e.currentTarget.getAttribute('data-test-id');
    localStorage.setItem('selectedQuestionId', questionId);
    // window.location.href = '/contributors/contributor-view-question/';
    window.open('/my-stories/view-question/view/', '_self');
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "#EFEFEF" : "#F7EBF3",
    margin: "10px 0",
    borderRadius: "9px",
    fontWeight: "400",
    height: "75px",
    display: "flex",
    fontFamily: "Fira Sans",
    ...draggableStyle,
  });
  renderQuestions() {
    const ulStyle = {
      display: "flex",
      padding: "2px 0px 10px 0px",
      fontSize: "12px",
      fontWeight: "600",
    };
    const liStyle = {
      borderRight: "2px solid #FEC1EB",
      padding: "0 10px",
    };

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="questions">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="question-list">
              {this.state.allQuestionData.map((question, index) => (
                question ?  // Add a check here
                <Draggable
                  key={question.id}
                  draggableId={question.id.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <>
                    {/* <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <div style={{ fontSize: '17.6px', background: '#8A1A75', marginLeft: '-10px 1rem -30px calc(-1rem - 4px)', borderRadius: '10px 0 0 10px', minWidth: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff' }}>Q</div>
                      <div style={{ maxWidth: '950px', height: '75px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', paddingLeft: '10px', flexGrow: 1 }}>
                        <div style={{ padding: '5px 0px 0px 10px' }}>
                          <span style={{ fontWeight: '400', fontSize: '17.6px' ,color: '#212529'}}>{question.question}</span>&nbsp;
                          {
                                question.last_edit === null ?
                                 <>
                                 <span> </span> <br></br>
                                 </>
                                 :
                                 <>
                                 <span style={{ background:"#fff", color:"grey", fontSize: "11px", fontFamily:"Fira Sans", fontWeight:"300",padding:"0px 6px", borderRadius:"6px"  }}>{question.status}</span> <br></br>
                                 </>
                          }
                          <span style={{ fontSize: '15.4px', color:"#36454f"}}>Added on {question.date} by {this.props.loggedInUserName}</span>
                        </div>
                        <ul style={ulStyle}>
                          <li style={liStyle} data-test-id={question.id} onClick={this.handleEditClick}><Link to="#">EDIT</Link></li>
                          <li style={liStyle} data-test-id={question.id}onClick={this.handlePDFClick}><Link to="#">PDF</Link></li>
                          <li style={liStyle} data-test-id={question.id}onClick={this.onDeleteClick}><Link to="#" >DELETE</Link></li>
                        </ul>
                      </div>
                      <div style={{ background: '#EDD3E5', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '55px', marginRight: '-13px', borderRadius: '0 10px 10px 0' }}>
                        <img src={click} width="35" height="35" alt='click' />
                      </div>
                    </div> */}
                    
                      <div
                        className="list-group-item item-with-data d-flex justify-content-between align-items-start"
                        data-order=""
                        data-id="7486"
                        data-type="question"
                        draggable="true"
                           ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      // style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <div className="d-flex">
                          <div className="listing-status">Q</div>
                          <div className="listing-title">
                          {question.question}
                            {
                            question.last_edit === null ? <> <small></small></>:
                             <>
                            <small className="q_status">{question.status}</small>
                            </>
                            }
                            <br />
                            <small>
                            Added on {question.date} by {this.props.loggedInUserName}
                            </small>
                          </div>
                        </div>

                        <div className="listing-actions d-flex">
                       
                          <span className="action_btns">
                          <Link to="#" className="inline-button" data-test-id={question.id} onClick={this.handleViewClick}>VIEW</Link>
                          <span>|</span>
                          <Link to="#" className="inline-button" data-test-id={question.id} onClick={this.handleEditClick}>EDIT</Link>
                            <span>|</span>
                            <Link to="#" className="inline-button" data-test-id={question.id} onClick={this.handlePDFClick}>PDF</Link>
                            <span>|</span>
                            <Link to="#" className="inline-button" data-test-id={question.id}onClick={this.onDeleteClick}>DELETE</Link>
                          </span>

                          <div className="listing-grab"></div>
                        </div>
                      </div>
                      </>
                  )}
                </Draggable>
                : null // Render nothing if question is undefined
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  render() {
    const { selectedStoryData,allQuestionData } = this.state;
    // console.log("allQuestionData==>>>",allQuestionData);
    return (
      <Fragment>
        <ToastContainer />
        <GoogleFontLoader
          fonts={[
            {
              font: "Martel",
              weights: [300, 700],
            },
            {
              font: "Fira Sans",
              weights: [300, 400, 700],
            },
          ]}
        />
        <Container fluid={false}>
          <CardBody>
            <Form>
              <Row>
                <h2
                  className="mt-5"
                  style={{
                    textAlign: "center",
                    font: "normal normal 300 2.2rem/1.4 Martel",
                    color: "#000",
                  }}
                >
                  { selectedStoryData.title } 
                </h2>
                <div style={{ textAlign: "center" }}>
                  <span
                    style={{
                      borderBottom: "1px solid #FEC1EB",
                      height: "1px",
                      width: "102px",
                      display: "inline-block",
                    }}
                  ></span>
                </div>
              </Row>
              <Row className="viewQuestionData">
                {/* <Col md="2"></Col> */}
                <Col md="12">
                  <div
                    className="mb-4"
                    style={{
                      borderBottom: "1px solid #fdc1ea",
                      paddingBottom: "20px",
                    }}
                  >
                    <span
                      style={{
                        font: "normal normal 300 1.8rem/1.4 Martel",
                        color: "#000000",
                      }}
                    >
                      Questions
                    </span>
                    <br></br>
                    <span
                      style={{
                        font: "normal normal 300 1.1rem/1.5 Fira Sans",
                        color: "#404040",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      Below are all the questions that have been added to your
                      story. Questions will become separate chapters and this
                      list will become your table of contents.
                    </span>
                  </div>
                </Col>
                {/* <Col md="2"></Col> */}
                {/* <Col md="2"></Col> */}
                <Col md="12">
                  <Row>{this.renderQuestions()}</Row>
                  <Row className="mt-3">
                    {/* <Col xs={5}></Col> */}
                    <Col xs={12} style={{textAlign: "center"}}>
                      <button
                        type="button"
                        className="custom-button"
                        onClick={this.onAddQuestionClick}
                        style={{margin: "0 auto"}}
                      >
                        {"Add A Question "}<svg xmlns="http://www.w3.org/2000/svg" class="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"><polyline points="9 18 15 12 9 6"/></svg>
                      </button>
                    </Col>
                    {/* <Col xs={4}></Col> */}
                  </Row>
                </Col>
                {/* <Col md="2"></Col> */}
              </Row>
            </Form>
          </CardBody>
        </Container>
      </Fragment>
    );
  }
}



const mapStateToProps = (state) => { 
  return {  loggedInUserId: state.auth.session.user.id,
    loggedInUserName: state.auth.session.user.user_name,
   }; };

const mapDispatchToProps = {
  listQuestion: myStoryOperations.listQuestion,
  deleteQuestion: myStoryOperations.deleteQuestion,
  // listMyStory: myStoryOperations.listMyStory,
  getMyStory: myStoryOperations.getMyStory,

};

export default connect(mapStateToProps, mapDispatchToProps)(ViewQuestion);
