import React, { Fragment } from 'react';
import { Container, Card, CardBody, Input, Col, Form, Row, Label } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { myStoryOperations } from '../../state/ducks/story';
import { connect } from 'react-redux';
import {SaveYourContent} from '../../Constant';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class JustWrite extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      text: '',
      imagePreviews: [],
      selectedOption: 'option1',
      numCols: 1,
      onAdd: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.imageHandler = this.imageHandler.bind(this);
  }

  handleChange(value) {
    this.setState({ text: value });
  }

  imageHandler() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = () => {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const range = this.quill.getEditor().getSelection();
        this.quill.getEditor().insertEmbed(range.index, 'image', e.target.result);
      };
      reader.readAsDataURL(file);
    };
  }

  onInsert = async (event) => {
    event.preventDefault();
    try {
      const payload = {
        question: this.state.title,
        description: this.state.text,
        status: this.state.selectedOption
      }
      const response = await this.props.justWrite(payload);
      // console.log("add question====>>>",response);
      toast.success(response.message, { autoClose: 10000 });
      // window.open("/my-stories/just-write/", "_self");
    } catch (err) {
      toast.error(err.response.data.message, { autoClose: 10000 });
      console.log(err);
    }
  }

  handleOptionChange = (e) => {
    const selectedOption = e.target.value;
    this.setState({ selectedOption });
  };

  handleAddCol = () => {
    this.setState(prevState => ({
      onAdd: true,
      numCols: prevState.numCols + 1
    }));
  };

  handleRemoveCol = () => {
    if (this.state.numCols > 1) { // Ensure there's at least one column
      this.setState(prevState => ({
        numCols: prevState.numCols - 1
      }));
    }
  };

  handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newPreviews = [...this.state.imagePreviews];
        newPreviews[index] = {
          preview: e.target.result,
          name: file.name
        };
        this.setState({ imagePreviews: newPreviews });
      };
      reader.readAsDataURL(file);
    }
  };

  render() {
    const { imagePreviews, numCols, onAdd } = this.state;
    const colComponents = Array.from({ length: numCols }, (_, index) => (
      <Col key={index} xs={12} className='mt-3' style={{ background: "#f6f6f6", padding: "20px", margin: "10px", float: "left", display: "flex" }}>
        <div style={{ border: "2px dashed #FEC1EB", height: "130px", width: "50%" }}>
          {imagePreviews[index] ? (
            <>
              <img src={imagePreviews[index].preview} alt="Preview" style={{ width: "75%", height: "75%", textAlign: "center", padding: "2% 10% 1% 40%" }} />
              <p style={{ fontSize: "12px", textAlign: "center" }}>{imagePreviews[index].name}</p>
            </>
          ) : (
            <div style={{ height: "130px", fontWeight: "400", fontSize: "20px", textAlign: "center", padding: "20px" }}>
              <p style={{ fontSize: "16px", margin: "0" }}>Drop files here to upload</p>
              <p style={{ margin: "0" }}>or</p>
              <input type='file' style={{ fontSize: "12px", paddingLeft: "60px", border: "none", background: "none" }} onChange={(e) => this.handleFileChange(e, index)} />
            </div>
          )}
        </div>
        <div style={{ padding: " 0px 10px 10px 10px", height: "130px", width: "50%" }}>
          <Label>Image caption (optional)</Label>
          {onAdd === true ?
            <button type='button' onClick={this.handleRemoveCol} style={{ marginLeft: "44%", border: "none", fontWeight: "bold", background: "#ccc", borderRadius: "20px", color: "#fff", fontSize: "10px", textAlign: "right" }}>X</button>
            : <span></span>}
          <Input type="textarea" style={{ height: "105px" }} name="caption" id={`caption_${index}`} />
        </div>
      </Col>
    ));
    const modules = {
      toolbar: {
        container: [
          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
          [{ 'size': [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' },
          { 'indent': '-1' }, { 'indent': '+1' }],
          ['link', 'image', 'video'],
          ['clean']
        ],
        handlers: {
          image: this.imageHandler
        }
      }
    };
    return (
      <Fragment>
        <div className='page-title'></div>
        <Container fluid={true}>
          <Row style={{ fontFamily: "Martel" }}>
            <Col sm="12">
              <Card>
                <h1 style={{ textAlign: "center", marginTop: "2%" }}>Just Write</h1>
                <span style={{ borderBottom: "2px solid #FEC1EB", height: "2px", width: "102px", margin: "20px auto" }}></span>
                <CardBody>
                  <Form onSubmit={this.onInsert}>
                    <Row>
                      <Col md='2'></Col>
                      <Col md='8'>
                        <Row>
                          <Col xs={12}>
                            <Label style={{ fontSize: "17px" }}>Title</Label>
                            <Input type="text" name="cname" id="cname" onChange={(e)=> this.setState({ title: e.target.value})}/>
                            <Label style={{ fontSize: "17px" }} className='mt-3'>Content</Label>
                            <ReactQuill
                              ref={(el) => { this.quill = el; }}
                              value={this.state.text}
                              onChange={this.handleChange}
                              modules={modules}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col md='2'></Col>
                    </Row>
                    <Row className='mt-4 mb-4'>
                      <Col md="3"></Col>
                      <Col md="9">
                        <span style={{ marginLeft: "14%", display: "block", content: "", height: "2px", width: "40%", background: "#7f2b72" }}></span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4"></Col>
                      <Col md="8">
                        <button type='submit' style={{ marginLeft: "10%", background: "#8A1A75", letterSpacing: "0.15rem", border: "2px solid #8A1A75", fontWeight: "300", color: "#fff", borderRadius: "30px", padding: "0 20px", textTransform: "uppercase", minHeight: "45px" }}>{SaveYourContent}</button>
                      </Col>
                    </Row>
                    {/* <Row className='mt-5'>
                      <Col md='2'></Col>
                      <Col md='8'>
                        <Row>
                          <Col xs={12}>
                            <div style={{ color: "#43107E" }}>UPLOAD IMAGES TO INCLUDE IN YOUR ANSWER (UP TO 10 IMAGES PER QUESTION)</div>
                            <div style={{ fontSize: ".75rem" }}>Uploaded images can be placed using the “Insert your images” button in the text format panel</div>
                          </Col>
                          {colComponents}
                          <Col xs={5}>
                            <button type='button' onClick={this.handleAddCol} style={{ background: "#8A1A75", fontSize: ".9rem", letterSpacing: ".1rem", border: "2px solid #8A1A75", fontWeight: "300", color: "#fff", borderRadius: "30px", padding: "0.5rem 1rem", textTransform: "uppercase", minHeight: "45px" }}>ADD ANOTHER IMAGE</button>
                          </Col>
                        </Row>
                      </Col>
                      <Col md='2'></Col>
                    </Row> */}
                    <Row className='mt-3'>
                      <Col xs={12}>
                        <div style={{ textAlign: "center", marginBottom: "1%" }}>STATUS</div>
                        <div style={{ textAlign: "center" }}>
                          <label style={{ fontSize: "15px", background: "#f8eaf4", padding: "2px 15px", borderRadius: "18px" }}>
                            <input
                              type="radio"
                              value="Not started"
                              checked={this.state.selectedOption === 'Not started'}
                              onChange={this.handleOptionChange}
                            />&nbsp;Not started
                          </label>&nbsp;&nbsp;&nbsp;
                          <label style={{ fontSize: "15px", background: "#f8eaf4", padding: "2px 15px", borderRadius: "18px" }}>
                            <input
                              type="radio"
                              value="Ongoing"
                              checked={this.state.selectedOption === 'Ongoing'}
                              onChange={this.handleOptionChange}
                            />&nbsp;Ongoing
                          </label>&nbsp;&nbsp;&nbsp;
                          <label style={{ fontSize: "15px", background: "#f8eaf4", padding: "2px 15px", borderRadius: "18px" }}>
                            <input
                              type="radio"
                              value="Complete"
                              checked={this.state.selectedOption === 'Complete'}
                              onChange={this.handleOptionChange}
                            />&nbsp;Complete
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
  justWrite: myStoryOperations.justWrite,
};
export default connect( mapStateToProps,mapDispatchToProps )(JustWrite);