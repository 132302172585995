import React, { Fragment } from "react";
import { Container, CardBody, Input, Col, Form, Row, Label } from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import { myStoryOperations } from "../../state/ducks/story";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Save} from "../../Constant";
import GoogleFontLoader from "react-google-font-loader";
import "../Button.css";
class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      imagePreviews: [],
      selectedOption: "",
      numCols: 1,
      onAdd: false,
      selectedQuestionData: "",
      showInputField: false,
      showSecondDropdown: false,
      isEditing: false,
      content: "",
      hashkey:""
    };
    this.h2Ref = React.createRef();
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.imageHandler = this.imageHandler.bind(this);
    this.saveCursorPosition = this.saveCursorPosition.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  async componentDidMount() {
    const questionId = localStorage.getItem("selectedQuestionId");
    const response = await this.props.listContributorQuestionDetails({ id: questionId });
    const questionData = response.payload;
 // Retrieve the hash key from sessionStorage
 const hashkey = sessionStorage.getItem("contributorHashKey");
    this.setState({
      selectedQuestionData: questionData,
      selectedOption: questionData.status,
      text: questionData.answer,
      content: questionData.question,
      hashkey: hashkey,
    });
  }


  handleHashKeyChange = (newHashKey) => {
    this.setState({ hashkey: newHashKey });
  }
  handleChange(value) {
    this.setState({ text: value });
  };

  imageHandler() {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = () => {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const range = this.quill.getEditor().getSelection();
        this.quill
          .getEditor()
          .insertEmbed(range.index, "image", e.target.result);
      };
      reader.readAsDataURL(file);
    };
  };

  onUpdate = async (e) => {
    this.toggleEdit();
    e.preventDefault();

    try {
      const {
        selectedQuestionData,
        selectedOption,
        text,
        content,
        hashkey
       
      } = this.state;
    
      let updateResponse = await this.props.updateContributorQuestion({
        id: selectedQuestionData.id,
        question: content,
        status: selectedOption,
        answer: text,
        hashkey: hashkey
      });
      toast.success(updateResponse.message, { autoClose: 10000 });
      // window.open("/my-stories/view-question/", "_self");
    } catch (err) {
      toast.error(err.updateResponse?.data?.message, { autoClose: 10000 });
      console.log(err);
    }
  };

  handleInput = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleOptionChange = (e) => {
    const selectedOption = e.target.value;
    this.setState({ selectedOption });
  };

  handleAddCol = () => {
    this.setState((prevState) => ({
      onAdd: true,
      numCols: prevState.numCols + 1,
    }));
  };

  handleRemoveCol = () => {
    if (this.state.numCols > 1) {
      this.setState((prevState) => ({
        numCols: prevState.numCols - 1,
      }));
    }
  };

  handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newPreviews = [...this.state.imagePreviews];
        newPreviews[index] = {
          preview: e.target.result,
          name: file.name,
        };
        this.setState({ imagePreviews: newPreviews });
      };
      reader.readAsDataURL(file);
    }
  };

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      showInputField: !prevState.showInputField,
      showSecondDropdown: !prevState.showInputField, // Ensure the button is shown when the checkbox is checked
    }));
  };

  handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    this.setState({
      selectedCategory,
      showSecondDropdown: selectedCategory !== "",
    });
  };

  toggleEdit() {
    this.setState(
      (prevState) => ({
        isEditing: !prevState.isEditing,
      }),
      () => {
        if (this.state.isEditing && this.h2Ref.current) {
          //this.restoreCursorPosition();
          this.h2Ref.current.focus();
        }
      }
    );
  }

  handleInputChange(event) {
    //console.log(event.target.textContent)
    this.saveCursorPosition();
    this.setState({
      content: event.target.value,
    });
  }
  handleBlur() {
    this.setState({ isEditing: false });
  }

  saveCursorPosition() {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const preSelectionRange = range.cloneRange();
      preSelectionRange.selectNodeContents(this.h2Ref.current);
      preSelectionRange.setEnd(range.startContainer, range.startOffset);
      this.cursorPosition = preSelectionRange.toString().length;
    }
  }

  render() {
    const {
      isEditing,
      content,
      selectedQuestionData,
      text,
      selectedOption,
      
    } = this.state;

    const modules = {
      toolbar: {
        container: [
          // [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
          // [{ 'size': [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ align: [] }],
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: this.imageHandler,
        },
      },
    };
    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: "Martel",
              weights: [300, 700],
            },
            {
              font: "Fira Sans",
              weights: [300, 700, 400, 800],
            },
          ]}
        />
        <Container fluid={true}>
          <CardBody>
            <Form onSubmit={this.onUpdate}>
              <div className="editBoxDesign">
                <Row style={{ display: "flex", justifyContent: "center" }}>
                  {isEditing ? (
                    <input
                      ref={this.h2Ref}
                      type="text"
                      // value={content}
                      onChange={this.handleInputChange}
                      style={{
                        width: "80%",
                        textAlign: "center",
                        font: "normal normal 300 2.2rem/1.4 Martel",
                        color: "#000",
                      }}
                      onBlur={this.toggleEdit}
                    />
                  ) : (
                    <h2
                      className="mt-5 mb-3"
                      style={{
                        width: "80%",
                        textAlign: "center",
                        font: "normal normal 300 2.2rem/1.4 Martel",
                        color: "#000",
                      }}
                      onClick={this.toggleEdit}
                    >
                      {content}
                    </h2>
                  )}
                </Row>
                <Row style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      textAlign: "center",
                      font: "normal normal 400 .65rem/1 Fira Sans",
                      textTransform: "uppercase",
                      letterSpacing: ".08rem",
                      color: "#8A1A75",
                    }}
                  >
                    BY GIFT YOUR STORY ON {selectedQuestionData.date}
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      display: "block",
                      content: "",
                      height: "2px",
                      width: "20%",
                      background: "#7f2b72",
                    }}
                  ></div>
                  <br></br>
                </Row>
                <Row className="mt-3 mb-5">
                  <Col md="12">
                    <div
                      style={{
                        font: "normal normal 300 1.0rem/1.5 Fira Sans",
                        color: "#000",
                      }}
                    >
                      {selectedQuestionData.description}
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "5%" }}>
                  <Col md="12">
                    <ReactQuill
                      style={{ height: "400px" }}
                      ref={(el) => {
                        this.quill = el;
                      }}
                      value={this.state.text}
                      onChange={this.handleChange}
                      modules={modules}
                    />
                  </Col>
                </Row>
              </div>
              <Row
                style={{ display: "flex", justifyContent: "center" }}
                className="mt-5"
              >
                <span
                  style={{
                    content: "",
                    height: "2px",
                    width: "20%",
                    background: "#7f2b72",
                  }}
                ></span>
              </Row>
              <Row className="mt-5 mb-3">
                <Col md="12">
                  <div
                    style={{
                      textAlign: "center",
                      color: "#43107E",
                      letterSpacing: ".122rem",
                      font: "normal normal 400 0.75rem/1 Fira Sans",
                    }}
                  >
                    STATUS
                  </div>
                  <div
                    className="mt-3 mb-5"
                    style={{
                      textAlign: "center",
                      font: "normal normal 300 1.0rem/1.5 Fira Sans",
                    }}
                  >
                    <label
                      style={{
                        background: "#f8eaf4",
                        padding: "2px 15px",
                        borderRadius: "18px",
                      }}
                    >
                      <input
                        type="radio"
                        value="Not started"
                        checked={selectedOption === "Not started"}
                        onChange={this.handleOptionChange}
                      />
                      &nbsp;Not started
                    </label>
                    &nbsp;&nbsp;&nbsp;
                    <label
                      style={{
                        background: "#f8eaf4",
                        padding: "2px 15px",
                        borderRadius: "18px",
                      }}
                    >
                      <input
                        type="radio"
                        value="Ongoing"
                        checked={selectedOption === "Ongoing"}
                        onChange={this.handleOptionChange}
                      />
                      &nbsp;Ongoing
                    </label>
                    &nbsp;&nbsp;&nbsp;
                    <label
                      style={{
                        background: "#f8eaf4",
                        padding: "2px 15px",
                        borderRadius: "18px",
                      }}
                    >
                      <input
                        type="radio"
                        value="Complete"
                        checked={selectedOption === "Complete"}
                        onChange={this.handleOptionChange}
                      />
                      &nbsp;Complete
                    </label>
                  </div>
                </Col>
              </Row>
              <Row>
                <div
                  style={{
                    position: "fixed",
                    bottom: "0",
                    zIndex: "10000",
                    left: "0",
                    right: "0",
                    background: "rgba(255,255,255,.7)",
                    padding: "20px 0px 20px 20px",
                    borderTop: "1px solid #e8d2e4",
                    textAlign: "center",
                  }}
                >
                  <button
                    type="submit"
                    style={{
                      background: "white",
                      letterSpacing: "0.15rem",
                      border: "2px solid #8A1A75",
                      fontWeight: "300",
                      color: "#8a1975",
                      borderRadius: "28px",
                      padding: "0 30px",
                      textTransform: "uppercase",
                      minHeight: "50px",
                      fontSize: "1.1rem",
                      fontFamily: "Fira Sans",
                    }}
                  >
                    {Save}
                  </button>
                </div>
              </Row>
            </Form>
          </CardBody>
        </Container>
      </Fragment>
    );
  }
}
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  listContributorQuestionDetails: myStoryOperations.listContributorQuestionDetails,
  updateContributorQuestion: myStoryOperations.updateContributorQuestion,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
