import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  // const [login, setLogin] = useState(JSON.parse(localStorage.getItem("login")));
  const [token] = useState(localStorage.getItem("token"));
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    localStorage.setItem("authenticated", authenticated);
    // localStorage.setItem("login", login);
    localStorage.setItem("login", token);

  }, []);

  return token || authenticated ? <Outlet /> : <Navigate exact to={`${process.env.PUBLIC_URL}/Signin/`} />;
  // return login || authenticated ? <Outlet /> : <Navigate exact to={`${process.env.PUBLIC_URL}/Signin/`} />;
};

export default PrivateRoute;
